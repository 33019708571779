import axios from "axios";
import { baseURL, basics } from "../../../basics";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import TicketComment from "./TicketComment";
import { useDispatch } from "react-redux";
import {
  fetchTicketsItemsData,
  sendTicketsItemsData,
} from "../../../store/ticket-actions";
import Loading from "../../Ui/Loading";
import Reply from "../StoreComments/Reply";
import ReplyToTicket from "./ReplyToTicket";
import { ticketActions } from "../../../store/ticket-slice";

const TicketMainPage = () => {
  const params: any = useParams();
  const id = params.ticketId;
  const dispatch = useDispatch();
  const getTicket = useSelector((state: any) => state.ticket.ticketItems.items);
  //console.log("getTicket", getTicket);
  const loading = useSelector((state: any) => state.ticket.loading);
  const changed = useSelector((state: any) => state.ticket.changed);
  // const [loading, setLoading] = useState(false);
  // const [getTicket, setGetTicket] = useState<any>([""]);
  //   const [yourCommentIsEmpty, setYourCommentIsEmpty] = useState(false);
  //   const [addComment, setAddComment] = useState(false);
  const Token = useSelector((state: any) => state.auth.token);
  //   const storeId = useSelector((state: any) => state.shopInfo.storeId);
  const messageInputRef = useRef<HTMLInputElement>(null);
  // const parentCommentId = ;
  //   const addCommentButtonHandler = () => {
  //     setAddComment(true);
  //   };
  // const ticketId =
  // //console.log("getTicket", getTicket);
  // useEffect(() => {
  //   dispatch(fetchTicketsItemsData(id, Token));
  // }, [Token, dispatch, id]);

  useEffect(() => {
    dispatch(fetchTicketsItemsData(id, Token));
  }, []);
  useEffect(() => {
    if (changed) {
      dispatch(fetchTicketsItemsData(id, Token));
      dispatch(ticketActions.toggleChange(false));
    }
  }, [Token, changed, dispatch, id]);
  const sendTicketHandler = (event: any) => {
    event.preventDefault();
    //     useEffect(() => {
    const message = messageInputRef.current!.value;
    //console.log("message", message, id);
    if (message) {
      //console.log("message", message, id);
      //  setYourCommentIsEmpty(false);
      const ticket = { id, message };
      dispatch(sendTicketsItemsData(ticket, Token));
      // dispatch(ticketActions.toggleChange(true));

      // dispatch(ticketActions.addItemToTicketsList(ticket));
    } else {
      //console.log("erore");
      //  setYourCommentIsEmpty(true);
      //  setTimeout(() => setYourCommentIsEmpty(false), 2000);
    }
  };
  // if (loading) {
  //   return (
  //     <div className="mt-24">
  //       <Loading />
  //     </div>
  //   );
  // }
  return (
    <div className=" my-24 mx-auto max-w-2xl">
      <div className="flex flex-col-reverse mb-44">
        {getTicket?.map((message: any) => (
          <div key={message.id} className="block w-full">
            <TicketComment message={message} />
          </div>
        ))}
      </div>
      <div className="fixed bottom-16 left-0 right-0 sm:mb-2 z-10 rounded max-w-2xl mx-auto flex justify-center items-center">
        <ReplyToTicket
          sendTicketHandler={sendTicketHandler}
          messageInputRef={messageInputRef}
        />
      </div>
    </div>
  );
};

export default TicketMainPage;
// import { useEffect, useRef, useState } from "react";
// import { baseURL, basics } from "../../../basics";
// import axios from "axios";
// import { useSelector } from "react-redux";

// const AddCommentToStore = () => {
//   const [yourCommentIsEmpty, setYourCommentIsEmpty] = useState(false);
//   const [addComment, setAddComment] = useState(false);
//   const Token = useSelector((state: any) => state.auth.token);
//   const storeId = useSelector((state: any) => state.shopInfo.storeId);
//   const descriptionInputRef = useRef<HTMLInputElement>(null);
//   // const parentCommentId = ;
//   const addCommentButtonHandler = () => {
//     setAddComment(true);
//   };
//   const sendCommentHandler = (event: any) => {
//     event.preventDefault();
//     //     useEffect(() => {
//     const enteredDescription = descriptionInputRef.current!.value;
//     if (enteredDescription) {
//       setYourCommentIsEmpty(false);
//       const bodyFormData = new FormData();
//       bodyFormData.append("storeId", storeId);
//       // bodyFormData.append("parentCommentId", parentCommentId);
//       bodyFormData.append("description", enteredDescription);
//       //     //console.log("sendfavoriteCartData", id);
//       axios({
//         method: "post",
//         url: `${baseURL}api/General/AddCommentToStore?access_token=${Token}`,
//         data: bodyFormData,
//         headers: { "Content-Type": "multipart/form-data" },
//       })
//         .then((response: any) => {
//           //console.log("response POST", response);
//           //     const data = response.data.data;
//           //     setServerData(data);
//         })
//         .catch((error: any) => {
//           //console.log("response POST", error);
//         });
//       //     }, [description, id]);
//     } else {
//       setYourCommentIsEmpty(true);
//       setTimeout(() => setYourCommentIsEmpty(false), 2000);
//     }
//   };

//   return (
//     <div className="flex justify-center items-center  h-12 mx-auto">
//       {!addComment && <button onClick={addCommentButtonHandler}> {basics.addComment}</button>}
//       {addComment && (
//         <form action="#">
//           <input
//             ref={descriptionInputRef}
//             required
//             className=" bg-gray-200 border-0 border-gray-900 rounded-r"
//             type="description"
//             placeholder="متن خود را وارد کنید"
//           />

//           {/* {typedComment > 3 ? ( */}
//           <button
//             className="bg-rose-900 text-zinc-50 my-1 p-1 rounded-l cursor-pointer"
//             onSubmit={sendCommentHandler}
//           >
//             {basics.addComment}
//           </button>
//         </form>
//       )}

//       {/* ) : ( */}
//       {/* <div className="bg-rose-500 text-zinc-50 my-1 p-1 rounded-l"> */}
//       {/* ثبت نظر */}
//       {/* </div> */}
//       {/* )} */}
//     </div>
//   );
// };

// export default AddCommentToStore;
