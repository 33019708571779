import axios from "axios";
import { useEffect, useState } from "react";
import { baseURL, basics } from "../../../basics";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../../store/Ui-slice";

import { targetDateActions } from "../../../store/targetDate-slice";
import moment from "jalali-moment";
import { MdAccessTime, MdDateRange } from "react-icons/md";
const AddPeriods = () => {
  const dispatch = useDispatch();
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const Token = useSelector((state: any) => state.auth.token);
  useEffect(() => {
    axios({
      method: "GET",
      url: `${baseURL}api/General/GetAvailablePeriods?access_token=${Token}`,
      headers: {
        "x-api-version": "2",
      },
    })
      .then((response: any) => {
        // console.log("res", response);
        const data = response.data;
        // setPeriodsData(data);
        dispatch(targetDateActions.addPeriodsData(data));
      })
      .catch((error: any) =>
        dispatch(
          uiActions.showNotification({
            status: "error",
            title: "خطا",
            message:
              "مشکل در دریافت بازه های زمانی ارسال، لطفا دوباره اقدام کنید",
          })
        )
      );
  }, [Token, dispatch]);
  const periodsData = useSelector((state: any) => state.targetDate.periodsData);
  const showListOfPeriodsHandler = () => {
    dispatch(targetDateActions.SHowAndHidePeriodsList(true));
    if (periodsData[0]) {
      // dispatch(targetDateActions.addTargetDayId(periodsData[0].id));
    }
    // console.log("clickOnPreiodsDateToSelectSendingPriodHandler");
  };

  const selectedDayId = useSelector(
    (state: any) => state.targetDate.selectedDayId
  );
  const selectedPeriodTimeId = useSelector(
    (state: any) => state.targetDate.selectedPeriodTimeId
  );

  const periodData = periodsData?.find(
    (data: any) => data.id === selectedDayId
  );
  const periodSelected = periodData?.periods.find(
    (period: any) => period.id === selectedPeriodTimeId
  );
  // console.log("periodData", periodData, periodSelected);
  useEffect(() => {
    if (periodData && periodSelected) {
      const currentDate = new Date();
      const currentDayOfWeek = currentDate.getDay(); // 0 (Sunday) - 6 (Saturday)

      const difference = (periodData?.day - currentDayOfWeek + 7) % 7; // day:1 monday => ((int)dayOfWeek - (int)currentDate.DayOfWeek + 7) % 7;

      const targetDate = new Date();
      targetDate.setDate(currentDate.getDate() + difference);

      const dayOfWeek = moment(targetDate, "YYYY/MM/DD")
        .locale("fa")
        .format("dddd");
      const month = moment(targetDate, "YYYY/MM/DD")
        .locale("fa")
        .format("MMMM");
      const dayOfMonth = moment(targetDate, "YYYY/MM/DD")
        .locale("fa")
        .format("D");
      setDate(` ${dayOfWeek} ${dayOfMonth} ${month}`);
      setTime(
        `${periodSelected?.from.substring(
          0,
          5
        )} تا ${periodSelected?.to.substring(0, 5)}`
      );
    }
  }, [periodData, periodSelected]);

  return (
    <div className="flex justify-center items-center">
      {/* {!showPeriodsList && ( */}
      <div
        onClick={showListOfPeriodsHandler}
        className="h-12 w-full rounded-lg text-zinc-100 font-bold cursor-pointer m-2 py-1 px-2 flex justify-center items-center bg-gradient-to-r from-green-400 via-green-500 to-green-400"
        // style={{ backgroundImage: `radial-gradient(red, orange,yellow)` }}
      >
        {date && time ? (
          <div className="flex justify-center items-center">
            <div className="flex justify-center items-center ml-2">
              <MdDateRange />
              <p>{date}</p>
            </div>
            <div className="flex justify-center items-center mr-2">
              <MdAccessTime />
            </div>
            <p>{time}</p>
          </div>
        ) : (
          basics.AddPeriods
        )}
      </div>
      {/* )} */}
    </div>
  );
};

export default AddPeriods;
