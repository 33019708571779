import AddPaymentType from "./AddPaymentType";
import PaymentModal from "./PaymentModal";

const PaymentPopUpMenu = (props: any) => {
  return (
    <PaymentModal onLoginClose={props.onHideLogin}>
      <AddPaymentType />
    </PaymentModal>
  );
};

export default PaymentPopUpMenu;
