import { useDispatch } from "react-redux";
import { cartActions } from "../../store/cart-slice";
import { useSelector } from "react-redux";
import useCheckStock from "../Hooks/useCheckStock";
import addToCart from "../../resources/images/app/cart.png";
import add from "../../resources/images/app/add.png";
import remove from "../../resources/images/app/remove.png";
import recycleBin from "../../resources/images/app/delete.png";
import { uiActions } from "../../store/Ui-slice";
import { MdDelete } from "react-icons/md";
import { BiCartAdd } from "react-icons/bi";
// TODO: this component will render a lot of variable exp: cartItemPlusOneItem,existingCartItemPlusOneItem,.. you should decrease it.
const AddAndRemoveItemButton = (props: any) => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state: any) => state.cart.items.items);
  const LoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const { id } = props.data;
  const checkStockHook = useCheckStock(id);
  const cartItemPlusOneItem = cartItems?.find(
    (item: any) => item.product.id === id
  );
  const existingCartItemPlusOneItem = cartItemPlusOneItem ? true : false;
  const addItemHandler = () => {
    if (LoggedIn) {
      dispatch(
        cartActions.addItemToCart({
          quantity: 1,
          ...props.data,
        })
        );
      } else {
      dispatch(uiActions.toggleModal());
    }
  };
  
  const removeItemHandler = () => {
    dispatch(cartActions.removeItemFromCart(id));
  };

  return (
    <div>
      {/* {LoggedIn && <div>asdada</div> } */}
      {!existingCartItemPlusOneItem && (
        <div
          onClick={addItemHandler}
          className={`w-full text-white ${props.styles} font-medium rounded-full text-sm  p-1 text-center cursor-pointer`}
        >
          {/* <img src={addToCart} className="w-6 h-6 " alt="" /> */}
          <BiCartAdd className="w-6 h-6"/>
        </div>
      )}
      {existingCartItemPlusOneItem && (
        <div
          
          className={`flex justify-center items-center  text-white px-1 rounded-lg ${props.styles} p-1`}
        >
          {cartItemPlusOneItem.quantity === 1 ? (
            <div  onClick={removeItemHandler}>
             <MdDelete className="w-7 h-7  cursor-pointer "/>
              
            </div>
          ) : (
            <div
              onClick={removeItemHandler}
              className=""
              // className="bg-emerald-300 hover:bg-emerald-700 text-gray-800 font-bold mx-1 p-4 rounded-full"
            >
              <img src={remove} className="filter brightness-0 invert w-7 h-7 cursor-pointer" alt="" />
            </div>
          )}
          <div className="w-7 h-7 m-1 flex justify-center items-center">{cartItemPlusOneItem.quantity}</div>
          {checkStockHook > 0 ? (
            <div
              onClick={addItemHandler}
              // disabled={stockCount === 0}
              // className="bg-emerald-300 hover:bg-emerald-700 text-gray-800 font-bold mx-1 p-4 rounded-full"
            >
              <img src={add} className="filter brightness-0 invert w-7 h-7 cursor-pointer " alt="" />
              
            </div>
          ) : (
            <div
            // disabled={stockCount === 0}
            // className=" p-2 text-gray-800 font-bold rounded-full"
            >
              <img src={add} className="filter brightness-0 invert w-7 h-7" alt="" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AddAndRemoveItemButton;
