import { CgProfile } from "react-icons/cg";
import { baseURL } from "../../../basics";
import Reply from "./Reply";
import AddReply from "./AddReply";
import useDateConverter from "../../Hooks/useDateConverter";

const Comment = (props: any) => {
  const cm = props.data.replies || [];
  const { Year, dayOfWeek, DayOfMonth, Month } = useDateConverter(
    props.data.createdDate
  );
  const avatarIcon = props.data.avatar ? (
    <div className="w-12 h-12">
      <img
        className="h-12 w-12 rounded-full"
        src={`${baseURL}${props.data.avatar}`}
        alt="avatar"
      />
    </div>
  ) : (
    <CgProfile className="h-12 w-12 rounded-full border-2 border-rose-200" />
  );
  const generateKey = () => Math.random().toString(36).substr(2, 9);
  return (
    <div className="m-1 flex justify-start items-start">
      <div className="">{avatarIcon}</div>
      <div className="w-full ">
        <div className="bg-gray-100 rounded-b-lg rounded-l-lg m-1 p-1 ">
          <div className="font-bold">{props.data.userName}</div>
          <div>{`${dayOfWeek} ${DayOfMonth} ${Month}  ${Year}`}</div>
          <div>{props.data.description}</div>
        </div>
        <AddReply commentId={props.data.id} />
        <div className="mr-4">
          {cm.map((reply: any) => (
            <Reply key={generateKey()} reply={reply} parentId={props.data.id} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Comment;
