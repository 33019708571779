import { createSlice, current } from "@reduxjs/toolkit";

const addressSlice = createSlice({
  name: "address",
  initialState: {
    addressItems: { items: [] },
    lat: null,
    lng: null,
    addressItemExistPopUp: false,
    changed: false,
    loading: false,
    addressSelectedData: "",
    addressSelectedForEditData: {},
    isAddressInfoButtonClicked: false,
    ordersIcon: "home",
    initiatelatLng: [],
  },
  reducers: {
    replaceAddress(state, action) {
      state.addressItems.items = action.payload.addressState.addressData;
    },
    toggleChange(state) {
      state.changed = !state.changed;
    },
    toggleLoading(state, action) {
      state.loading = action.payload;
    },
    toggleAddressInfoButton(state, action) {
      state.isAddressInfoButtonClicked = action.payload;
    },
    toggleAddressIcon(state, action) {
      state.ordersIcon = action.payload;
    },
    addLat(state, action) {
      state.lat = action.payload;
    },
    addLng(state, action) {
      state.lng = action.payload;
    },
    initiateMarkerAndViewState(state, action) {
      state.initiatelatLng = action.payload;
    },
    addAddressSelectedData(state, action) {
      state.addressSelectedData = action.payload;
      // state.itemIsSelected = true
      //console.log(
      // "state.addressSelectedData.................Items",
      // state.addressSelectedData
      // );
    },
    addAddressSelectedForEditData(state, action) {
      state.addressSelectedForEditData = action.payload;
      // state.itemIsSelected = true
      //console.log(
      // "state.addressSelectedData.................Items",
      // state.addressSelectedData
      // );
    },
    addItemToAddressesList(state: any, action: any) {
      const newAddressItem = action.payload;
      //console.log("addressItems.................Items", newAddressItem);
      const existingCartItemTitle = state.addressItems.items?.find(
        (item: any) => item.enteredTitle === newAddressItem.enteredTitle
      );
      //console.log("existingCartItemTitle", existingCartItemTitle);
      // state.changed = true;
      state.changed = true;
      if (!existingCartItemTitle) {
        state.addressItems.items?.push(newAddressItem);

        //console.log("existingCartItemTitle", current(state.addressItems));
      } else {
        state.addressItemExistPopUp = true;
      }
    },
    removeItemFromAddresses(state: any, action: any) {
      const id: any = action.payload;
      //console.log("state", current(state.addressItems.items));

      const existingAddressItem = state.addressItems.items?.find(
        (item: any) => item.id === id
      );
      //console.log("existingAddressItem", current(existingAddressItem));

      if (existingAddressItem) {
        state.addressItems.items = state.addressItems.items?.filter(
          (item: any) => item.id !== id
        );
        state.changed = true;
      }
      // //console.log("state.items", current(state.addressItems.items));
    },
  },
});

export const addressActions = addressSlice.actions;

export default addressSlice.reducer;
