import moment from "jalali-moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { targetDateActions } from "../../../store/targetDate-slice";
import PeriodTime from "./PeriodTime";
import { basics } from "../../../basics";

const TargetDay = ({ date }: any) => {
  const dispatch = useDispatch();
  // date: TODO: make a hook for handle this date because I used in 2 component
  const currentDate = new Date();
  const currentDayOfWeek = currentDate.getDay(); // 0 (Sunday) - 6 (Saturday)

  const difference = (date.day - currentDayOfWeek + 7) % 7; // day:1 monday => ((int)dayOfWeek - (int)currentDate.DayOfWeek + 7) % 7;

  const targetDate = new Date();
  targetDate.setDate(currentDate.getDate() + difference);

  const dayOfWeek = moment(targetDate, "YYYY/MM/DD")
    .locale("fa")
    .format("dddd");
  const month = moment(targetDate, "YYYY/MM/DD").locale("fa").format("MMMM");
  const dayOfMonth = moment(targetDate, "YYYY/MM/DD").locale("fa").format("D");
  //  .
  const selectCurrentDateHandler = (id: any) => {
    dispatch(targetDateActions.addTargetDayId(id));
    // console.log("id of day", id);
  };
  const selectedDayId = useSelector(
    (state: any) => state.targetDate.selectedDayId
  );
  const selectedPeriodTimeId = useSelector(
    (state: any) => state.targetDate.selectedPeriodTimeId
  );
  const isThatdaySelected = selectedDayId === date.id;
  const selectedDAyStyle = isThatdaySelected
    ? "bg-sky-700 text-zinc-100"
    : "bg-sky-100";
  // useEffect(() => {
  //   // console.log("selectedDayId", selectedDayId);
  // }, [selectedDayId]);
 
  return (
    <div className="flex flex-col justify-center items-center">
      <div
        onClick={() => selectCurrentDateHandler(date.id)}
        className={` ${selectedDAyStyle} rounded-lg m-1 p-2 w-40 flex justify-center items-center cursor-pointer hover:bg-sky-500 hover:text-zinc-100`}
      >
        <p>{`${dayOfWeek} ${dayOfMonth} ${month}`}</p>
      </div>
      
     
    </div>
  );
};

export default TargetDay;
