import { HiPlus } from "react-icons/hi";

const AddNewButton = () => {
  return (
    <div>
      <button className="rounded bg-rose-700 px-8 py-2">
        <HiPlus className="text-zinc-50" />
      </button>
    </div>
  );
};

export default AddNewButton;
