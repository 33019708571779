import { useRef, useState } from "react";
import { basics } from "../../../basics";
import { sendCartDataForCouponCode } from "../../../store/cart-actions";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../../store/Ui-slice";
import { cartActions } from "../../../store/cart-slice";

const CouponDiscount = () => {
  // console.log('CouponDiscount');
  const dispatch = useDispatch();
  // const discountCodeRef = useRef<HTMLInputElement>(null);
  const Token = useSelector((state: any) => state.auth.token);
  const cart = useSelector((state: any) => state.cart);
  const totalPrice = useSelector((state: any) => state.cart.totalPrice);
  const totalDiscountedPrice = useSelector(
    (state: any) => state.cart.totalDiscountedPrice
  );
  const [enteredCode, setEnteredCode] = useState("");
  const sendingCoupon = useSelector((state: any) => state.cart.sendingCoupon);
  const checkForCouponDiscountHandler = (event: any) => {
    event.preventDefault();
    // const enteredCode = discountCodeRef.current!.value;
    if (enteredCode) {
      if (sendingCoupon) {
        dispatch(sendCartDataForCouponCode(cart, enteredCode, Token));
        // console.log("enteredCode", enteredCode);
      } else {
        dispatch(sendCartDataForCouponCode(cart, null, Token));
      }
    } else {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "کد تخفیف خالی است",
          message: "لطفا کد تخفیف خود را وارد کنید",
        })
      );
    }
  };
  !enteredCode && dispatch(cartActions.toggleCouponButton(true));
  return (
    <div className="max-w-xs mx-auto my-1">
      <form>
        <div className="flex">
          <div className="relative w-full">
            {totalPrice === totalDiscountedPrice ? (
              <div>
                <input
                  // ref={discountCodeRef}
                  value={enteredCode}
                  onChange={(e) => setEnteredCode(e.target.value)}
                  type="text"
                  id="search-dropdown"
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border-l-gray-100 border-l-2 border border-gray-300 focus:ring-gray-500 focus:border-gray-500 "
                  placeholder={basics.couponCode}
                  required
                />
                <button
                  // type="submit"
                  onClick={checkForCouponDiscountHandler}
                  className="absolute top-0 left-0 p-2.5 text-sm font-medium  bg-gray-300 rounded-l-lg border border-gray-300 hover:bg-gray-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-gray-300 focus:text-white"
                >
                  {basics.couponSubmit}
                </button>
              </div>
            ) : (
              <div  onClick={checkForCouponDiscountHandler} className="bg-rose-600 text-zinc-100 rounded-lg text-center py-3 px-1 mx-4 cursor-pointer">{`${basics.clearCouponCode} ${enteredCode}`}</div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default CouponDiscount;
