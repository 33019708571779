import { basics } from "../../../basics";
import favorites from "../../../resources/images/app/favorites.png";
const YourFavoriteListIsEmpty = () => {
  return (
    <div className="my-32 flex flex-col justify-center items-center text-lg text-rose-600 font-bold">
      <div>
        <img src={favorites} className="w-32" alt="" />
      </div>
      <div>{basics.YourFavoriteIsEmpety}</div>
    </div>
  );
};

export default YourFavoriteListIsEmpty;
