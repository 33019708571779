import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { baseURL, basics } from "../../../basics";
import { userInfoActions } from "../../../store/userInfo-slice";
import logo from "../../../resources/images/app/ic_icon.png";
import { uiActions } from "../../../store/Ui-slice";
import { CgProfile } from "react-icons/cg";
import { AiFillCamera } from "react-icons/ai";
import MainModal from "./MainModal";
import axios from "axios";
import GetApplications from "../../Footer/GetApplications";
import { IoMdClose, IoMdCloseCircle } from "react-icons/io";

const DownloadAppPopUpMenu = () => {
  const dispatch = useDispatch();
  const hideDownloadAppModalHandler = () => {
    dispatch(uiActions.toggleDownloadAppModal());
  };
  return (
    <MainModal>
      <div className="relative min-h-full flex flex items-center justify-center ">
        <div className="bg-white rounded-t-lg w-full sm:w-96 space-y-8">
          <div
            onClick={hideDownloadAppModalHandler}
            className="absolute top-0 right-0 m-2"
          >
            <IoMdClose className="text-gray-600 h-8 w-8 cursor-pointer" />
          </div>
          <div className="text-gray-800"></div>
          <GetApplications />
        </div>
      </div>
    </MainModal>
  );
};

export default DownloadAppPopUpMenu;
