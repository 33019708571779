import { createSlice } from "@reduxjs/toolkit";

const targetDateSlice = createSlice({
  name: "targetDate",
  initialState: {
    periodsData: [],
    selectedDayId: null,
    selectedPeriodTimeId: null,
    showPeriodsList: false,
  },
  reducers: {
     addPeriodsData(state, action) {
      state.periodsData = action.payload;
    },
     addTargetDayId(state, action) {
      state.selectedDayId = action.payload;
    },
    addTargetPeriodTimeId(state, action) {
      state.selectedPeriodTimeId = action.payload;
    },
    SHowAndHidePeriodsList(state, action) {
      state.showPeriodsList = action.payload;
    },
  },
});

export const targetDateActions = targetDateSlice.actions;

export default targetDateSlice.reducer;
