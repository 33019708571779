import { useSelector } from "react-redux";
import OverallInfo from "./OverallInfo";
import Loading from "../Ui/Loading";
import infoBgImg from "../../resources/images/app/banner_background.png";
import { useEffect, useState } from "react";
const InfoBar = () => {
  const shopInfoDataResponse = useSelector(
    (state: any) => state.shopInfo.shopInfoData
    );
    const [receivedData,setReceivedData]=useState(false)
  useEffect(() => {
   setReceivedData(Object.keys(shopInfoDataResponse).length !==0)
  }, [shopInfoDataResponse])
  
  return (
    <div
      className="  bg-zinc-700 bg-no-repeat bg-contain text-zinc-50 p-1 sm:p-2 h-32 sm:h-44 md:h-56 mx-auto mt-12 relative"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${infoBgImg})`,
        backgroundPosition: `right 52% bottom 0%`,
        backgroundSize: `100% 100%`,
      }}
    >
     
      {receivedData ? <OverallInfo /> : <Loading />}
    </div>
  );
};

export default InfoBar;
