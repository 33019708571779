// sendAndHowCalculatePayments

import { useSelector } from "react-redux";
import { basics } from "../../basics";

const SendAndHowCalculatePayments = () => {
  const data = useSelector((state: any) => state.shopInfo.shopInfoData);
  const descriprtion = `برای سفارشات بالای ${data.deliveryAmountDiscountedFrom.toLocaleString()} هزار تومان هزینه ی ارسال رایگان بوده و برای کمتر از ${data.deliveryAmountDiscountedFrom.toLocaleString()} هزار تومان مبلغ ${data.deliveryAmount} هزار تومان محاسبه میشود و به هزینه فاکتور اضافه می گردد.`;

  return (
    <div className="my-24">
      <div className="flex justify-center text-slate-800 font-bold text-lg">
        {basics.sendAndHowCalculatePayments}
      </div>
      <p className="text-slate-700 font-medium leading-loose text-justify m-3 sm:m-4">
        {descriprtion}
      </p>
    </div>
  );
};

export default SendAndHowCalculatePayments;
