import { useHistory } from "react-router-dom";

const Ticket = (props: any) => {
     const history =useHistory()
  const sendUserToTicketMainPage = () => {
    history.push(`/ticketToSupport/${props.item.id}`);
  };
  return (
    <div
      onClick={sendUserToTicketMainPage}
      className="flex justify-between bg-gray-200 px-4 py-2 mx-2 my-1 cursor-pointer rounded"
    >
      <p>{props.item.title}</p>
      <p>{props.item.created}</p>
    </div>
  );
};

export default Ticket;
