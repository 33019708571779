import { useEffect, useState } from "react";
import { baseURL, basics } from "../../basics";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Ui/Loading";
import { Link } from "react-router-dom";
import comment from "../../resources/images/app/chat.svg";
import sendingAndRuls from "../../resources/images/app/shopping_cart.png";
import { MdOutlineInsertComment } from "react-icons/md";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { shopInfoActions } from "../../store/shopInfo-slice";
const OverallInfo = () => {
  const dispatch = useDispatch();
  const data = useSelector((state: any) => state.shopInfo.shopInfoData);
  // console.log("data", data);
  const shopInfoDataResponse = useSelector(
    (state: any) => state.shopInfo.received
  );
  const dataInShopInfo = data.stores[0];

  const currentDate = new Date();
  let currentDayOfWeek = currentDate.getDay();
  currentDayOfWeek = currentDayOfWeek !== 0 ? currentDayOfWeek : 7;
  const findCurrentDayInfo = dataInShopInfo.activities.find(
    (activity: any) => activity.day === currentDayOfWeek
  );
  const tomorrowDayOfWeek = currentDayOfWeek !== 7 ? currentDayOfWeek + 1 : 1;
  const findTomorrowInfo = dataInShopInfo.activities.find(
    (activity: any) => activity.day === tomorrowDayOfWeek
  );
  // console.log(
  //   "check today info",
  //   currentDayOfWeek,
  //   tomorrowDayOfWeek,
  //   findCurrentDayInfo,
  //   findTomorrowInfo
  // );
  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();

  // Format the time with leading zeros if needed
  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;

  // console.log(formattedTime); // Output: "10:30" (example)

  const checkPeriodActivitiyFrom = findCurrentDayInfo.fromTime
    .split(":")
    .slice(0, 2)
    .join(":");
  const checkPeriodActivitiyTo = findCurrentDayInfo.toTime
    .split(":")
    .slice(0, 2)
    .join(":");
  const checkDayesIsDayOff = findCurrentDayInfo.isDayOff;
  const checkTomorrowIsDayOff = findTomorrowInfo.isDayOff;

  const currentDateOfUser = new Date();
  currentDateOfUser.setHours(
    Number(formattedTime.split(":")[0]),
    Number(formattedTime.split(":")[1]),
    0,
    0
  );

  const dateFrom = new Date();
  dateFrom.setHours(
    Number(checkPeriodActivitiyFrom.split(":")[0]),
    Number(checkPeriodActivitiyFrom.split(":")[1]),
    0,
    0
  );
  const dateTo = new Date();
  dateTo.setHours(
    Number(checkPeriodActivitiyTo.split(":")[0]),
    Number(checkPeriodActivitiyTo.split(":")[1]),
    0,
    0
  );

  const checkPeriodTimeAvailableHandler =
    dateFrom <= currentDateOfUser && currentDateOfUser <= dateTo;
  // const checkTomorrowIsOffDay =
  const checkOpenCloseReserveStore =
    !checkDayesIsDayOff && checkPeriodTimeAvailableHandler
      ? "باز"
      : !checkTomorrowIsDayOff
      ? "رزرو"
      : "بسته";
  dispatch(
    shopInfoActions.changeOpenCloseReserveStore(checkOpenCloseReserveStore)
  );
  // console.log(
  //   "findCurrentDayInfo",
  //    formattedTime,
  //    checkPeriodActivitiyTo,
  //   checkPeriodTimeAvailableHandler,
  //   checkDayesIsDayOff,
  //   checkOpenCloseReserveStore
  // );

  if (Object.keys(data).length === 0) {
    return <Loading />;
  }

  return (
    <div className="relative flex flex-col justify-between h-full m-2">
      <div className="flex justify-between ">
        <div className="text-base sm:text-xl">{dataInShopInfo.title}</div>
        <div className="flex flex-col justify-center items-end ">
          <div className="flex justify-end items-center text-base font-bold sm:text-xl min-w-max">
            شرکت میوه کانی باغ سنه دژ
          </div>
        </div>
      </div>
      <div className="flex justify-between ">
        <div className="text-xs sm:text-lg">{`${basics.status}: ${checkOpenCloseReserveStore}`}</div>
        <Link to={"sendAndHowCalculatePayments"}>
          <div className="text-xs sm:text-lg flex justify-center items-center">
            <div>
              <AiOutlineShoppingCart className="w-4 h-4 ml-1 sm:h-6 sm:w-6" />
              {/* <img src={sendingAndRuls} className="w-4 h-4 ml-1" alt="" /> */}
            </div>
            <div>{basics.sendAndHowCalculatePayments}</div>
          </div>
        </Link>
      </div>
      <div className="flex justify-between text-xs sm:text-lg">
        {!checkDayesIsDayOff && checkPeriodTimeAvailableHandler ? (
          <div>{`${basics.activitiesPeriod}: ${checkPeriodActivitiyFrom} ${basics.until} ${checkPeriodActivitiyTo}`}</div>
        ) : (
          <div></div>
        )}
        <Link to="/getStoreComments">
          <div className="text-xs sm:text-lg flex justify-center items-center">
            <div>
              <MdOutlineInsertComment className="w-4 h-4 ml-1 sm:h-6 sm:w-6" />
              {/* <img src={comment} className="w-4 h-4 ml-1" alt="" /> */}
            </div>
            <div>{basics.seeCustomersComment}</div>
          </div>
        </Link>
      </div>

      <strong className="mx-1"></strong>
    </div>
  );
};

export default OverallInfo;
