import { useDispatch, useSelector } from "react-redux";

import home from "../../resources/images/app/home.png";
import cart from "../../resources/images/app/cart.png";
import listOfOrders from "../../resources/images/app/order_list.png";
import addresses from "../../resources/images/app/address.png";
import { NavLink, useHistory} from "react-router-dom";
import { basics } from "../../basics";
import { uiActions } from "../../store/Ui-slice";
import { userOrdersActions } from "../../store/userOrders-slice";

const BottomNavbar = () => {
  const history = useHistory()
  const dispatch =useDispatch()
  const LoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const numberOfCartItems = useSelector(
    (state: any) => state.cart.totalQuantity
  );
  const numberOfCartItemsNonZero = numberOfCartItems!==0 && numberOfCartItems
 const selectedItemHandler=(text:any)=>{
  dispatch(userOrdersActions.pageChanger())
   if(LoggedIn){
     history.push(text);

   }else{
    dispatch(uiActions.toggleModal());
    history.push("/");
   }
 }
  return (
    <div className="fixed z-20 bottom-0 left-0 right-0 cursor-pointer  bg-cyan-200 mx-auto max-w-2xl rounded-t-xl sm:rounded-lg sm:mb-2">
      <div className="flex justify-around items-center h-16">
        <div onClick={()=>selectedItemHandler('/')} className="flex flex-col justify-center items-center ">
          <img src={home} className=" p-0 w-8 h-8  " alt="" />
          <div>{basics.showcase}</div>
        </div>
        <div
          onClick={()=>selectedItemHandler("/cartOfUser")}
          className="flex flex-col justify-center items-center relative "
        >
          <div className="text-xs text-zinc-50 px-1 absolute right-4 top-0 rounded-full bg-rose-600">
            {numberOfCartItemsNonZero}
          </div>
          <img src={cart} className=" p-0 w-8 h-8 " alt="" />
          <div>{basics.lastCart}</div>
        </div>

        <div
          onClick={()=>selectedItemHandler("/listOfOrders")}
          className="flex flex-col justify-center items-center"
          // activeClassName="text-base"
        >
          <img src={listOfOrders} className=" p-0 w-8 h-8 " alt="" />
          <div className="">{basics.myOrders}</div>
        </div>
        <div
          onClick={()=>selectedItemHandler("/addresses")}
          className="flex flex-col justify-center items-center"
        >
          <img src={addresses} className=" p-0 w-8 h-8 " alt="" />
          <div>{basics.myAddress}</div>
        </div>
        {/* <NavbarCart /> */}
      </div>
    </div>
  );
};

export default BottomNavbar;
