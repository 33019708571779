import { useDispatch, useSelector } from "react-redux";
import PeriodsModal from "./PeriodsModal";
import TargetDay from "../../Main/AddPeriods/TargetDay";
import PeriodTime from "../../Main/AddPeriods/PeriodTime";
import { targetDateActions } from "../../../store/targetDate-slice";
import { basics } from "../../../basics";
import { useRef } from "react";

const PeriodsPopUpMenu = () => {
  const dispatch = useDispatch();
  const modalRef = useRef<HTMLDivElement>(null);
  // const showPeriodsList = useSelector(
  //   (state: any) => state.targetDate.showPeriodsList
  // );

  const periodsData = useSelector((state: any) => state.targetDate.periodsData);
  const selectedDayId = useSelector(
    (state: any) => state.targetDate.selectedDayId
  );
  // const selectedPeriodTimeId = useSelector(
  //   (state: any) => state.targetDate.selectedPeriodTimeId
  // );
  const selectSendigPeriodHandler = () => {
    dispatch(targetDateActions.SHowAndHidePeriodsList(false));
    // console.log(
    //   "selectSendigPeriodHandler",
    //   selectedDayId,
    //   selectedPeriodTimeId
    // );
  };
  const periodsDataOfSelectedDay = periodsData.find(
    (day: any) => day.id === selectedDayId
  );
  const toggleHideModalHandler = (event: any) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      // Handle closing the modal here
      // console.log("Hide modal");
      dispatch(targetDateActions.SHowAndHidePeriodsList(false));
    }
  };
  return (
    // <PeriodsModal>
    <div
      onClick={toggleHideModalHandler}
      className="fixed inset-0 flex items-center justify-center  bg-gray-500 bg-opacity-75 transition-opacity z-30"
    >
      <div
        ref={modalRef}
        className="flex flex-col items-center justify-center bg-white rounded mx-1"
      >
        <ul className="flex justify-center items-center">
          {periodsData?.map((day: any) => (
            <div key={day.id} className="text-xs m-1">
              <div>
                <TargetDay date={day} />
              </div>
            </div>
          ))}
        </ul>
        <div className="flex justify-center items-center w-72">
          <div className="flex justify-center items-center flex-wrap text-xs m-1">
            {periodsDataOfSelectedDay?.periods.map((period: any) => (
              <PeriodTime key={period.id} time={period} />
            ))}
          </div>
        </div>

        <div
          onClick={selectSendigPeriodHandler}
          className="bg-rose-500 hover:bg-rose-600  cursor-pointer flex justify-center items-center text-zinc-100 font-bold rounded-lg m-2 p-2 w-40"
        >
          {basics.accept}
        </div>
      </div>
    </div>
    // </PeriodsModal>
  );
};

export default PeriodsPopUpMenu;
