import { basics } from "../../basics";
import cafeBaza from "../../resources/images/app/cafe-bazar.png";
import shopLogo from "../../resources/images/app/ic_icon.png";
const GetApplications = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex justify-center items-start">
        <div>
          <img src={shopLogo} alt="" className="h-16" />
        </div>
        <div className="flex flex-col justify-center items-start">
          <h3 className=" text-sm  m-1">
            {basics.getApplicationTitle}
          </h3>
          <h2 className="font-bold text-lg m-1">
            {basics.getApplicationDesc}
          </h2>
        </div>
      </div>
      <div className="m-2">
        <a href="https://cafebazaar.ir/app/co.market.Mivekani.Mivekani">
          <img
            src={cafeBaza}
            alt="دریافت اپلیکیشن از کافه بازار"
            className=""
          />
        </a>
      </div>
    </div>
  );
};

export default GetApplications;
