import { useDispatch, useSelector } from "react-redux";
import { baseURL, basics } from "../../../basics";
import { useRef, useState } from "react";
import axios from "axios";
import { uiActions } from "../../../store/Ui-slice";

const AddReply = ({ commentId }: any) => {
  const dispatch = useDispatch();
  const [yourReplyIsEmpty, setYourReplyIsEmpty] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [addReply, setAddReply] = useState(false);
  const Token = useSelector((state: any) => state.auth.token);
  const storeId = useSelector((state: any) => state.shopInfo.storeId);
  const loggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const descriptionInputRef = useRef<HTMLInputElement>(null);
  // const parentReplyId = ;
  const addReplyButtonHandler = () => {
    if (loggedIn) {
      setAddReply(() => !addReply);
    } else {
      dispatch(uiActions.toggleModal());
    }
  };
  const sendReplyHandler = (event: any) => {
    event.preventDefault();
    //     useEffect(() => {
    const enteredDescription = descriptionInputRef.current!.value;
    //console.log('response POST');
    if (enteredDescription && commentId) {
      dispatch(
        uiActions.showNotification({
          status: "pending",
          title: "در حال ارسال  ",
          message: "در حال ارسال نظر شما ",
        })
      );
      //console.log('response POST');
      setYourReplyIsEmpty(false);
      const bodyFormData = new FormData();
      bodyFormData.append("storeId", storeId);
      bodyFormData.append("parentReplyId", commentId);
      bodyFormData.append("description", enteredDescription);
      //     //console.log("sendfavoriteCartData", id);
      axios({
        method: "post",
        url: `${baseURL}api/General/AddCommentToStore?access_token=${Token}`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response: any) => {
          dispatch(
            uiActions.showNotification({
              status: "success",
              title: "نظر شما ارسال شد",
              message:
                "نظر شما پس از تایید توسط مدیر فروشگاه در لیست نظرات و پیشنهادات نمایش داده خواهد شد",
            })
          );
          setAddReply(() => !addReply);
          setInputValue("");
        })
        .catch((error: any) => {
          dispatch(
            uiActions.showNotification({
              status: "error",
              title: "نظر شما ارسال نشد",
              message: "متاسفانه نظر شما ارسال نشد. لطفا دوباره اقدام کنید",
            })
          );
          //console.log("response POST", error);
        });
      //     }, [description, id]);
    } else {
      setYourReplyIsEmpty(true);
      setTimeout(() => setYourReplyIsEmpty(false), 2000);
      setAddReply(() => !addReply);
    }
  };
  return (
    <div className="flex justify-end items-start w-full  mx-auto">
      {!addReply && (
        <button
          onClick={addReplyButtonHandler}
          className="bg-rose-500 text-zinc-50  mx-2 mb-1 py-1 px-2 rounded cursor-pointer "
        >
          {basics.addReply}
        </button>
      )}
      {addReply && (
        <form action="#">
          <input
            ref={descriptionInputRef}
            required
            className=" bg-gray-200 border-0 px-1 border-gray-900 rounded-r h-10"
            type="description"
            placeholder="پاسخ را بنویسید"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />

          {/* {typedReply > 3 ? ( */}
          <button
            className="bg-gray-700 text-zinc-50  ml-2 mb-1 py-1 px-2 rounded cursor-pointer h-10"
            onClick={sendReplyHandler}
          >
            {basics.submitReply}
          </button>
        </form>
      )}
    </div>
  );
};

export default AddReply;

// {/* <div className="flex justify-start items-start mx-2 "> */}
//       {/* <button */}
//         {/* onClick={addReplyButtonHandler} */}
//         {/* className="bg-rose-300 text-zinc-50 mb-1 py-1 rounded cursor-pointer px-2" */}
//       {/* > */}
//         {/* {basics.addReply} */}
//       {/* </button> */}
//     {/* </div>  */}
