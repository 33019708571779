import { basics } from "../../../basics";
import "./SearchingProducts";
// import noSearchData from '../../../resources/images/app/'
const NoSearchDataFound = () => {
  return (
    <div>
      <div className="text-rose-700 text-center mt-4 mx-2 py-2 right-0 left-0 bg-zinc-100 rounded border border-gray-200 w-100">
        {/* <img src={noSearchData} alt="no data found in searching data" /> */}
        <p>{basics.noDataFound}</p>
      </div>
    </div>
  );
};

export default NoSearchDataFound;
