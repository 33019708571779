import { useEffect, useState } from "react";
import Search from "../../Ui/Search";
import { useDispatch, useSelector } from "react-redux";
import { fetchTextToSearch } from "../../../store/search-actions";
import { searchActions } from "../../../store/search-slice";
import { basics } from "../../../basics";
import ListOfReceivedDataOfSearch from "../../Ui/ListOfReceivedDataOfSearch";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "../../Ui/Loading";
import NoSearchDataFound from "./NoSearchDataFound";
import { uiActions } from "../../../store/Ui-slice";
import { useHistory } from "react-router-dom";

const SearchingProducts = () => {
  const dispatch = useDispatch();
  const [newSearchInputValue, setNewSearchInputValue] = useState("");
  const receivedDataOfSearch = useSelector(
    (state: any) => state.search.searchItems
  );
  const showSearchInfo = useSelector(
    (state: any) => state.search.showSearchInfo
  );
  const emptyDataReceivedFromSearch = useSelector(
    (state: any) => state.search.noDataReceived
  );
  const LoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const history = useHistory();
  if (!LoggedIn) {
   dispatch(uiActions.toggleModal());
   history.push("/");
 }
  function valueChangehandler(newValue: any) {
    //console.log("event.target.value newValue", newValue);
    setNewSearchInputValue(newValue);
  }
  const itemsPerPage = useSelector((state: any) => state.search.itemsPerPage);
  const currentPage = useSelector((state: any) => state.search.page);
  useEffect(() => {
    // const oldSearchInputValue=
    if (newSearchInputValue) {
      // dispatch(searchActions.loadingSearchData(true))
      // setShowDiv(true);
      const timer = setTimeout(
        () => dispatch(fetchTextToSearch(newSearchInputValue,currentPage, itemsPerPage)),
        2000
      );
      return () => {
        clearTimeout(timer);
      };
    } else {
      dispatch(searchActions.addNewListOfSearchData(newSearchInputValue));
    }
  }, [currentPage, dispatch, itemsPerPage, newSearchInputValue]);
  const fetchDataHandler = () => {
     dispatch(searchActions.nextPage());
     //console.log("nextProductsList, Url2:");
   };
   const hasMore = useSelector((state:any)=>state.search.hasMore)

 
  return (
    <div className="mt-24">
      <Search onValueChange={valueChangehandler} />
      {showSearchInfo && emptyDataReceivedFromSearch && currentPage===1 &&(
          <NoSearchDataFound/>
   
      )}
      {showSearchInfo && receivedDataOfSearch && (
          <InfiniteScroll
          dataLength={receivedDataOfSearch.length}
          next={fetchDataHandler}
          hasMore={hasMore} // Replace with a condition based on your data source
          loader={
            <div className="my-24">
              <Loading />
            </div>
          }
          endMessage={''}
        >
        <ul className="mt-4 mx-2 right-0 left-0  bg-zinc-100 rounded  w-100 ">
          {receivedDataOfSearch.map((item: any) => (
            <ListOfReceivedDataOfSearch key={item.id} item={item} />
          ))}
        </ul>
      </InfiniteScroll>
      )}
    </div>
  );
};

export default SearchingProducts;
