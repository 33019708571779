import moment from "jalali-moment";
import { targetDateActions } from "../../../store/targetDate-slice";
import { useDispatch, useSelector } from "react-redux";

const PeriodTime = ({ time }: any) => {
  const dispatch = useDispatch();
  // console.log("period date Time", time);
  // const TimeOfDay = moment(time.from, "MM/DD/YYYY HH:mm:ss")
  //   .locale("fa")
  //   .format("HH:mm:ss");
  // console.log("TimeOfDay", TimeOfDay);

  const selectPeriodTimeHandler = (id: any) => {
    dispatch(targetDateActions.addTargetPeriodTimeId(id));
    // console.log("selectPeriodTimeHandler", id);
  };
  const selectedPeriodTimeId = useSelector(
    (state: any) => state.targetDate.selectedPeriodTimeId
  );
  const selectedPeriodStyle =
    time.id === selectedPeriodTimeId
      ? " bg-gray-600 text-zinc-100"
      : " bg-gray-100 text-gray-900";

  return (
    <div
      onClick={() => selectPeriodTimeHandler(time.id)}
      className={`flex justify-center items-center w-24 m-1 p-2 cursor-pointer ${selectedPeriodStyle} rounded-lg hover:bg-gray-500 hover:text-zinc-100`}
    >
      <p>{`${time.from.substring(0, 5)} تا ${time.to.substring(0, 5)}`}</p>
    </div>
  );
};

export default PeriodTime;
