import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { baseURL, basics } from "../../../basics";
import { userOrdersActions } from "../../../store/userOrders-slice";
import axios from "axios";
import UserOrderItem from "./UserOrderItem";
import Loading from "../../Ui/Loading";
import { useHistory } from "react-router-dom";
import { uiActions } from "../../../store/Ui-slice";
import YourOrdersIsEmpety from "./YourOrdersIsEmpty";
import InfiniteScroll from "react-infinite-scroll-component";
const ListOfOrders = () => {
  const dispatch = useDispatch();

  const [items, setItems] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);

  const Token = useSelector((state: any) => state.auth.token);

  const itemsPerPage = useSelector(
    (state: any) => state.userOrders.itemsPerPage
  );
  const currentPage = useSelector((state: any) => state.userOrders.currentPage);

  const fetchDataHandler = () => {
    dispatch(userOrdersActions.nextPage());
  };
  // if (data.length < items.length) {
  //   setHasMore(true)
  //   // console.log('setHasMore(true');
  // }
  useEffect(() => {
    if (Token) {
      axios({
        method: "get",
        url: `${baseURL}api/Order/GetOrders?PageNumber=${currentPage}&RowCount=${itemsPerPage}&access_token=${Token}`,
        responseType: "stream",
      })
        .then((response: any) => {
          // console.log("api/Order/GetOrders", response.data.data);
          const data = response.data.data;
          setItems((prevItems: any[]) => [...prevItems, ...data]);
          if (data.length === 0 || data.length < itemsPerPage) {
            setHasMore(false);
          }
        })
        .catch((error: any) => {
          dispatch(
            uiActions.showNotification({
              status: "error",
              title: "خطا!",
              message: "دریافت لیست سفارشات از سرور موفق نبود ",
            })
          );
        });
    }
  }, [Token, currentPage, dispatch, itemsPerPage]);

  const LoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const history = useHistory();
  if (!LoggedIn) {
    dispatch(uiActions.toggleModal());
    history.push("/");
  }

  if (!items.length) {
    return <YourOrdersIsEmpety />;
  }
  return (
    <div className="mb-24 mt-12 sm:mt-24 mx-1 ">
      <div className="flex justify-center fixed top-0 left-0 right-0 z-10 bg-white sm:relative  text-xl font-bold">
        <div className="my-3">{basics.myOrders}</div>
      </div>
      <InfiniteScroll
        dataLength={items.length}
        next={fetchDataHandler}
        hasMore={hasMore} // Replace with a condition based on your data source
        loader={
          <div className="my-24">
            <Loading />
          </div>
        }
        endMessage={""}
      >
        <ul>
          {/* {dataResponse && */}
          {items.map((order: any) => (
            <UserOrderItem key={order.id} data={order} />
          ))}
          {/* ))} */}
        </ul>
      </InfiniteScroll>
    </div>
  );
};

export default ListOfOrders;
