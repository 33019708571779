// in this slice i wanna check if any product has amount
//  then show the + and - components to increase and deacrese
//  amount of product
// and if the amount of this product is equal to 0,
//  then whe should show "افزودن به سبد" component.

import { createSlice } from "@reduxjs/toolkit";

const defaultHasItemState = {
  hasProduct: false,
};

const hasItemSlice = createSlice({
  name: "hasItem",
  initialState: defaultHasItemState,
  reducers: {
    hasItem(state, action) {
      state.hasProduct = true;
    },
  },
});
export const hasItemActions = hasItemSlice.actions;

export default hasItemSlice.reducer;
