import { basics, basicsDescription } from "../../basics";
import AboutShopInFooter from "./AboutShopInFooter";
import CallInformations from "./CallInformations";
import GetApplications from "./GetApplications";

const MainFooter = () => {
  return (
    <div className="hidden sm:flex sm:flex-row justify-between bg-gray-700 text-zinc-100 rounded pt-2 pb-12 px-1">
      <div className="m-2  sm:w-2/4 ">
        <AboutShopInFooter />
      </div>
      <div className="m-2  sm:w-1/4 ">
        <CallInformations />
      </div>
      <div className="m-2  sm:w-1/4 text-zinc-50">
        <GetApplications />
      </div>
    </div>
  );
};

export default MainFooter;
