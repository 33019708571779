import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { sendFavoriteCartData } from "../../store/cart-actions";
import { cartActions } from "../../store/cart-slice";
import { uiActions } from "../../store/Ui-slice";

const LikeAndUnLikeButton = (props: any) => {
  const dispatch = useDispatch();

  const Token = useSelector((state: any) => state.auth.token);
  const LoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const favoriteCartData = useSelector(
    (state: any) => state.cart.favoriteItems.items
  );
  const existInFavoriteItem = favoriteCartData.find(
    (item: any) => item.id === props.item.id
  )
    ? true
    : false;
  const addToFavoriteHandler = () => {
    if (LoggedIn) {
      dispatch(cartActions.addOrRemoveFavoriteItemInList(props.item));
      dispatch(sendFavoriteCartData(props.item.id, Token));
      //console.log("addToFavoriteHandler:::", props.item.id, Token);
    } else {
      dispatch(uiActions.toggleModal());
    }
  };
  return (
    <div
      className="absolute left-0 top-0 p-2 cursor-pointer"
      onClick={addToFavoriteHandler}
    >
      {existInFavoriteItem ? (
        <AiFillHeart className="text-rose-600" />
      ) : (
        <AiOutlineHeart className="text-rose-700" />
      )}
    </div>
  );
};

export default LikeAndUnLikeButton;
