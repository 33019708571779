import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { baseURL, basicUnitType, basics } from "../../../basics";
import Loading from "../../Ui/Loading";
import LikeAndUnLikeButton from "../../Ui/LikeAndUnLikeButton";
import CheckStockAndChangeButton from "../../Ui/CheckStockAndChangeButton";
import GradeDescription from "../../Ui/GradeDescription";
import UnitTypeBox from "../../Ui/UnitTypeBox";
import ShowPriceInCardItem from "../ListOfProducts/ShowPriceInCardItem";
import { useDispatch } from "react-redux";
import { uiActions } from "../../../store/Ui-slice";
// import { PiPlusMinusBold } from "react-icons/pi";
const SinglePage = () => {
  const dispatch = useDispatch();
  const [dataProduct, setDataProduct] = useState<any>("");
  const [descriptions, setDescriptions] = useState("");
  const [gradeDescriptions, setGradeDescriptions] = useState("");
  const [response, setResponse] = useState<any>(false);
  const [imageOfProduct, setImageOfProduct] = useState("");
  const params: any = useParams();
  useEffect(() => {
    axios({
      method: "get",
      url: `${baseURL}api/Product/GetProduct?Id=${params.productId}`,
      responseType: "stream",
    })
      .then(function (response: any) {
        const data = response.data;
        setDataProduct(data);
        setResponse(true);
      })
      .catch(function (error: any) {
        dispatch(
          uiActions.showNotification({
            status: "error",
            title: "خطا",
            message: "مشکل در دریافت اطلاعات، لطفا دوباره اقدام کنید",
          })
        );
      });
  }, [dispatch, params.productId]);
  useEffect(() => {
    async function fetchDescription() {
      try {
        const text = await dataProduct.description;
        const hasNewline: any = text.includes("\n");
        const paragraphs = hasNewline
          ? dataProduct.description
              .split("\n")
              .map((paragraph: any, index: any) => {
                return <p key={index}>{paragraph}</p>;
              })
          : dataProduct.description;
        setDescriptions(paragraphs);
      } catch (error) {}
    }
    if (dataProduct.description) {
      fetchDescription();
    }
  }, [dataProduct.description]);
  useEffect(() => {
    async function fetchGradeDescription() {
      try {
        const text = await dataProduct.gradeDescription;
        const hasNewline: any = text.includes("\n");
        const paragraphs = hasNewline
          ? dataProduct.gradeDescription
              .split("\n")
              .map((paragraph: any, index: any) => {
                return <p key={index}>{paragraph}</p>;
              })
          : dataProduct.gradeDescription;
        setGradeDescriptions(paragraphs);
      } catch (error) {}
    }
    if (dataProduct.gradeDescription) {
      fetchGradeDescription();
    }
  }, [dataProduct.gradeDescription]);

  useEffect(() => {
    async function fetchImg() {
      try {
        if (dataProduct.images.length) {
          setImageOfProduct(dataProduct.images[0].imageUrl);
        } else {
          setImageOfProduct(dataProduct.imageUrl);
        }
      } catch (error) {}
    }
    if (dataProduct) {
      fetchImg();
    }
  }, [dataProduct]);
  if (!response) {
    return (
      <div className="my-24 mx-auto">
        <Loading />
      </div>
    );
  }
 
  return (
    <div className="flex flex-col justify-center mx-auto mb-24 mt-12 sm:mt-24 max-w-3xl  px-4 sm:px-6 lg:px-8">
      <div className="flex justify-center fixed top-0 left-0 right-0 z-10 bg-white w-full h-64 border-b-2 rounded-3xl sm:relative  text-xl font-bold">
        {/* <div className="my-3">{basics.listOfAddresses}</div> */}
        <div className="text-2xl">
          <div className="ml-5">
            <LikeAndUnLikeButton item={dataProduct} />
          </div>
          <div className="flex justify-center items-center mb-5 mt-12 sm:mt-4">
            <img
              src={`${baseURL}${imageOfProduct}`}
              className="h-44 rounded-lg"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="mt-60 sm:mt-2">
        <div className="py-1 flex justify-between items-center ml-1 ">
          <div className="flex justify-start items center">
            <CheckStockAndChangeButton
              parentStyles={""}
              childStyles={"flex-row-reverse bg-blue-400 hover:bg-blue-500"}
              items={dataProduct}
            />
          </div>
          <div className="flex justify-end items-center">
            <ShowPriceInCardItem
              price={dataProduct.price}
              discountedPrice={dataProduct.discountedPrice}
            />
            <div className="flex flex-col justify-center items-center">
              <UnitTypeBox
                unit={dataProduct.unit}
                unitType={dataProduct.unitType}
              />
              {dataProduct.unitType === 1 ? (
                <div className="flex justify-center items-center text-center bg-orange-300 text-orange-800 rounded text-xs m-1 px-1 max-w-max">
                  {/* <PiPlusMinusBold /> */}
                  ±50{basicUnitType[2]}
                </div>
              ) : dataProduct.unitType === 2 ? (
                <div className="flex justify-center items-center text-center bg-orange-300 text-orange-800 rounded text-xs m-1 px-1 max-w-max">
                  {/* <PiPlusMinusBold /> */}
                  ±5{basicUnitType[2]}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-center items-start">
          <p className="font-bold my-2">{dataProduct.title}</p>
          <div>
            <GradeDescription grade={dataProduct.grade} color={"black"} />
          </div>
        </div>
        <div className="mt-3 text-gray-500 text-justify">
          <p>{gradeDescriptions}</p>
          <hr className="h-px bg-gray-800 border-0 my-2" />
          <p>{descriptions}</p>
        </div>
      </div>
    </div>
  );
};

export default SinglePage;
