import { basics } from "../../basics";

const AddNewAddressInPurchasePageButton = () => {
     return ( <div>
          <button className="rounded bg-rose-800 px-3 py-2 text-zinc-50">
            {basics.addNewAddressInPurchasePageButton}
          </button>
        </div> );
}
 
export default AddNewAddressInPurchasePageButton;