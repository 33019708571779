import { useSelector } from "react-redux";
import NavbarHamburger from "./NavbarHamburgerMenu";
import NavbarInfoButton from "./NavbarInfoButton";
import NavbarLoginRegister from "./NavbarLoginRegister";
import NavbarLogo from "./NavbarLogo";
import GoBack from "../Ui/GoBackButton";

const TopNavbar = () => {
  const LoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  return (
    <div className="fixed bg-sky-100 z-20 top-0 left-0 right-0 h-12 px-2 flex justify-between items-center mx-auto max-w-7xl ">
      <NavbarHamburger />
      <NavbarLogo />
      {/* {!LoggedIn && <NavbarLoginRegister />} */}
      {/* {LoggedIn && ( */}
       
      {/* )} */}
    </div>
  );
};

export default TopNavbar;
