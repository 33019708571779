import axios from "axios";
import { baseURL } from "../basics";
import { uiActions } from "./Ui-slice";

import { shopInfoActions } from "./shopInfo-slice";
import { cartActions } from "./cart-slice";
import { useHistory } from "react-router-dom";

export const fetchShopInfo: any = () => {
  return async (dispatch: any) => {
    const fetchShopData = async () => {
      dispatch(shopInfoActions.toggleLoading(true));
      dispatch(shopInfoActions.toggleShopInfoReceived(false));
      const response = await fetch(`${baseURL}api/General/GetInfo`);

      if (!response.ok) {
        throw new Error("Could not fetch !");
      }

      const data = await response.json();
      //  setData(data);
      //console.log("OverallInfo", data);
      //  benazaram in data bayad ba map() check shavad k be ezaye har id k
      //  daryaft mikonim product an ra peyda konim, chon in data etelaate
      //  kameli product ra nadarad.
      return data;
    };

    try {
      const shopInfoData = await fetchShopData();
      // handle totalPrice by any fetch request

      // let totalPrice = 0;
      // favoriteCartData.items.map(
      //   (item: any) =>
      //     (totalPrice = totalPrice + item.product.price * item.quantity)
      // );
      //  dispatch(shopInfoActions.toggleLoading(false))
      dispatch(shopInfoActions.toggleLoading(false));
      dispatch(shopInfoActions.toggleShopInfoReceived(true));
      dispatch(
        shopInfoActions.replaceShopInfo({
          shopInfoState: { shopInfoData } || {
            shopInfoData: [],
          },
        })
      );
    } catch (error) {
      dispatch(shopInfoActions.toggleLoading(false));
      dispatch(shopInfoActions.toggleShopInfoReceived(false));
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "خطا",
          message: "دریافت اطلاعات با مشکل مواجه شد، لطفا دوباره اقدام کنید",
        })
      );
    }
  };
};
export const sendOrderData: any = (data: any, Token: any) => {
  return async (dispatch: any) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "ارسال سفارش",
        message: "در حال ارسال سفارش",
      })
    );
    const bodyFormData = new FormData();
    //  bodyFormData.append("Id", data);
    bodyFormData.append("addressId", data.addressLocationId);
    bodyFormData.append("paymentId", data.paymentId);
    bodyFormData.append("activityId", data.activityId);
    bodyFormData.append("periodId", data.periodId);
    //  bodyFormData.append("Icon", data);
    //  bodyFormData.append("Latitude", data);
    //  bodyFormData.append("Longitude", data);
    //console.log("senddataesItemsData", data);

    axios({
      method: "post",
      url: `${baseURL}api/Order/SubmitOrder?access_token=${Token}`,
      data: bodyFormData,
      headers: { "x-api-version": "2", "Content-Type": "multipart/form-data" },
    })
      .then((response: any) => {
        dispatch(
          cartActions.replaceCart({
            cartState: {
              cartData: { items: [] },
              totalPrice: 0,
              totalQuantity: 0,
              totalDiscountedPrice: 0,
            },
          })
        );
        // if (response.ok) {
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "موفقیت آمیز",
            message: "سفارش شما با موفقیت ثبت شد.",
          })
        );
        dispatch(uiActions.sendUserToHome(true));
        //console.log("Data sent successfully!", response.status);
        const history = useHistory();
        history.replace("/");
        // } else {
        // //console.log('Error sending data:', response.status);
        // }
      })
      .catch((error) => {
        if (error.response.status) {
          dispatch(
            uiActions.showNotification({
              status: "error",
              title: "مشکل در ارسال سفارش",
              message: error.response.data,
            })
          );
        } else {
          dispatch(
            uiActions.showNotification({
              status: "error",
              title: "مشکل در ارسال سفارش",
              message: "سفارش شما ثبت نشد.",
            })
          );
        }
        // console.error("Error sending data:", error.response.status)
      });
  };
};
