import { useEffect, useRef, useState } from "react";
import { baseURL, basics } from "../../../basics";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../../store/Ui-slice";

const AddCommentToStoreButton = () => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [yourCommentIsEmpty, setYourCommentIsEmpty] = useState(false);
  const [addComment, setAddComment] = useState(false);
  const Token = useSelector((state: any) => state.auth.token);
  const storeId = useSelector((state: any) => state.shopInfo.storeId);
  const loggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const descriptionInputRef = useRef<HTMLInputElement>(null);
  // const parentCommentId = ;
  const addCommentButtonHandler = (event: any) => {
    event.preventDefault();
    if (loggedIn) {
      setAddComment(true);
      dispatch(uiActions.toggleCommentToStoreModal());
    } else {
      dispatch(uiActions.toggleModal());
    }
  };
  const sendCommentHandler = (event: any) => {
    event.preventDefault();
    //console.log('sendCommentHandler');
    //     useEffect(() => {
    const enteredDescription = descriptionInputRef.current!.value;
    if (enteredDescription) {
      setYourCommentIsEmpty(false);
      const bodyFormData = new FormData();
      bodyFormData.append("storeId", storeId);
      // bodyFormData.append("parentCommentId", parentCommentId);
      bodyFormData.append("description", enteredDescription);
      //     //console.log("sendfavoriteCartData", id);
      // console.log("sendCommentHandler", enteredDescription);
      dispatch(
        uiActions.showNotification({
          status: "pending",
          title: "در حال ارسال  ",
          message: "در حال ارسال نظر شما ",
        })
      );
      axios({
        method: "post",
        url: `${baseURL}api/General/AddCommentToStore?access_token=${Token}`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response: any) => {
          // console.log("response sendCommentHandler", response);
          dispatch(
            uiActions.showNotification({
              status: "success",
              title: "نظر شما ارسال شد",
              message:
                "نظر شما پس از تایید توسط مدیر فروشگاه در لیست نظرات و پیشنهادات نمایش داده خواهد شد",
            })
          );
          setInputValue("");
          //console.log("response POST", response);
          //     const data = response.data.data;
          //     setServerData(data);
        })
        .catch((error: any) => {
          //console.log("response POST", error);
          dispatch(
            uiActions.showNotification({
              status: "error",
              title: "نظر شما ارسال نشد",
              message: "متاسفانه نظر شما ارسال نشد. لطفا دوباره اقدام کنید",
            })
          );
        });
      //     }, [description, id]);
    } else {
      setYourCommentIsEmpty(true);
      setTimeout(() => setYourCommentIsEmpty(false), 2000);
    }
  };

  return (
    <div className="flex justify-center w-full">
      {/* {!addComment && ( */}
        <button
          onClick={addCommentButtonHandler}
          className="w-24 p-1 mb-1 text-zinc-50 cursor-pointer bg-rose-700 rounded-lg flex justify-center items-center "
        >
          {basics.addComment}
        </button>
      {/* )} */}
      {/* {addComment && (
        <form
          action="#"
          className="flex justify-center w-full bg-gray-900 rounded-lg p-1"
        >
          <button
            className="w-24 text-zinc-50 cursor-pointer flex justify-center items-center "
            onClick={sendCommentHandler}
          >
            {basics.addComment}
          </button>
          <input
            ref={descriptionInputRef}
            required
            className="p-1 h-12 w-full max-w-20 bg-gray-200 border-0 border-gray-900 rounded-r"
            type="description"
            placeholder="نظر خود را بنویسید"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />

        </form>
      )} */}

      {/* ) : ( */}
      {/* <div className="bg-rose-500 text-zinc-50 my-1 p-1 rounded-l"> */}
      {/* ثبت نظر */}
      {/* </div> */}
      {/* )} */}
    </div>
  );
};

export default AddCommentToStoreButton;
