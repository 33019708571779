import { Link } from "react-router-dom";
import { basics } from "../../../basics";
import AddNewAddressInPurchasePageButton from "../../Ui/AddNewAddressInPurchasePageButton";

const YourAddressListIsEmpty = () => {
  return (
    <div className="flex flex-col justify-center items-center text-sm text-rose-800">
      <p>{basics.yourAddressListIsEmpty}</p>
      <Link
        to="/addresses/addNewAddress"
        className="my-2 rounded flex justify-center items-center"
      >
        <AddNewAddressInPurchasePageButton />
      </Link>
    </div>
  );
};

export default YourAddressListIsEmpty;
