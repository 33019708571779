import { useEffect, useState } from "react";
import { baseURL, basics } from "../../../basics";
import Search from "../../Ui/Search";
import axios from "axios";
import Ticket from "./Ticket";
import { useDispatch, useSelector } from "react-redux";
import AddNewButton from "../../Ui/AddNewButton";
import { Link, useHistory } from "react-router-dom";
import { uiActions } from "../../../store/Ui-slice";
import Loading from "../../Ui/Loading";

const TicketToSupport = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [getTickets, setGetTickets] = useState<any>([""]);
  const Token = useSelector((state: any) => state.auth.token);
  const LoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  useEffect(() => {
    if (LoggedIn) {
      axios({
        method: "get",
        url: `${baseURL}api/Ticket/GetTickets?access_token=${Token}`,
        responseType: "stream",
      })
        .then((response: any) => {
          //console.log(" response", response);
          const data = response.data;
          setGetTickets(data);
          setLoading(true);
        })
        .catch((error: any) => {
          //console.log("errpr response", error);
        });
    }
  }, [LoggedIn, Token]);

  if (!LoggedIn) {
    dispatch(uiActions.toggleModal());
    history.push("/");
  }
  if (!loading) {
    return (
      <div className="mt-24">
        <Loading />
      </div>
    );
  }
  return (
    <div className="my-24 max-w-2xl mx-auto relative">
      <Search search={basics.search} />
      <ul>
        {getTickets.map((item: any) => (
          <Ticket key={item.id} item={item} />
        ))}
      </ul>
      <div className="fixed bottom-20 left-0 right-0 z-10 rounded flex justify-center items-center">
        <Link to="/ticketToSupport/addNewTicket">
          <AddNewButton />
        </Link>
      </div>
    </div>
  );
};

export default TicketToSupport;
