const TicketComment = (props: any) => {
  let stylesOfCOmment = "";
  let childLine = "";
  let stylesOfLine = "";

  if (props.message.isOwner) {
    stylesOfCOmment = " bg-gray-100 mr-2 ml-1  rounded-l rounded-b";
    stylesOfLine = "block w-full float-right";
    childLine = "block  float-right";
  } else {
    stylesOfCOmment = " bg-blue-300 ml-2 mr-1 rounded-r rounded-b";
    stylesOfLine = "  w-full float-left";
    childLine = " float-left";
  }
  return (
    <div className={`${stylesOfLine}  my-2 p-1  `}>
      <div className={`${childLine} `}>
        <p className={`${stylesOfCOmment} py-1 px-2 w-56`}>
          {props.message.message}
        </p>
      </div>
    </div>
  );
};

export default TicketComment;

// 0 baz status of ticets
// 1 dar entezare pasokh status of ticets
// 2 dar pasokh dade shode status of ticets
// 3 dar naste shode status of ticets
