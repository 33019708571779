import { Fragment } from "react";
import ReactDOM from "react-dom";
import { useDispatch } from "react-redux";
import { uiActions } from "../../../store/Ui-slice";

const Backdrop = (props: any) => {
  const dispatch = useDispatch();

  const toggleHideCartHandler = () => {
    dispatch(uiActions.toggle());
  };
  return (
    <div
      onClick={toggleHideCartHandler}
      className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-30"
    ></div>
  );
};

const ModalOverlay = (props: any) => {
  return (
    <div>
      <div>{props.children}</div>
    </div>
  );
};
const portalElement: any = document.getElementById("cartOverlays");
const CartModal = (props: any) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(<Backdrop />, portalElement)}
      {ReactDOM.createPortal(
        <ModalOverlay>{props.children}</ModalOverlay>,
        portalElement
      )}
    </Fragment>
  );
};

export default CartModal;
