import { useDispatch, useSelector } from "react-redux";
import CartItem from "./CartItem";
import CartModal from "./CartModal";
import { uiActions } from "../../../store/Ui-slice";
import { cartActions } from "../../../store/cart-slice";
import { basics } from "../../../basics";

const Cart = () => {
  const totalPrice = useSelector((state: any) => state.cart.totalPrice);
  const dispatch = useDispatch();
  const cartItems = useSelector((state: any) => state.cart.items.items);
  const eraseAllCartHandler = () => {
    dispatch(cartActions.eraseAllCart());
  };
  const toggleHideCartHandler = () => {
    dispatch(uiActions.toggle());
  };
  const checkCartItems = () => {
    if (cartItems.length > 0) {
      return cartItems?.map((item: any) => (
        <CartItem
          key={item.product.id}
          item={{
            id: item.product.id,
            imageUrl: item.product.imageUrl,
            title: item.product.title,
            price: item.product.price,
            totalPrice: item.product.totalPrice,
            grade: item.product.grade,
            quantity: item.quantity,
            stock: item.product.stock,
          }}
        />
      ));
    } else if (cartItems.length === 0) {
      return <div>سبد خالی است</div>;
    } else {
      alert("we have error in cart component");
      //console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
    }
  };

  return (
    <CartModal>
      <div
        className="relative z-30"
        aria-labelledby="slide-over-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-y-0 right-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden ">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 ">
              <div className="pointer-events-auto w-screen max-w-md">
                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div className="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
                    <div className="flex items-start w-full">
                      <div className="ml-3 flex h-7 items-center w-full">
                        <button
                          onClick={toggleHideCartHandler}
                          type="button"
                          className="-m-2 p-2 text-gray-400 hover:text-gray-500 w-1/5"
                        >
                          <span className="sr-only">Close panel</span>
                          <svg
                            className="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                        <h2
                          className="text-lg font-medium text-gray-900 w-4/5 flex justify-end"
                          id="slide-over-title"
                        >
                          سبد محصولات
                        </h2>
                      </div>
                    </div>
                    <div className="mt-8">
                      <div className="flow-root">
                        <ul className="-my-6 divide-y divide-gray-200">
                          {checkCartItems()}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="py-2 px-2 sm:px-6">
                    <div
                      onClick={eraseAllCartHandler}
                      className="flex items-center justify-center rounded-md border border-transparent bg-red-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-red-900 cursor-pointer"
                    >
                      <p>حذف همه</p>
                    </div>
                  </div>
                  <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                    <div className="flex justify-between text-base font-medium text-gray-900">
                      <p>{basics.totalCost}</p>
                      <p>{totalPrice.toLocaleString()}</p>
                    </div>
                    <div className="mt-6">
                      <div className="cursor-pointer flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700">
                        <p>{basics.continueProcessOfPurchase}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CartModal>
  );
};

export default Cart;
