import { useDispatch, useSelector } from "react-redux";
import ListOfReceivedDataOfSearch from "./ListOfReceivedDataOfSearch";
import Loading from "./Loading";
import { searchActions } from "../../store/search-slice";
import { basics } from "../../basics";
import { useEffect, useRef, useState } from "react";

const Search = (props: any) => {
  const dispatch = useDispatch();
 
  const searchLoading = useSelector((state: any) => state.search.searchLoading);
  const clickedOnSearch = useSelector((state: any) => state.search.clickedOnSearch);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current && clickedOnSearch) {
      inputRef.current.focus();
    }
  }, []);
  function handleChange(event: any) {
    props.onValueChange(event.target.value);
    dispatch(searchActions.reSetPageData())
    dispatch(searchActions.toggleSearchInfoButton(true));
    dispatch(searchActions.fetchedEmptySearchData(false));
    
    //console.log("event.target.value", event.target.value);
  }
 
  return (
    <div className="relative">
      <form className="m-4">
        <label
          htmlFor="search"
          className="mb-2 text-sm font-medium text-rose-900 sr-only "
        >
          {props.search}
        </label>
        <div className="relative">
          <div className=" absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <svg
              aria-hidden="true"
              className="w-5 h-5 text-gray-500 "
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </div>
          {searchLoading && (
            <div className="absolute left-10 top-3 ">
              <Loading />
            </div>
          )}
          <input
          ref={inputRef}
            type="search"
            // id="search"
            className="block w-full p-4 pr-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-gray-500 focus:border-gray-500"
            placeholder={props.search}
            required
            onChange={handleChange}
          />
        </div>
      </form>
     
    </div>
  );
};

export default Search;
