import React, { useState, useEffect } from "react";
import { basics } from "../../basics";

function Timer(props: any) {
  const [countdown, setCountdown] = useState<any>(180);

  useEffect(() => {
    if (countdown > 0) {
      const intervalId = setInterval(() => {
        setCountdown((prevCountdown: any) => prevCountdown - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [countdown]);

  useEffect(() => {
    if (countdown === 0) {
      setCountdown("Time is up!");
    }
  }, [countdown]);

  const handleClick = () => {
    setCountdown(60);
    props.sendCodeAgain();
  };

  const formatCountdown = (countdown: any) => {
    const minutes = Math.floor(countdown / 60);
    const seconds = countdown % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div>
      <div>
        {countdown > 0 ? (
          <div className="flex text-rose-600 text-xs mx-1">
            <p>{basics.codeWillSendAfterWhile}</p>
            <p className="mx-1">{formatCountdown(countdown)}</p>
          </div>
        ) : (
          <button className="text-rose-600 text-xs" onClick={handleClick}>
            {basics.sendAgainCode}
          </button>
        )}
      </div>
    </div>
  );
}
export default Timer;
