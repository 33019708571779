import { basics } from "../../../basics";
import orderIsEmpty from '../../../resources/images/app/order_list.png'
const YourOrdersIsEmpty = () => {
  return (
    <div className="my-32 flex flex-col justify-center items-center text-lg text-rose-600 font-bold">
      <div>
        <img src={orderIsEmpty} className="w-32" alt="" />
      </div>
      <div>{basics.YourOrdersIsEmpety}</div>
    </div>
  );
};

export default YourOrdersIsEmpty;
