import axios from "axios";
import { baseURL } from "../basics";
import { uiActions } from "./Ui-slice";
import { addressActions } from "./address-slice";

export const fetchAddressesItemsData: any = (Token: any) => {
  return async (dispatch: any) => {
    const fetchAddressesData = async () => {
      dispatch(addressActions.toggleLoading(true));
      const response = await fetch(
        `${baseURL}api/User/GetAddresses?access_token=${Token}`
      );

      if (!response.ok) {
        throw new Error("Could not fetch cart data!");
      }
      // dispatch(addressActions.toggleLoading(true))

      const data = await response.json();
      //console.log("fetchAddressesData::", data);
      //  benazaram in data bayad ba map() check shavad k be ezaye har id k
      //  daryaft mikonim product an ra peyda konim, chon in data etelaate
      //  kameli product ra nadarad.
      return data;
    };

    try {
      const addressData = await fetchAddressesData();
      // handle totalPrice by any fetch request

      // let totalPrice = 0;
      // favoriteCartData.items.map(
      //   (item: any) =>
      //     (totalPrice = totalPrice + item.product.price * item.quantity)
      // );
      dispatch(addressActions.toggleLoading(false));
      dispatch(
        addressActions.replaceAddress({
          addressState: { addressData } || {
            addressData: [],
          },
        })
      );
    } catch (error) {
      dispatch(addressActions.toggleLoading(false));
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "خطا ",
          message: "دریافت آدرس با مشکل مواجه شد",
        })
      );
    }
  };
};

export const deleteAddressesItemsData: any = (id: any, Token: any) => {
  // console.log("deleteAddressesItemsData");
  const bodyFormData = new FormData();
  bodyFormData.append("Id", id);
  axios({
    method: "post",
    url: `${baseURL}api/User/DeleteAddress?access_token=${Token}`,
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then((response: any) => {
      // if (response.ok) {
      // console.log("deleteAddressesItemsData Data sent successfully!", response);
      // } else {
      // //console.log('Error sending data:', response.status);
      // }
    })
    .catch((error) => {
      console.error("deleteAddressesItemsData Error sending data:", error);
    });
};
export const sendAddressItemsData: any = (address: any, Token: any) => {
  return async (dispatch: any) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "ارسال آدرس",
        message: "در حال ارسال آدرس",
      })
    );
    const bodyFormData = new FormData();
    //  bodyFormData.append("Id", address);
    bodyFormData.append("title", address.title);
    bodyFormData.append("description", address.description);
    bodyFormData.append("Icon", address.addressIcon);
    address.Latitude && bodyFormData.append("Latitude", address.Latitude);
    address.Longitude && bodyFormData.append("Longitude", address.Longitude);
    axios({
      method: "post",
      url: `${baseURL}api/User/AddAddress?access_token=${Token}`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response: any) => {
        // if (response.ok) {
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "موفقیت آمیز",
            message: "آدرس شما با موفقیت ثبت شد.",
          })
        );
        dispatch(uiActions.sendUserToHome(true));
        dispatch(addressActions.toggleLoading(false));
        //console.log("Data sent successfully!", response.status);
        // } else {
        // //console.log('Error sending data:', response.status);
        // }
      })
      .catch((error) => {
        dispatch(uiActions.sendUserToHome(false));
        dispatch(addressActions.toggleLoading(false));
        dispatch(
          uiActions.showNotification({
            status: "error",
            title: "مشکل در ارسال آدرس",
            message: "آدرس شما ثبت نشد.",
          })
        );
        console.error("Error sending data:", error);
      });
  };
};
export const deleteAddresseItemsData: any = (id: any, Token: any) => {
  return async (dispatch: any) => {
    const bodyFormData = new FormData();
    //  bodyFormData.append("Id", address);
    // bodyFormData.append("title", address.enteredTitle);
    // bodyFormData.append("description", address.enteredDescription);
    //  bodyFormData.append("Icon", address);
    //  bodyFormData.append("Latitude", address);
    bodyFormData.append("id", id);
    //console.log("sendAddressItemsData", id);
    axios({
      method: "post",
      url: `${baseURL}api/User/DeleteAddress?access_token=${Token}`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response: any) => {
        // if (response.ok) {
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "موفقیت آمیز",
            message: "آدرس شما با موفقیت حذف شد.",
          })
        );
        // dispatch(uiActions.sendUserToHome(true));
        //console.log("Data sent successfully!", response.status);
        // } else {
        // //console.log('Error sending data:', response.status);
        // }
      })
      .catch((error) => {
        dispatch(
          uiActions.showNotification({
            status: "error",
            title: "مشکل در حذف آدرس",
            message: "آدرس شما حذف نشد.",
          })
        );
        console.error("Error sending data:", error);
      });
  };
};
//    description // "تستی برای تست"
// icon // "location"
// id // "39309296-66a9-41a2-87d2-11f8c8725511"
// latitude // 0
// longitude // 0
// title // "تست"
