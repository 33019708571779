import axios from "axios";
import { useEffect, useState } from "react";
import { baseURL, basics } from "../../../basics";
import DataCategoresItem from "../ListOfProducts/ByCategory/DataCategoresItem";
import Loading from "../../Ui/Loading";

const Category = () => {
  const [dataCategory, setDataCategory] = useState([""]);
  const [response, setResponse] = useState<any>(false);
  // TODO: this use effect is like useEffect used in: 'ListOfTypes' change it and make one hook that u can use it in 2 component
  useEffect(() => {
    axios({
      method: "get",
      url: `${baseURL}api/Category/GetCategories`,
      responseType: "stream",
    })
      .then(function (response) {
        //console.log("response", response);
        // console.log("img", response.data);
        //console.log("", response.data[0].imageUrl);
        setDataCategory(response.data);
        setResponse(true);
      })
      .catch(function () {
        //console.log("error", error);
      });
  }, []);
  if (!response) {
    return (
      <div className="my-24 mx-auto">
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <DataCategoresItem dataCategory={dataCategory} />
    </div>
  );
};
export default Category;
