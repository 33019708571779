import axios from "axios";
import ProductItem from "./ProductItem";
import { useEffect, useState } from "react";
import Category from "../Category/Category";
import { useSelector } from "react-redux";
import { categoryActions } from "../../../store/category-slice";
import { useDispatch } from "react-redux";
import { baseURL, basics } from "../../../basics";
import ProductItemInMobile from "./ProductItemInMobile";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "../../Ui/Loading";
import {
  fetchShowAllProductsHandler,
  fetchShowProductsHandler,
  fetchShowSpecialsHandler,
} from "../../../store/category-actions";
// import { Link, Route, Switch, useParams } from "react-router-dom";
// import { categoryActions } from "../../../store/category-actions";
const ListOfProducts = () => {
  const dispatch = useDispatch();
  const [listOfProductsCanRenderNow, setListOfProductsCanRenderNow] =
    useState(false);

  const [response, setResponse] = useState<any>(false);
  const loading = useSelector((state: any) => state.category.loading);
  const categoryId = useSelector((state: any) => state.category.categoryId);
  const typeOfProductsCategories = useSelector(
    (state: any) => state.category.typeOfProductsCategories
  );
  const hasMore = useSelector((state: any) => state.category.hasMore);
  // const hasMore = true;
  // const [productsLength, setProductsLength] = useState();
  const productsLength = useSelector(
    (state: any) => state.category.productsLength
  );
  const categoryIdHandler = useSelector(
    (state: any) => state.category.categoryId
  );
  const itemsPerPage = useSelector((state: any) => state.category.itemsPerPage);
  const currentPage = useSelector((state: any) => state.category.page);
  // const [dataProduct, setDataProduct] = useState([""]);
  const dataProduct: any = useSelector(
    (state: any) => state.category.dataOfProducts
  );

  const nextProductsList = () => {
    dispatch(categoryActions.nextPage());
    // console.log("asdasdasdasdasdasdasdasdasd", currentPage, itemsPerPage);
  };
  // console.log("currentPage", currentPage);
  if (dataProduct.length < productsLength) {
    dispatch(categoryActions.setHasMore(true));
    // console.log('setHasMore(true');
  }
  // useEffect(()=>{
  //   dispatch(categoryActions.setHasMore(true))
  // },[dispatch])
  useEffect(() => {
    dispatch(categoryActions.newCategoryIsSelected());
    setListOfProductsCanRenderNow(true);
    // console.log("hasMore", hasMore);
  }, [dispatch]);
  // useEffect(() => {
  //   setWeHaveMore(hasMore)
  // }, [hasMore]);
  useEffect(() => {
    // console.log("1", "haseMore:", hasMore);
    setResponse(false);
    if (typeOfProductsCategories === "special") {
      // console.log("2", "haseMore:", hasMore);
      dispatch(fetchShowSpecialsHandler(currentPage, itemsPerPage));
      setResponse(true);
    } else if (typeOfProductsCategories === "allProducts") {
      // console.log("3", "haseMore:", hasMore);
      dispatch(fetchShowAllProductsHandler(currentPage, itemsPerPage));
      setResponse(true);
    } else {
      // console.log("4", "haseMore:", hasMore);
      dispatch(fetchShowProductsHandler(currentPage, itemsPerPage, categoryId));
      setResponse(true);
      // axios({
      //   method: "get",
      //   url: `${baseURL}api/Product/GetByCategory?CategoryId=${categoryIdHandler}`,
      //   responseType: "stream",
      // })
      //   .then(function (response: any) {
      //     const data: any = response.data.totalItems;
      //     dispatch(categoryActions.totalItemsOfCategory(data));
      //     setResponse(true);
      //   })
      //   .catch(function (error: any) {
      //     // console.log("error", error);
      //     setResponse(false);
      //   });
    }
  }, [
    categoryId,
    categoryIdHandler,
    currentPage,
    dispatch,
    itemsPerPage,
    typeOfProductsCategories,
  ]);

  // console.log(
  //   "dataProduct.length:",
  //   dataProduct.length,
  //   "haseMore:",
  //   hasMore,
  //   "tate.category.productsLength:",
  //   productsLength
  // );
  // if (!response) {
  //   return (
  //     <div className="my-24 mx-auto">
  //       <Loading />
  //     </div>
  //   );
  // }
  return (
    <div className="mt-12 sm:mt-24 mb-24 ">
      <div className="flex justify-center fixed top-0 left-0 right-0 z-10 bg-white sm:relative  text-xl font-bold">
        <div className="my-3">{basics.products}</div>
      </div>
      {/* <h2 className="flex justify-start text-xl font-extrabold text-gray-900 mx-2">
        {basics.Categories}
      </h2> */}
      <Category />
      <div className="max-w-7xl mx-auto px-4 ">
        <div className=" mx-auto py-8  ">
          {!response && !listOfProductsCanRenderNow ? (
            <div className="my-24 mx-auto">
              <Loading />
            </div>
          ) : (
            <InfiniteScroll
              dataLength={dataProduct.length}
              next={nextProductsList}
              hasMore={hasMore} // Replace with a condition based on your data source
              loader={
                <div className="my-24">
                  <Loading />
                </div>
              }
              endMessage={""}
            >
              <ul className="hidden sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 ">
                {dataProduct &&
                  dataProduct.map((product: any) => (
                    <ProductItem key={product.id} items={product} />
                  ))}
              </ul>
              <ul className="sm:hidden flex flex-col ">
                {/* TODO create one component  instead of ProductItem and ProductItemInMobile*/}
                {dataProduct &&
                  dataProduct.map((product: any) => (
                    <ProductItemInMobile key={product.id} items={product} />
                  ))}
              </ul>
            </InfiniteScroll>
          )}
        </div>
      </div>
    </div>
  );
};
export default ListOfProducts;
