import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
// import SinglePage from "../SinglePage/SinglePage";
import { baseURL } from "../../../basics";
import ShowPriceInCardItem from "./ShowPriceInCardItem";
import LikeAndUnLikeButton from "../../Ui/LikeAndUnLikeButton";
import UnitTypeBox from "../../Ui/UnitTypeBox";
import GradeDescription from "../../Ui/GradeDescription";
import CheckStockAndChangeButton from "../../Ui/CheckStockAndChangeButton";
// import { useSelector } from "react-redux";

const ProductItemInMobile = (props: any) => {
  const {
    id,
    title,
    price,
    imageUrl,
    stock,
    discountedPrice,
    isSpecial,
    unit,
    unitType,
    grade,
  } = props.items;
  const [refresh, setRefresh] = useState(false);
  const [noStock, setNoStock] = useState(false);
  // const [showSinglePage, setShowSinglePage] = useState(false);
  const handleRefresh = () => {
    setRefresh(!refresh);
  };
  // //console.log("props:::::::::::", props.items);
  // const dispatch = useDispatch();
  // const addToCartHandler = () => {
  //   dispatch(
  //     cartActions.addItemToCart({
  //       // inja chon mikhastim be in surat benvisim ke id:id, title:title,... pas ba tavajoh be modern javascript shortcut be surate zir minenisim:
  //       id,
  //       imageUrl,
  //       title,
  //       price,
  //       grade,
  //       quantity: 1,
  //     })
  //   );
  // };
  // const Token = useSelector((state: any) => state.auth.token);
  // const addToFavoriteHandler = () => {
  //   dispatch(cartActions.addOrRemoveFavoriteItemInList(props.items));
  //   dispatch(dispatch(sendFavoriteCartData(id, Token)));
  //   //console.log("addToFavoriteHandler:::", id, Token);
  // };
  //  const CategoryId = useSelector((state:any)=> state.category.categoryId)
  // const amountOfItem: any = () => {
  // const eachItem: any = cartCtx.items.find(
  //   (item: any) => item.id === props.id
  // );
  // if (eachItem) {
  //   return eachItem.amount;
  // }
  // };
  // anItem();
  // //console.log("anItem", anItem());
  // //console.log('cartAmount',cartAmount);
  const showNoStockHandler = () => {
    setNoStock(true);
  };
  const hideNoStockHandler = () => {
    setNoStock(false);
  };
  const showProductPageHandler = () => {
    //console.log("showProductPageHandler", id);
    // maybe in this function we should use React Router
    // to send users to product's page
    // //// or we should handle one boolean parameter in redux to
    // handle showing SinglePage with click and render this function
    //  I think we should handle this change in App component
    // setShowSinglePage(true);
  };
  // //console.log('props.id',props);
  // const favoriteCartData = useSelector(
  //   (state: any) => state.cart.favoriteItems.items
  // );
  // const existInFavoriteItem = favoriteCartData.find(
  //   (item: any) => item.id === id
  // )
  //   ? true
  //   : false;
  const history = useHistory();
  const sendUserToProductItemPage = () => {
    history.push(`/products/productItem/${id}`);
  };
  const [truncatedText, settRuncatedText] = useState("");
  useEffect(() => {
    if (title) {
      const rangeStart = 0;
      const rangeEnd = 20;
      // //console.log('title',title);
      const lastWordBoundary = title.lastIndexOf(" ", rangeEnd);
      const truncatedText =
        lastWordBoundary >= rangeEnd
          ? `${title.substring(rangeStart, lastWordBoundary)} ...`
          : `${title.substring(rangeStart, rangeEnd)}`;
      settRuncatedText(truncatedText);
    }
  }, [title]);
  return (
    <div className="relative ">
      <form className="bg-gray-100 rounded-lg shadow-md  m-1 relative">
        <LikeAndUnLikeButton item={props.items} />
        <CheckStockAndChangeButton
          parentStyles={"absolute left-0 top-7"}
          childStyles={"flex-row-reverse bg-green-400 hover:bg-green-500"}
          items={props.items}
        />
        <div
          onClick={sendUserToProductItemPage}
          className=" flex justify-start items-center"
        >
          <div className="flex justify-start items-center">
            <img
              src={`${baseURL}${imageUrl}`}
              alt={title}
              // onClick={showProductPageHandler}
              className=" w-full h-24 w-24 object-scale-down object-cover rounded-full m-2"
            />
          </div>
          {/* <p className="ml-4">{price}</p>
            <p className="ml-4">{stock}</p> */}
          <div className="text-base px-2 ">
            <div
              onClick={showProductPageHandler}
              className="flex flex-col items-start"
            >
              <p className=" py-1 text-gray-900 flex justify-start items-center">
                {truncatedText}
              </p>

              <GradeDescription grade={grade} color={"black"} />
              <div className="py-1 flex justify-start items-center">
                <UnitTypeBox unit={unit} unitType={unitType} />
                <ShowPriceInCardItem
                  price={price}
                  discountedPrice={discountedPrice}
                />
              </div>
            </div>
          </div>
        </div>
      </form>

      {/* {showSinglePage && <SinglePage id={id} />} */}
    </div>
  );
};
export default ProductItemInMobile;
