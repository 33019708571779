import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import store from "./store/index";
import "./index.css";
// import './fonts/woff/IRANSansXFaNum-Bold.woff'
import App from "./App";
import { Provider } from "react-redux";
// Redux persist:
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./store/index";
// basics

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className=" mx-auto ">
          <App />
        </div>
      </PersistGate>
    </Provider>
  </BrowserRouter>
  // </React.StrictMode>
);
