import { useSelector } from "react-redux";
import { basics } from "../../../basics";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { cartActions } from "../../../store/cart-slice";
import useCartItemsAreEqual from "../../Hooks/useCartItemsAreEqual";
import PurchaseInvoicePrices from "./PurchaseInvoicePrices";
import FinalSubmitAndPay from "../../Ui/FinalSubmitAndPay";
import ListOfCartItemsInCarInProfile from "./ListOfCartItemsInCartInProfile";
import { uiActions } from "../../../store/Ui-slice";
import YourCartIsEmpety from "./YourCartIsEmpety";
import { MdDelete, MdShoppingCart } from "react-icons/md";
const CartInProfile = () => {
  const dispatch = useDispatch();

  const cartItems = useSelector((state: any) => state.cart.items.items);
  const cart = useSelector((state: any) => state.cart);
  const LoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const history = useHistory();

  const firstName = useSelector((state: any) => state.userInfo.firstName);
  const lastName = useSelector((state: any) => state.userInfo.lastName);
  const phoneNumber = useSelector((state: any) => state.auth.phoneNumber);

  // console.log('chekOpenCloseReserveStore',todayIsDayOff);
  // const fullName = `${firstName} ${lastName}`;

  const finalSbmitHandler = () => {
 if (Object.keys(cartItems).length) {
      if ((firstName || lastName) && phoneNumber) {
        history.push("/selectAddressAndAcceptPrices");
      } else {
        dispatch(uiActions.toggleUpdateUserModal());
        dispatch(
          uiActions.showNotification({
            status: "error",
            title: "تکمیل پروفایل",
            message: "لطفا نام و نام خانوادگی خود را وارد کنید",
          })
        );
      }
      // TODO:in this function I should make sure that my cart items is avalible in server or not
    } else {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "سبد خالی است",
          message: "",
        })
      );
    }
  };

  if (!LoggedIn) {
    dispatch(uiActions.toggleModal());
    history.push("/");
  }
  if (!Object.keys(cartItems).length) {
    return <YourCartIsEmpety />;
  }
  const eraseAllItemsHandler = () => {
    dispatch(cartActions.eraseAllCart());
  };

  const numberOfCartItemsNonZero =
    cart.totalQuantity !== 0 && cart.totalQuantity;

  return (
    <div className="mx-auto max-w-2xl mt-4 sm:mt-24 mb-72 relative">
      <div className="fixed top-0 sm:top-12 bg-white z-10 mx-auto w-full max-w-2xl h-32">
        <h2 className="flex justify-center items-center text-2xl font-bold mt-6">
          {basics.ListOfOrdersInCart}
        </h2>

        <div className=" absolute left-0 top-4">
          <div className=" mx-2 float-left">
            <div className=" w-12 h-12 bg-gray-100 rounded-lg p-1 my-2">
              <div className="relative">
                <div className="absolute -right-4 -top-4 bg-rose-300 p-1 rounded-lg">
                  {numberOfCartItemsNonZero}
                </div>
              </div>
              <MdShoppingCart className="w-8 h-8" />
            </div>
            <div
              onClick={eraseAllItemsHandler}
              className=" flex justify-end mt-4"
            >
              <MdDelete className="w-8 h-8 text-rose-500 cursor-pointer" />
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-2xl mx-auto mb-12 mt-32 sm:mt-44">
        <div className="mx-1">
          <ListOfCartItemsInCarInProfile />
        </div>
      </div>
      <div className="fixed bottom-0 left-0 right-0 my-6 mx-auto max-w-2xl z-10 bg-gray-200 rounded ">
        <PurchaseInvoicePrices />
        <div
          onClick={finalSbmitHandler}
          className=" rounded flex justify-center items-center"
        >
          <FinalSubmitAndPay title={"ادامه فرآیند خرید"} />
        </div>
      </div>
      {/* <Link to="/selectAddressAndAcceptPrices"> */}
    </div>
  );
};

export default CartInProfile;
