import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { baseURL, basics } from "../../../basics";
import Loading from "../../Ui/Loading";
import SpecialItem from "./SpecialItem";
import { categoryActions } from "../../../store/category-slice";
import { fetchShowSpecialsHandler } from "../../../store/category-actions";

const ListOfSpecialProducts = (props: any) => {
  const [dataProduct, setDataProduct] = useState([""]);
  const [response, setResponse] = useState(false);
  //console.log("dataProduct", dataProduct);
  //console.log("props.id", props.id);
  useEffect(() => {
    axios({
      method: "get",
      url: `${baseURL}api/Product/GetSpecialProducts?PageNumber=${1}&RowCount=${15}`,
      responseType: "stream",
    })
      .then(function (response) {
        const data = response.data.data;
        setDataProduct(data);
        setResponse(true);
        //console.log("categoryUrl:response.specialsdata", response);
        //console.log("categoryUrl:data", data);
      })
      .catch(function (error: any) {
        //console.log("errorLIST", error);
      });
  }, []);
  const itemsPerPage = useSelector((state: any) => state.category.itemsPerPage);
  const currentPage = useSelector((state: any) => state.category.page);
  const dispatch = useDispatch();
  const history = useHistory();
  const catagorySelectedId = (id: any) => {
    dispatch(categoryActions.newCategoryIsSelected());
    dispatch(categoryActions.typeOfProductsCategoriesChanger("special"));
    // dispatch(fetchShowSpecialsHandler(currentPage, itemsPerPage));
    // dispatch(categoryActions.newCategoryIsSelected(id));
    // console.log("id", id);
    history.push("/products");
  };

  if (!response) {
    return (
      <div className="my-24 mx-auto">
        <Loading />
      </div>
    );
  }
  return (
    <div className="max-w-7xl mx-auto   ">
      <div className={` mb-2 mx-auto  rounded text-center pt-8  `}>
        <div className="flex justify-between items-center mr-2">
          <div className="flex justify-start items-center">
            <h2 className="text-xl font-extra bold text-gray-900">
              {basics.specialOrderTitle}
            </h2>
          </div>
          <button
            onClick={catagorySelectedId}
            className={`bg-transparent hover:bg-blue-300 text-blue-400  hover:text-white py-2 px-4 mx-2 rounded-lg`}
          >
            {basics.seeAllProducts}
          </button>
        </div>

        <div className="col-span-5 flex overflow-x-scroll ml-2 ">
          {dataProduct.map((product: any) => (
            <SpecialItem key={product.id} items={product} />
          ))}
        </div>
      </div>
    </div>
  );
};
export default ListOfSpecialProducts;
