import { useDispatch } from "react-redux";
import { cartActions } from "../../../store/cart-slice";
import { baseURL } from "../../../basics";
// import AddItemButton from "../Layout/AddAndRemoveItemButton";
import AddAndRemoveItemButton from "../../Ui/AddAndRemoveItemButton";
import CheckStockAndChangeButton from "../../Ui/CheckStockAndChangeButton";

const CartItem = (props: any) => {
  const { id, title, price, imageUrl, grade, quantity, stock } = props.item;
  const dispatch = useDispatch();
  const eraseItemHandler = () => {
    dispatch(cartActions.eraseItemFromCart(id));
  };

  return (
    <li className="flex py-6">
      <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
        <img
          src={`${baseURL}${imageUrl}`}
          alt={title}
          className="h-full w-full object-cover object-center"
        />
      </div>

      <div className="ml-4 flex flex-1 flex-col">
        <div>
          <div className="flex justify-between text-base font-medium text-gray-900">
            <h3>{title}</h3>
            <p className="ml-4">{price.toLocaleString()}</p>
            <p className="ml-4">{stock}</p>
            <p className="ml-4">{quantity}</p>
            <p className="ml-4">{stock - quantity}</p>
          </div>
        </div>
        <div className="flex flex-1 items-end justify-between text-sm">
          <p className="text-gray-500">{grade}</p>

          <div className="flex">
            <div className="inline-flex">
              <CheckStockAndChangeButton
                items={props.item}
                style={{ name: "flex-row bg-blue-400 hover:bg-blue-500" }}
              />
              {/* <AddAndRemoveItemButton data={props.item}  style={{name:"flex-col bg-green-400 hover:bg-green-500"}} /> */}
            </div>
            <button
              type="button"
              onClick={eraseItemHandler}
              className="font-medium text-indigo-600 hover:text-indigo-500"
            >
              حذف
            </button>
          </div>
        </div>
      </div>
    </li>
  );
};
export default CartItem;
