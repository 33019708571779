import { basics } from "../../basics";

const NoStockButton = (props: any) => {
  return (
    <div className=" text-red-600 bg-red-400/30  font-bold rounded-lg text-sm px-2 py-1 m-1 text-center absolute right-0 top-0">
      {basics.noStocks}
    </div>
  );
};

export default NoStockButton;
