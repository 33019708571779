import { useDispatch, useSelector } from "react-redux";
import { baseURL, basics } from "../../../basics";
import { sendOrderData } from "../../../store/shopInfo-actions";
import { useEffect, useState } from "react";
import axios from "axios";
import { uiActions } from "../../../store/Ui-slice";
import { useHistory } from "react-router-dom";
import { cartActions } from "../../../store/cart-slice";
import { BsCreditCard2Back } from "react-icons/bs";
const AddPaymentType = () => {
  const [storesPaymentData, setStoresPaymentData] = useState([]);
  const dispatch = useDispatch();
  const addressLocationId = useSelector(
    (state: any) => state.address.addressSelectedData
  );
  const activityId = useSelector(
    (state: any) => state.targetDate.selectedDayId
  );
  const periodId = useSelector(
    (state: any) => state.targetDate.selectedPeriodTimeId
  );
  const Token = useSelector((state: any) => state.auth.token);
  // const paymentId = useSelector((state: any) => state.shopInfo.shopInfoData);
  const storeId = useSelector((state: any) => state.shopInfo.storeId);
  // const stores = useSelector(
  //   (state: any) => state.shopInfo.shopInfoData.stores
  // );
  useEffect(() => {
    axios({
      method: "get",
      url: `${baseURL}api/General/GetPaymentMethods?StoreId=${storeId}`,
    })
      .then((response: any) => {
        const storesPaymentData = response.data;
        setStoresPaymentData(storesPaymentData);
        //console.log("response", response);
      })
      .catch((error: any) => {
        //console.log("error", error);
      });
  }, [storeId]);
  // const existStore = stores.find((store: any) => store.id === storeId);
  // //console.log("existStore", existStore);
  const paymentSendingDataToServerHandler = (payment: any) => {
    const paymentId = payment.id;
    if (payment.isOnlinePayment) {
      //console.log("do something when we have bank payment");
    } else {
      const data = { addressLocationId, paymentId, activityId, periodId };
      dispatch(sendOrderData(data, Token));
      dispatch(uiActions.paymentModalPopUpToggle(false));
      // console.log("asdasdasdad");
    }
  };
  return (
    <div className="bg-zinc-50 rounded border m-1 p-1 cursor-pointer">
      <div className="text-gray-500">{basics.selectYourPaymentType}</div>
      <ul>
        {storesPaymentData?.map((payment: any) => (
          <li
            key={payment.id}
            onClick={() => paymentSendingDataToServerHandler(payment)}
            className="flex justify-start items-start m-1 p-1 crusor-pointer"
          >
            {payment.isOnlinePayment ? (
              <div className="flex justify-start items-start">
                <BsCreditCard2Back className="w-8 h-8 bg-rose-500 p-1 rounded-full text-zinc-50" />
                <div className="mr-1">
                  <div className="">{payment.title}</div>
                  <div className="text-xs">
                    {basics.selectBankPaymentTypeDescription}
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex justify-start items-start">
                <BsCreditCard2Back className="w-8 h-8 bg-rose-500 p-1 rounded-full text-zinc-50" />
                <div className="mr-1">
                  <div className="">{payment.title}</div>
                  <div className="text-xs">
                    {basics.selectHomeDoorPaymentTypeDescription}
                  </div>
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AddPaymentType;
