import { basics } from "../../basics";
import HowUsersCallToSupport from "../Main/Information/HowUsersCallToSupport";

const CallInformations = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <h2 className="font-bold text-lg text-rose-400 m-1">
        {basics.callInformations}
      </h2>
      <div>
        <HowUsersCallToSupport />
      </div>
    </div>
  );
};

export default CallInformations;
