import { useHistory } from "react-router-dom";
import goBack from "../../resources/images/app/arrow_back.png";
const GoBack = () => {
  const history = useHistory();

  function handleGoBack() {
    history.goBack();
  }

  return (
    <div className=" w-8 h-8 bg-gray-100 rounded-lg flex justify-center items-center hover:bg-gray-200">
      <button onClick={handleGoBack}>
        <img src={goBack} className="w-6 h-6" alt="" />
      </button>
    </div>
  );
};

export default GoBack;
