import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  baseURL,
  basics,
  headerstatusColor,
  orderStatusType,
} from "../../../basics";
import { useSelector } from "react-redux";
import Loading from "../../Ui/Loading";
import moment from "jalali-moment";
import ListOfProductsInOrdersDetails from "./ListOfProductsInOrdersDetails";
import useDateConverter from "../../Hooks/useDateConverter";

const UserOrderDetails = () => {
  const params: any = useParams();
  const [dataOrder, setDataOrder] = useState<any>();
  const [dataResponse, setDataResponse] = useState(false);
  const [shippingPrice, setShippingPrice] = useState(0);
  const [productsCount, setProductsCount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalDiscountedPrice, setTotalDiscountedPrice] = useState(0);
  const [shopName, setShopName] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [convertedDate, setConvertedDate] = useState("");
  const Token = useSelector((state: any) => state.auth.token);
  // console.log("dataOrder", dataOrder);
  const shopInfoData = useSelector((state: any) => state.shopInfo.shopInfoData);
  const { Year, dayOfWeek, DayOfMonth, TimeOfDay, Month } =
    useDateConverter(convertedDate);
  //   const dataOrders = useSelector((state: any) => state.userOrders.dataOfOrders);
  //   const exactItemOrdered = dataOrders?.find(
  //     (item: any) => item.id === parseFloat(params.orderId)
  //   );
  useEffect(() => {
    axios({
      method: "get",
      url: `${baseURL}api/Order/GetOrder?Id=${params.orderId}&access_token=${Token}`,
      responseType: "stream",
    })
      .then((response: any) => {
        //    //console.log("api/Order/GetOrders", response.data.data);
        const data = response.data;
        setDataResponse(true);
        setDataOrder(data);
      })
      .catch((error: any) => {
        //console.log("error in ListOfOrders", error);
      });
  }, [Token, params.orderId]);

  useEffect(() => {
    if (dataOrder) {
      Object.keys(shopInfoData).length &&
        setShopName(shopInfoData.stores[0].title);
      const shippingPrice = dataOrder.deliveryPrice;
      setShippingPrice(shippingPrice);
      const productsCount = dataOrder.orderProducts.length;
      setProductsCount(productsCount);
      setTotalPrice(dataOrder.totalAmount);
      setTotalDiscountedPrice(dataOrder.totalDiscountedAmount);
      setCouponCode(dataOrder.couponCode);
      setConvertedDate(dataOrder.lastModified)
    }
  }, [dataOrder, shopInfoData]);

  // //console.log(' headerstatusColor[dataOrder.status]', orderStatusType[dataOrder.status]);
  //   //console.log("dataOrder", dataOrder);
  if (!dataResponse) {
    return (
      <div className="my-24 flex justify-center">
        <Loading />
      </div>
    );
  }
  const Classes = `bg-${
    headerstatusColor[dataOrder.status]
  }-600 rounded-lg text-zinc-50  flex justify-center items-center p-1`;
  return (
    <div className="my-24 max-w-2xl mx-auto ">
       <div className="flex justify-center fixed top-0 left-0 right-0 z-10 bg-white sm:relative  text-xl font-bold">
        <div className="my-3"> {basics.detailsOfOrder}</div>
      </div>
      <div className="my-3 mx-auto">
        <div className={Classes}>
          <p>{`${basics.orderStatus}: ${orderStatusType[dataOrder.status]}`}</p>
        </div>
        <div className="flex justify-center text-base my-2">
          <p className="pl-1 font-bold ">{`${basics.numberOfOrderId}:`}</p>
          <p>{dataOrder.id}</p>
        </div>

        <div className=" bg-gray-100 text-sm rounded-lg pr-2 py-1 my-2 max-w-2xl">
          <div className="flex my-2">
            <p className="pl-1 font-bold">{`${basics.exactTimeOfOrder}:`}</p>
            <p>{`${dayOfWeek} ${DayOfMonth} ${Month} ${Year} ${basics.time} ${TimeOfDay}`}</p>
          </div>
          <div className="">
            <div className="flex my-2">
              <p className="pl-1 font-bold">{`${basics.shop}:`}</p>
              <p>{shopName}</p>
            </div>
          </div>
          <div className="">
            <div className="flex my-2">
              <p className="pl-1 font-bold">{`${basics.numberOfItemsInOrder}:`}</p>
              <p className="text-rose-600">{productsCount}</p>
              <p className="pr-1 text-rose-600">{`${basics.product}`}</p>
            </div>
              <div className="flex my-2">
                <p className="pl-1 font-bold">{`${basics.totalCost}:`}</p>
                <p className="text-rose-600">{totalPrice.toLocaleString()}</p>
                <p className="text-rose-600">{`${basics.priceName}`}</p>
              </div>
            {totalPrice !== totalDiscountedPrice &&
              <div className="flex text-rose-600 my-2">
                <p className="pl-1 font-bold">{`${basics.totalCostWithDiscount}:`}</p>
                {/* <p className="line-through px-1">{`${totalPrice} ${basics.priceName}`}</p> */}
                <p className="text-green-400">{totalDiscountedPrice.toLocaleString()}</p>
                <p className="text-green-400">{`${basics.priceName}`}</p>
                <p className="text-green-400 text-xs px-1">{`(${basics.couponCode}: ${couponCode})`}</p>
              </div>
            }
            <div className="flex my-2">
              <p className="pl-1 font-bold">{`${basics.shippingPrice}:`}</p>
              <p className="text-rose-600">{shippingPrice.toLocaleString()}</p>
              <p className="text-rose-600">{`${basics.priceName}`}</p>
            </div>
            <hr className="h-px bg-gray-800 border-0 mx-1" />
            <div className="flex my-4 font-bold text-base">
              <p className="pl-1 ">{`${basics.priceOfOrder}:`}</p>
              <p className="text-rose-400">
                {(totalDiscountedPrice + shippingPrice).toLocaleString()}
              </p>
              <p className="text-rose-400">{`${basics.priceName}`}</p>
            </div>
          </div>
          <div className="flex justify-end pb-2 px-2 font-bold text-red-600 text-xs ">
            {dataOrder.payment.title}
          </div>
        </div>
      </div>
      <div className="bg-gray-100 rounded-lg p-2 flex">
        <p className="pl-1">{`${basics.addressDescription}:`}</p>
        <p>{dataOrder.address}</p>
      </div>
      <div>
        <div className="flex justify-center font-bold text-base my-2">
          <p>{basics.listOfProducts}</p>
        </div>
        {dataOrder.orderProducts.map((item: any) => (
          <ListOfProductsInOrdersDetails key={item.id} data={item} />
        ))}
      </div>
    </div>
  );
};

export default UserOrderDetails;
