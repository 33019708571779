import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { baseURL, basics } from "../../../basics";
import { Link, useHistory } from "react-router-dom";
import DataCategoresItem from "../ListOfProducts/ByCategory/DataCategoresItem";
import Loading from "../../Ui/Loading";
import { useDispatch, useSelector } from "react-redux";
import { fetchShowAllProductsHandler } from "../../../store/category-actions";
import Category from "../Category/Category";
import { categoryActions } from "../../../store/category-slice";

function SampleArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red", color: "black" }}
      onClick={onClick}
    />
  );
}

const ListOfTypes = () => {
  // const isMounted = useRef<boolean>();
  // const [dataCategory, setDataCategory] = useState([""]);
  // const [response, setResponse] = useState<any>(false);

  // const settings = {
  //   focusOnSelect: true,
  //   infinite: true,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   speed: 500,
  //   nextArrow: <SampleArrow />,
  //   prevArrow: <SampleArrow />,
  //   // rtl: true,
  // };
  const dispatch = useDispatch();
  const history = useHistory();
  const itemsPerPage = useSelector((state: any) => state.category.itemsPerPage);
  const currentPage = useSelector((state: any) => state.category.page);
  const goToAllProductsHandler = () => {
    dispatch(categoryActions.newCategoryIsSelected());
    dispatch(categoryActions.typeOfProductsCategoriesChanger("allProducts"));
    dispatch(fetchShowAllProductsHandler(currentPage, itemsPerPage));
    history.push("/products");
  };
  // useEffect(() => {
  //   if (isMounted.current) return;
  //   axios({
  //     method: "get",
  //     url: `${baseURL}api/Category/GetCategories`,
  //     responseType: "stream",
  //   })
  //     .then(function (response) {
  //       setDataCategory(response.data);
  //       setResponse(true);

  //       // //console.log("DataCATEGORYYYYYYYYYYY", response.data);
  //     })
  //     .catch(function (error: any) {
  //       //console.log("error", error);
  //     });
  //   isMounted.current = true;
  // }, []);
  // if (!response) {
  //   return (
  //     <div className="my-24 mx-auto">
  //       <Loading />
  //     </div>
  //   );
  // }
  return (
    <div className="mx-1">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-extra bold text-gray-900">
          {basics.Categories}
        </h2>
        {/* <h2 className="mx-4 ">{basics.ListOfTypes}</h2> */}
        {/* <Slider
        {...settings}
        className="max-w-6xl mx-auto flex justify-center items-center mx-auto"
      > */}
        {/* <Link to="/products/1"> */}
        <button
          onClick={goToAllProductsHandler}
          className={`bg-transparent hover:bg-blue-300 text-blue-400 hover:text-white py-2 px-4 mx-2 rounded-lg`}
        >
          {basics.seeAllProducts}
        </button>
        {/* </Link> */}
      </div>
      <Category />
      {/* <DataCategoresItem dataCategory={dataCategory} /> */}

      {/* </Slider> */}
    </div>
  );
};

export default ListOfTypes;
