import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useCheckStock = (id: any) => {
  const cartItems = useSelector((state: any) => state.cart.items.items);
  const [stockRemined, setStockRemined] = useState(0);
  const checkStock = cartItems?.find((item: any) => item.product.id === id);

  useEffect(() => {
    if (checkStock) {
      const stockOfProduct = checkStock.product.stock - checkStock.quantity;
      if (stockOfProduct >= 0) {
        setStockRemined(stockOfProduct);
      }
    }
  }, [checkStock]);
  return stockRemined;
};

export default useCheckStock;
