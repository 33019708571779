import { createSlice, current } from "@reduxjs/toolkit";

const ticketSlice = createSlice({
  name: "ticket",
  initialState: {
    ticketItems: { items: [] },
    ticketItemExistPopUp: false,
    changed: false,
    loading: false,
    loadingSendNewReplyForTicket: false,
    ticketSelectedData: "",
    // itemIsSelected: false,
  },
  reducers: {
    replaceticket(state, action) {
      state.ticketItems.items = action.payload.ticketState.ticketData;
    },
    toggleChange(state, action) {
      state.changed = action.payload
    },
    toggleLoading(state, action) {
      state.loading = action.payload;
    },
    toggleloadingSendNewReplyForTicket(state, action) {
      state.loadingSendNewReplyForTicket = action.payload;
    },
    addTicketelectedData(state, action) {
      state.ticketSelectedData = action.payload;
      // state.itemIsSelected = true
      //console.log(
        // "state.ticketSelectedData.................Items",
        // state.ticketSelectedData
      // );
    },
    addItemToTicketsList(state: any, action: any) {
      const newTicketItem = action.payload;
      //console.log("ticketItems.................Items", newTicketItem);
      // const existingTicketTitle = state.ticketItems.items?.find(
      //   (item: any) => item.enteredTitle === newTicketItem.enteredTitle
      // );
      // //console.log("existingTicketTitle", existingTicketTitle);
      // state.changed = true;
      state.changed = true;
      // if (!existingTicketTitle) {
      state.ticketItems.items?.push(newTicketItem);

      // //console.log("existingTicketTitle", current(state.ticketItems));
      // } else {
      // state.ticketItemExistPopUp = true;
      // }
    },
    //     removeItemFromticketes(state: any, action: any) {
    //       const id: any = action.payload;
    //       //console.log("state", current(state.ticketItems.items));

    //       const existingticketItem = state.ticketItems.items?.find(
    //         (item: any) => item.id === id
    //       );
    //       //console.log("existingticketItem", current(existingticketItem));

    //       if (existingticketItem) {
    //         state.ticketItems.items = state.ticketItems.items?.filter(
    //           (item: any) => item.id !== id
    //         );
    //         state.changed = true;
    //       }
    //       // //console.log("state.items", current(state.ticketItems.items));
    //     },
  },
});

export const ticketActions = ticketSlice.actions;

export default ticketSlice.reducer;
