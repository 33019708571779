import { useDispatch, useSelector } from "react-redux";
import MainModal from "./MainModal";
import { NavLink, useHistory } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import favorite from "../../../resources/images/app/favorites.png";
import addresses from "../../../resources/images/app/address.png";
import listOfOrders from "../../../resources/images/app/order_list.png";
import share from "../../../resources/images/app/share.png";
import about from "../../../resources/images/app/about_me.png";
import quality from "../../../resources/images/app/quality.png";
import warranty from "../../../resources/images/app/warranty.png";
import rules from "../../../resources/images/app/rules.png";
import pricing from "../../../resources/images/app/price.png";
import privacy from "../../../resources/images/app/setting.png";
import ticketToSupport from "../../../resources/images/app/message_support.png";
import contactSupport from "../../../resources/images/app/call_support.png";
import exit from "../../../resources/images/app/exit.png";
import { authActions } from "../../../store/auth-slice";
import { baseURL, basics } from "../../../basics";
import { uiActions } from "../../../store/Ui-slice";
import { Share } from "../../Main/Information/Share";
import { cartActions } from "../../../store/cart-slice";
import { userInfoActions } from "../../../store/userInfo-slice";
import infoBgImg from "../../../resources/images/app/drawer_background.png";
const MainPopUpMenu = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const LoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const avatar = useSelector((state: any) => state.userInfo.avatar);
  const firstName = useSelector((state: any) => state.userInfo.firstName);
  const lastName = useSelector((state: any) => state.userInfo.lastName);
  const phoneNumber = useSelector((state: any) => state.auth.phoneNumber);
  const fullName = `${firstName} ${lastName}`;

  const avatarIcon = avatar ? (
    <img
      className="h-12 w-12 rounded-full"
      src={`${baseURL}${avatar}`}
      alt="avatar"
    />
  ) : (
    <CgProfile className="h-12 w-12 rounded-full" />
  );
  const toggleHideCartAndCheckUserLogedInHandler = (path: any) => {
    if (LoggedIn) {
      dispatch(uiActions.toggleHambargerModal());
      history.push(path);
    } else {
      dispatch(uiActions.toggleModal());
      dispatch(uiActions.toggleHambargerModal());
      history.push("/");
    }
  };
  const toggleHideCartHandler = (path: any) => {
    dispatch(uiActions.toggleHambargerModal());
    history.push(path);
  };
  const LoggOutHandler = () => {
    dispatch(authActions.logout());
    dispatch(uiActions.toggleHambargerModal());
    dispatch(
      cartActions.replaceCart({
        cartState: {
          cartData: { items: [] },
          totalPrice: 0,
          totalQuantity: 0,
        },
      })
    );
    // todo:is it correct?
    dispatch(userInfoActions.replaceUserInfo());
    dispatch(authActions.replaceUserInfo());
    // .
    history.push("/");
  };
  const shareUrl = () => {
    dispatch(uiActions.toggleHambargerModal());
    Share();
  };
  const authModalHandler = () => {
    dispatch(uiActions.toggleHambargerModal());
    if (LoggedIn) {
      dispatch(uiActions.toggleUpdateUserModal());
    } else {
      dispatch(uiActions.toggleModal());
    }
  };

  return (
    <MainModal>
      <div
        className="relative z-30"
        aria-labelledby="slide-over-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-y-0 right-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden ">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 ">
              <div className="pointer-events-auto w-screen max-w-xs">
                <div className="flex h-full flex-col bg-white shadow-xl">
                  <div className="flex-1 overflow-y-auto ">
                    <div className="flex items-start w-full"></div>
                    <div>
                      <div className=" justify-between items-center">
                        <div
                          onClick={authModalHandler}
                          className="bg-no-repeat bg-contain flex flex-col justify-center items-center cursor-pointer  text-white"
                          style={{
                            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${infoBgImg})`,
                            backgroundPosition: `right 52% bottom 0%`,
                            backgroundSize: `100% 100%`,
                          }}
                        >
                          <div className="flex justify-center items-center">
                            {avatarIcon}
                          </div>
                          <div>
                            {firstName || lastName ? (
                              fullName
                            ) : phoneNumber ? (
                              <div className="bg-purple-500 px-1 rounded-tr-lg rounded-bl-lg">
                                {basics.completProfileInfo}
                              </div>
                            ) : (
                              basics.guest
                            )}
                          </div>
                          <div className="my-2">
                            {LoggedIn ? (
                              <div>{phoneNumber}</div>
                            ) : (
                              <div>{basics.clickForAdd}</div>
                            )}
                          </div>
                        </div>
                        <ul className="flex flex-col m-2  rounded-l-lg ">
                          <li>
                            <div
                              onClick={() =>
                                toggleHideCartAndCheckUserLogedInHandler(
                                  "/favoriteProducts"
                                )
                              }
                              // activeClassName="bg-red-500 text-white rounded-tl-lg "
                              className="flex items-center block py-2 pr-4 pl-3 cursor-pointer   hover:rounded-lg hover:bg-red-400 "
                              aria-current="page"
                            >
                              <img
                                src={favorite}
                                className="w-6 h-6"
                                alt="favorite"
                              />
                              <span className="pr-2">{basics.likes}</span>
                            </div>
                          </li>
                          <li>
                            <div
                              onClick={() =>
                                toggleHideCartAndCheckUserLogedInHandler(
                                  "/addresses"
                                )
                              }
                              className="flex items-center block py-2 pr-4 pl-3 cursor-pointer   hover:rounded-lg hover:bg-red-400 "
                              aria-current="page"
                            >
                              <img
                                src={addresses}
                                className="w-6 h-6"
                                alt="addresses"
                              />

                              <span className="pr-2">
                                {basics.managingAddresses}
                              </span>
                            </div>
                          </li>
                          <li>
                            <div
                              onClick={() =>
                                toggleHideCartAndCheckUserLogedInHandler(
                                  "/listOfOrders"
                                )
                              }
                              className="flex items-center block py-2 pr-4 pl-3 cursor-pointer   hover:rounded-lg hover:bg-red-400 "
                              aria-current="page"
                            >
                              <img
                                src={listOfOrders}
                                className="w-6 h-6"
                                alt="listOfOrders"
                              />

                              <span className="pr-2">
                                {basics.listOfOrders}
                              </span>
                            </div>
                          </li>
                          <li>
                            <div
                              onClick={shareUrl}
                              className="flex items-center block py-2 pr-4 pl-3 cursor-pointer  hover:rounded-lg hover:bg-red-400 "
                            >
                              <img
                                src={share}
                                className="w-6 h-6"
                                alt="share"
                              />
                              <span className="pr-2">{basics.share}</span>
                            </div>
                          </li>
                          <li>
                            <div
                              onClick={() => toggleHideCartHandler("/about")}
                              className="flex items-center block py-2 pr-4 pl-3 cursor-pointer   hover:rounded-lg hover:bg-red-400 "
                              aria-current="page"
                            >
                              <img
                                src={about}
                                className="w-6 h-6"
                                alt="about"
                              />
                              <span className="pr-2">{basics.aboutUs}</span>
                            </div>
                          </li>
                          <li>
                            <div
                              onClick={() => toggleHideCartHandler("/quality")}
                              className="flex items-center block py-2 pr-4 pl-3 cursor-pointer   hover:rounded-lg hover:bg-red-400 "
                              aria-current="page"
                            >
                              <img
                                src={quality}
                                className="w-6 h-6"
                                alt="quality"
                              />
                              <span className="pr-2">{basics.quality}</span>
                            </div>
                          </li>
                          <li>
                            <div
                              onClick={() => toggleHideCartHandler("/warranty")}
                              className="flex items-center block py-2 pr-4 pl-3 cursor-pointer   hover:rounded-lg hover:bg-red-400 "
                              aria-current="page"
                            >
                              <img
                                src={warranty}
                                className="w-6 h-6"
                                alt="warranty"
                              />
                              <span className="pr-2">{basics.warranty}</span>
                            </div>
                          </li>
                          <li>
                            <div
                              onClick={() => toggleHideCartHandler("/rules")}
                              className="flex items-center block py-2 pr-4 pl-3 cursor-pointer   hover:rounded-lg hover:bg-red-400 "
                              aria-current="page"
                            >
                              <img
                                src={rules}
                                className="w-6 h-6"
                                alt="rules"
                              />
                              <span className="pr-2">{basics.rules}</span>
                            </div>
                          </li>
                          <li>
                            <div
                              onClick={() => toggleHideCartHandler("/pricing")}
                              className="flex items-center block py-2 pr-4 pl-3 cursor-pointer   hover:rounded-lg hover:bg-red-400 "
                              aria-current="page"
                            >
                              <img
                                src={pricing}
                                className="w-6 h-6"
                                alt="pricing"
                              />

                              <span className="pr-2">{basics.pricing}</span>
                            </div>
                          </li>

                          <div className="border-solid border border-gray-200 rounded-lg"></div>

                          <li>
                            <div
                              onClick={() => toggleHideCartHandler("/privacy")}
                              className="flex items-center block py-2 pr-4 pl-3 cursor-pointer   hover:rounded-lg hover:bg-red-400 "
                              aria-current="page"
                            >
                              <img
                                src={privacy}
                                className="w-6 h-6"
                                alt="privacy"
                              />
                              <span className="pr-2">{basics.privacy}</span>
                            </div>
                          </li>
                          <li>
                            <div
                              onClick={() =>
                                toggleHideCartAndCheckUserLogedInHandler(
                                  "/ticketToSupport"
                                )
                              }
                              className="flex items-center block py-2 pr-4 pl-3 cursor-pointer   hover:rounded-lg hover:bg-red-400 "
                              aria-current="page"
                            >
                              <img
                                src={ticketToSupport}
                                className="w-6 h-6"
                                alt="ticketToSupport"
                              />
                              <span className="pr-2">
                                {basics.ticketToSupport}
                              </span>
                            </div>
                          </li>
                          <li>
                            <div
                              onClick={() =>
                                toggleHideCartHandler("/contactToSupport")
                              }
                              className="flex items-center block py-2 pr-4 pl-3 cursor-pointer   hover:rounded-lg hover:bg-red-400 "
                              aria-current="page"
                            >
                              <img
                                src={contactSupport}
                                className="w-6 h-6"
                                alt="contactSupport"
                              />
                              <span className="pr-2">
                                {basics.contactSupport}
                              </span>
                            </div>
                          </li>
                          {LoggedIn && (
                            <li>
                              <NavLink
                                to="/"
                                onClick={LoggOutHandler}
                                className="flex items-center block py-2 pr-4 pl-3 cursor-pointer   hover:rounded-lg hover:bg-red-400 "
                                aria-current="page"
                              >
                                <img
                                  src={exit}
                                  className="w-6 h-6"
                                  alt="contactSupport"
                                />
                                <span className="pr-2">{basics.exit}</span>
                              </NavLink>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="py-2 px-2 sm:px-6"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainModal>
  );
};

export default MainPopUpMenu;
