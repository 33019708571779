import { useSelector } from "react-redux";
import Address from "./Address";

import { basics } from "../../../basics";
import Search from "../../Ui/Search";
import AddNewButton from "../../Ui/AddNewButton";
import { Link, useHistory } from "react-router-dom";
import { uiActions } from "../../../store/Ui-slice";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { fetchAddressesItemsData } from "../../../store/address-actions";
import Loading from "../../Ui/Loading";

const Addresses = () => {
  const dispatch = useDispatch();
  const Token: any = useSelector((state: any) => state.auth.token);
  const userAdresses: any = useSelector(
    (state: any) => state.address.addressItems.items
  );
  // console.log('userAdresses',userAdresses);
  const loading: any = useSelector((state: any) => state.address.loading);
  useEffect(() => {
    if (Token) {
      dispatch(fetchAddressesItemsData(Token));
    }
    //console.log("Addresses");
  }, [Token, dispatch]);

  const locationSelectHandler = (e: any) => {
    // //console.log("eee", e);
  };

  const LoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const history = useHistory();
  if (!LoggedIn) {
    dispatch(uiActions.toggleModal());
    history.push("/");
  }
  if (loading) {
    return (
      <div className="my-24">
        <Loading />
      </div>
    );
  }
  return (
    <div className="mb-24 mt-12 sm:mt-24 max-w-2xl mx-auto">
      <div className="flex justify-center fixed top-0 left-0 right-0 z-10 bg-white sm:relative  text-xl font-bold">
        <div className="my-3">{basics.listOfAddresses}</div>
      </div>
      {/* <h2 className="flex justify-center items-center text-2xl font-bold"></h2> */}
      {/* <MobileTopBar/> */}
      {/* <Search search={basics.addressSearch} /> */}

      <ul className="my-2 mx-1">
        {userAdresses.length ? (
          userAdresses.map((address: any) => (
            <Address key={address.id} address={address} />
          ))
        ) : (
          <div className="flex justify-center items-center text-rose-600 mt-4">
            لیست آدرس های شما خالی است.
          </div>
        )}
      </ul>
      <Link
        to="/addresses/addNewAddress"
        className="fixed bottom-24 left-0 right-0 z-10 rounded flex justify-center items-center"
      >
        <AddNewButton />
      </Link>

      {/* <div>NEW:</div> */}
      {/* <UserLocationSelector/> */}
      {/* <div id="map" style={{ height: '500px' }}></div> */}
    </div>
  );
};

export default Addresses;
