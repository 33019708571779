import { createSlice } from "@reduxjs/toolkit";

const initialcommentsState = {
  commentsId: 0,
  itemsPerPage: 10,
  page: 1,
  totalItems: 0,
  loading: false,
};

const commentsSlice = createSlice({
  name: "comments",
  initialState: initialcommentsState,
  reducers: {
//     commentsIdSelected(state, action) {
//       state.commentsId = action.payload;
//       state.page = 1;
//     },
    pageChanger(state, action) {
      state.page = action.payload;
    },
    nextPage(state) {
      // if (state.itemsPerPage * state.page < state.totalItems) state.page++;
       state.page++;
    },
    prevPage(state) {
      if (state.page > 1) {
        state.page--;
      }
    },
//     idOfcomments(state, action) {
//       state.commentsId = action.payload;
//     },
    totalItemsOfcomments(state, action) {
      state.totalItems = action.payload;
    },
    toggleLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const commentsActions = commentsSlice.actions;

export default commentsSlice.reducer;
