import axios from "axios";
import { useEffect, useState } from "react";
import { baseURL, basics } from "../../../basics";
import { useDispatch, useSelector } from "react-redux";
import Comment from "./Comment";
// import AddReply from "./AddReply";
// import Pagination from "../ListOfProducts/Pagination/Pagination";
import { commentsActions } from "../../../store/comments-slice";
import Loading from "../../Ui/Loading";
// import InfiniteScroll from "../ListOfProducts/Pagination/InfiniteScroll";
import InfiniteScroll from "react-infinite-scroll-component";
import AddCommentToStore from "../../Modals/AddComment/AddCommentToStore";
import AddCommentToStoreButton from "./AddCommentToStoreButton";

// api/General/GetStoreComments?StoreId=0&PageNumber=1&RowCount=5
// PageNumber=1&RowCount=5&access_token=${Token}PageNumber=1&RowCount=5&access_token=${Token}PageNumber=1&RowCount=5&access_token=${Token}
const GetStoreComments = () => {
  const dispatch = useDispatch();
  // const loading: any = useSelector((state: any) => state.comments.loading);
  const [serverData, setServerData] = useState<any>([]);
  const Token = useSelector((state: any) => state.auth.token);
  const storeId = useSelector((state: any) => state.shopInfo.storeId);
  //
  const [hasMore, setHasMore] = useState(true);
  const itemsPerPage = useSelector((state: any) => state.comments.itemsPerPage);
  const currentPage = useSelector((state: any) => state.comments.page);
  // const handlePageChange = (pageNumber: any) => {
  //   dispatch(commentsActions.pageChanger(pageNumber));
  // };
  const fetchDataHandler = () => {
    dispatch(commentsActions.nextPage());
  };
  // const prevCommentsList = () => {
  //   dispatch(commentsActions.prevPage());
  //   //console.log("prevCommentsList");
  // };

  useEffect(() => {
    // dispatch(commentsActions.toggleLoading(false));
    axios({
      method: "get",
      url: `${baseURL}api/General/GetStoreComments?StoreId=${storeId}&PageNumber=${currentPage}&RowCount=${itemsPerPage}&access_token=${Token}`,
    })
      .then((response: any) => {
        //console.log("response", response);
        const data = response.data.data;
        setServerData((prevItems: any[]) => [...prevItems, ...data]);
        if (data.length === 0) {
          // console.log("data", response.data.data.length);
          setHasMore(false);
        }
        // setCommentsLength(response.data.totalItems);
        // dispatch(commentsActions.toggleLoading(true));
      })
      .catch((error: any) => {
        console.log("response", error);
      });
  }, [
    Token,
    currentPage,
    dispatch,
    itemsPerPage,
    setServerData,
    storeId,
    // commentsLength,
  ]);

  // if (!loading) {
  //   return (
  //     <div className="my-24">
  //       <Loading />
  //     </div>
  //   );
  // }
  return (
    <div className=" max-w-2xl mx-auto">
      <div className="flex justify-center m-2 font-bold">
        {basics.storeComments}
      </div>
      <div className="mx-1 my-12">
        <InfiniteScroll
          dataLength={serverData.length}
          next={fetchDataHandler}
          hasMore={hasMore} // Replace with a condition based on your data source
          loader={
            <div className="my-24">
              <Loading />
            </div>
          }
          endMessage={
            <p className="my-24" style={{ textAlign: "center" }}>
              <b>`شما تمام پیام ها را مشاهده فرموده اید.`</b>
            </p>
          }
        >
          <ul>
            {serverData.map((item: any) => (
              <div key={item.id} className="relative">
                <Comment data={item} />
                {/* <div className=""> */}
                {/* <div className="absolute z-10 top-0 left-0 mx-3 mt-2"> */}
                {/* <AddReply commentId={item.id} /> */}
                {/* </div> */}
              </div>
            ))}
          </ul>
        </InfiniteScroll>
      </div>
      {/* {loading && (
        <div className="flex justify-center my-6">
          <InfiniteScroll
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={commentsLength}
            onLoadMore={onLoadMore}
          />
        </div>
      )}
      {!loading && (
        <div className="my-24">
          <Loading />
        </div>
      )} */}
      <div className="fixed bottom-16 left-0 right-0 sm:mb-2 z-10 rounded max-w-2xl mx-auto flex justify-center items-center">
        <AddCommentToStoreButton />
      </div>
    </div>
  );
};

export default GetStoreComments;
