import { useSelector } from "react-redux";
import { Fragment, useEffect } from "react";

import { useDispatch } from "react-redux";
import { addressActions } from "../../../store/address-slice";
import CouponDiscount from "../Cart/CouponDiscount";
import PurchaseInvoicePrices from "../Cart/PurchaseInvoicePrices";
import { useHistory } from "react-router-dom";
import FinalSubmitAndPay from "../../Ui/FinalSubmitAndPay";
import { fetchShopInfo } from "../../../store/shopInfo-actions";
import { uiActions } from "../../../store/Ui-slice";
import { basics } from "../../../basics";
import AddressesInLastStepOfPurchase from "./AddressesInLastStepOfPurchase";
import YourAddressListIsEmpty from "./YourAddressListIsEmpty";
import Loading from "../../Ui/Loading";
import { fetchAddressesItemsData } from "../../../store/address-actions";
import AddPeriods from "../AddPeriods/AddPeriods";
import { targetDateActions } from "../../../store/targetDate-slice";
import { cartActions } from "../../../store/cart-slice";
import { fetchCartData } from "../../../store/cart-actions";
const SelectAddressAndAcceptPrices = () => {
  // console.log('SelectAddressAndAcceptPrices CouponDiscount');
  const dispatch = useDispatch();
  const Token = useSelector((state: any) => state.auth.token);
  const todayIsDayOff = useSelector(
    (state: any) => state.shopInfo.checkOpenCloseReserveStore
  );

  const totalQuantityItemsInCart = useSelector(
    (state: any) => state.cart.totalQuantity
  );
  // console.log("haveItemsInCart", totalQuantityItemsInCart);
  useEffect(() => {
    if (Token) {
      dispatch(fetchShopInfo());
      dispatch(fetchAddressesItemsData(Token));
    }
    dispatch(targetDateActions.SHowAndHidePeriodsList(false));
    dispatch(uiActions.paymentModalPopUpToggle(false));
  }, [Token, dispatch]);

  const shopfetchLoading = useSelector((state: any) => state.shopInfo.loading);
  // const [paymentId,setPaymentId]=useState()
  const userAdresses: any = useSelector(
    (state: any) => state.address.addressItems.items
  );
  //console.log("userAdresses", userAdresses);
  const addressLocationId = useSelector(
    (state: any) => state.address.addressSelectedData
  );
  const selectedPeriodTimeId = useSelector(
    (state: any) => state.targetDate.selectedPeriodTimeId
  );
  const selectedDayId = useSelector(
    (state: any) => state.targetDate.selectedDayId
  );
  function handleChildData(data: any) {
    dispatch(addressActions.addAddressSelectedData(data));
  }
  const history = useHistory();
  const finalSbmitHandler = () => {
    dispatch(fetchCartData(Token));
    if (addressLocationId) {
      if (selectedPeriodTimeId && selectedDayId) {
        dispatch(uiActions.paymentModalPopUpToggle(true));
      } else {
        dispatch(
          uiActions.showNotification({
            status: "error",
            title: "بازه زمانی خالی است",
            message: "لطفا یک بازه زمانی را انتخاب کنید",
          })
        );
      }
      // this shuld be added in  paymentpopoUp component:
      // //console.log("addressLocationId", addressLocationId, paymentId);
      // const data = { addressLocationId, paymentId };
      // dispatch(sendOrderData(data, Token));
      // .

      // dispatch(fetchCartData(Token))

      // localStorage.setItem('persist:cart-root','[]')
    } else {
      // <ErrorAddOneAddress/>
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "آدرس خالی است",
          message: "لطفا یک آدرس را انتخاب کنید",
        })
      );
    }
  };
  useEffect(() => {
    if (!totalQuantityItemsInCart) {
      history.push("/");
    }
  }, [history, totalQuantityItemsInCart]);
  useEffect(() => {
    if (todayIsDayOff === "بسته") {
      history.push("/home");
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "فروشگاه تعطیل است.",
          message: "سفارش شما به دلیل تعطیل بودن فروشگاه ثبت نشد",
        })
      );
    }
  }, [dispatch, history, todayIsDayOff]);

  if (shopfetchLoading) {
    return (
      <div className="mt-24">
        <Loading />
      </div>
    );
  }

  return (
    <Fragment>
      <div className="mt-24 max-w-3xl mx-auto">
        <div className="flex justify-center fixed top-0 left-0 right-0 z-10 bg-white sm:relative  text-xl font-bold">
          <div className="my-3">{basics.moreInformation}</div>
        </div>
        {/* <h2 className="flex justify-center items-center text-2xl font-bold">
          {basics.listOfAddresses}
        </h2> */}
        <div className="max-w-2xl mx-auto h-46 shadow-md mt-6 mb-72">
          <ul>
            {userAdresses.length === 0 ? (
              <YourAddressListIsEmpty />
            ) : (
              userAdresses.map((address: any) => (
                <AddressesInLastStepOfPurchase
                  key={address.id}
                  address={address}
                  onAddressClick={handleChildData}
                />
              ))
            )}
          </ul>
        </div>
        <div className="max-w-2xl my-6 mx-auto"></div>
        <div className="fixed bottom-0 left-0 right-0 my-6 mx-auto max-w-2xl z-10 bg-gray-200 rounded">
          <AddPeriods />
          <CouponDiscount />
          <PurchaseInvoicePrices />
          <div
            onClick={finalSbmitHandler}
            className=" rounded flex justify-center items-center"
          >
            <FinalSubmitAndPay title={"تایید آدرس و ادامه خرید"} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SelectAddressAndAcceptPrices;
