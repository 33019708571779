import { createSlice } from "@reduxjs/toolkit";
// const initialToken = localStorage.getItem("token");
// //console.log("initialToken", initialToken);
const initialAuthState = {
  token: "",
  phoneNumber: "",
  isLoggedIn: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    replaceUserInfo(state) {
      state.token = "";
      state.phoneNumber = "";
      state.isLoggedIn = false;
    },
    login(state, action) {
      state.isLoggedIn = true;
      state.token = action.payload;
      //  localStorage.setItem("token", action.payload);
    },
    logout(state) {
      state.isLoggedIn = false;
      state.token = "";
      //  localStorage.removeItem("token");
    },
    addPhoneNumber(state, action: any) {
      state.phoneNumber = action.payload;
      //  localStorage.removeItem("token");
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
