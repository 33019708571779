import axios from "axios";
import { baseURL } from "../basics";
import { uiActions } from "./Ui-slice";
import { ticketActions } from "./ticket-slice";

export const fetchTicketsItemsData: any = (id: any, Token: any) => {
  return async (dispatch: any) => {
    const fetchTicketData = async () => {
      dispatch(ticketActions.toggleLoading(true));
      const response = await fetch(
        `${baseURL}api/Ticket/GetTicket?Id=${id}&access_token=${Token}`
      );
      if (!response.ok) {
        throw new Error("Could not fetch !");
      }
      const data = await response.json();
      return data.messages;
    };

    try {
      const ticketData = await fetchTicketData();
      // handle totalPrice by any fetch request
      //console.log("ticketData", ticketData);
      // let totalPrice = 0;
      // favoriteCartData.items.map(
      //   (item: any) =>
      //     (totalPrice = totalPrice + item.product.price * item.quantity)
      // );
      //  dispatch(ticketActions.toggleLoading(false))
      dispatch(ticketActions.toggleLoading(false));
      dispatch(
        ticketActions.replaceticket({
          ticketState: { ticketData } || {
            ticketData: [],
          },
        })
      );
    } catch (error) {
      dispatch(ticketActions.toggleLoading(false));
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "خطا در دریافت تیکت",
          message: "لطفا دوباره اقدام کنید",
        })
      );
    }
  };

  // axios({
  //   method: "get",
  //   url: `${baseURL}api/Ticket/GetTicket?Id=${id}&access_token=${Token}`,
  //   responseType: "stream",
  // })
  //   .then((response: any) => {
  //     //console.log(" response", response);
  //     const data = response.data.messages;
  //     setGetTicket(data);
  //     setLoading(true);
  //   })
  //   .catch((error: any) => {
  //     //console.log("errpr response", error);
  //   });

  //   };
};

export const sendTicketsItemsData: any = (ticket: any, Token: any) => {
  return async (dispatch: any) => {
    dispatch(ticketActions.toggleloadingSendNewReplyForTicket(true));
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "ارسال تیکت",
        message: "در حال ارسال تیکت",
      })
    );
    const bodyFormData = new FormData();
    if (ticket.id) {
      bodyFormData.append("id", ticket.id);
    }
    // bodyFormData.append("id", ticket.id);
    if (ticket.enteredTitle) {
      bodyFormData.append("title", ticket.enteredTitle);
    }
    bodyFormData.append("Message", ticket.message);

    //console.log("sendTicketsItemsData", ticket);
    axios({
      method: "post",
      url: `${baseURL}api/Ticket/AddTicket?access_token=${Token}`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response: any) => {
        // if (response.ok) {
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "موفقیت آمیز",
            message: "تیکت شما با موفقیت ثبت شد.",
          })
        );
        dispatch(ticketActions.toggleloadingSendNewReplyForTicket(false));
        dispatch(ticketActions.toggleChange(true));
        //console.log("Data sent successfully!", response.status);
        // } else {
        // //console.log('Error sending data:', response.status);
        // }
      })
      .catch((error) => {
        dispatch(ticketActions.toggleloadingSendNewReplyForTicket(false));
        dispatch(
          uiActions.showNotification({
            status: "error",
            title: "مشکل در ارسال تیکت",
            message: "تیکت شما ثبت نشد.",
          })
        );
        console.error("Error sending data:", error);
      });
  };
};
