import AddAndRemoveItemButton from "./AddAndRemoveItemButton";
import NoStockButton from "./NoStockButton";

const CheckStockAndChangeButton = (props: any) => {
  return (
    <div>
      {props.items.stock ? (
        <div className={`${props.parentStyles} mx-1`}>
          <AddAndRemoveItemButton
            data={props.items}
            styles={props.childStyles}
          />
        </div>
      ) : (
        <NoStockButton />
      )}
    </div>
  );
};

export default CheckStockAndChangeButton;
