import { basics, basicsDescription } from "../../../basics";

const About = () => {
  const descriprtion = basicsDescription.about.includes("\n");
  const paragraphs = descriprtion
    ? basicsDescription.about.split("\n").map((paragraph: any, index: any) => {
        return <p key={index}>{paragraph}</p>;
      })
    : basicsDescription.about;
  return (
    <div className="my-24">
      <div className="flex justify-center text-slate-800 font-bold text-lg">
        {basics.aboutUs}
      </div>
      <p className="text-slate-700 font-medium leading-loose text-justify m-3 sm:m-4">
        {paragraphs}
      </p>
    </div>
  );
};

export default About;
