import { baseURL, basics } from "../../../basics";
import UnitTypeBox from "../../Ui/UnitTypeBox";

const ListOfProductsInOrdersDetails = (props: any) => {
  // console.log('props',props);
  return (
    <div className="flex bg-gray-100 m-1 rounded-lg">
      <img
        src={`${baseURL}${props.data.product.imageUrl}`}
        className="h-8 w-8 m-1 rounded-full"
        alt={props.data.product.title}
      />
      <div>
        <div>{props.data.product.title}</div>
        <div className="flex m-1">
          <div className="mx-1">
            <UnitTypeBox
              unit={props.data.quantity*props.data.unit}
              unitType={props.data.unitType}
            />
          </div>
          {/* <p>{props.data.lastDiscountedPrice}</p> */}
          <p>{(props.data.lastDiscountedPrice*props.data.quantity).toLocaleString()}</p>
          <p className="text-rose-600">{basics.priceName}</p>
        </div>
      </div>
    </div>
  );
};

export default ListOfProductsInOrdersDetails;
