import { createSlice } from "@reduxjs/toolkit";

const shopInfoSlice = createSlice({
  name: "shopInfo",
  initialState: {
    shopInfoData: {},
    storeId: 1,
    loading: false,
    received: false,
    SupportPhoneNumber: "09109205060",
    SupportEmail: "support@mivekani.com",
    SupportTelegram: "MiveKani",
    webSiteLink: "https://mivekani.com",
    checkOpenCloseReserveStore:"",
  },
  reducers: {
    replaceShopInfo(state, action) {
      state.shopInfoData = action.payload.shopInfoState.shopInfoData;
    },
    addShopInfo(state, action) {
      state.shopInfoData = action.payload;
    },
    toggleLoading(state, action) {
      state.loading = action.payload;
    },
    toggleShopInfoReceived(state, action) {
      state.received = action.payload;
    },
    changeOpenCloseReserveStore(state, action) {
      state.checkOpenCloseReserveStore = action.payload;
    },
  },
});

export const shopInfoActions = shopInfoSlice.actions;

export default shopInfoSlice.reducer;
