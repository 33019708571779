import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { baseURL, basics } from "../../../basics";
import { userInfoActions } from "../../../store/userInfo-slice";
import logo from "../../../resources/images/app/ic_icon.png";
import { uiActions } from "../../../store/Ui-slice";
import { CgProfile } from "react-icons/cg";
import { AiFillCamera } from "react-icons/ai";
import MainModal from "./MainModal";
import axios from "axios";

const AuthPopUpMenu = () => {
  const dispatch = useDispatch();

  const nameInputRef = useRef<HTMLInputElement>(null);
  const familyNameInputRef = useRef<HTMLInputElement>(null);
  const avatarInputRef = useRef<HTMLInputElement>(null);
  const firstName = useSelector((state: any) => state.userInfo.firstName);
  const lastName = useSelector((state: any) => state.userInfo.lastName);
  const avatar = useSelector((state: any) => state.userInfo.avatar);
  const Token = useSelector((state: any) => state.auth.token);
  // add image:
  const [enteredAvatar, setEnteredAvatar] = useState<any>(null);

  const handleButtonClick = () => {
    if (avatarInputRef.current) {
      avatarInputRef.current!.click();
    }
  };

  const handleFileSelect = (event: any) => {
    const file = event.target.files[0];
    setEnteredAvatar(file);
  };
  const submitUpdatedUserInfoHandler = (event: any) => {
    event.preventDefault();

    const enteredName: string = nameInputRef.current!.value;
    const enteredFamilyName: string = familyNameInputRef.current!.value;

    const bodyFormData = new FormData();
    bodyFormData.append("firstName", enteredName);
    bodyFormData.append("lastName", enteredFamilyName);
    bodyFormData.append("avatar", enteredAvatar);
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "در حال ارسال  ",
        message: "در حال ارسال مشخصات وارد شده ",
      })
    );
    axios({
      method: "post",
      url: `${baseURL}api/User/UpdateProfile?access_token=${Token}`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response: any) {
        dispatch(
          userInfoActions.updateUserInfo({
            enteredName,
            enteredFamilyName,
            enteredAvatar,
          })
        );
        dispatch(uiActions.toggleUpdateUserModal());
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "تایید",
            message: "پروفایل شما ویرایش شد",
          })
        );
      })

      .catch(function (error: any) {
        dispatch(
          uiActions.showNotification({
            status: "error",
            title: "خطا",
            message: "پروفایل شما ویرایش نشد، لطفا دوباره اقدام بفرمایید",
          })
        );
      });
  };

  return (
    <MainModal>
      <div className="min-h-full flex items-center justify-center ">
        <div className="bg-white rounded w-72 sm:w-96 space-y-8">
          <div>
            <img
              className="mx-auto mt-3 h-12 w-auto"
              src={logo}
              alt="Workflow"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              {basics.updateUserProfile}
            </h2>
          </div>
          <form
            onSubmit={submitUpdatedUserInfoHandler}
            className=" mx-2 "
            action="#"
            method="POST"
          >
            <div className=" mb-2">
              <div className="my-2">
                <div className="mb-3">
                  <div className="flex justify-center items-center">
                    <div className="relative">
                      <div onClick={handleButtonClick}>
                        <AiFillCamera className="absolute cursor-pointer bottom-0 bg-red-600 text-zinc-50 h-6 w-6 p-1 rounded-full" />
                        <input
                          type="file"
                          ref={avatarInputRef}
                          style={{ display: "none" }}
                          onChange={handleFileSelect}
                        />
                      </div>
                      {avatar ? (
                        <img
                          className="h-12 w-12 rounded-full"
                          src={`${baseURL}${avatar}`}
                          alt="avatar"
                        />
                      ) : (
                        <CgProfile className="h-12 w-12 rounded-full" />
                      )}
                    </div>
                  </div>
                </div>
                <label htmlFor="name" className="sr-only">
                  {basics.updateUserProfile}
                </label>
                <input
                  id="code"
                  ref={nameInputRef}
                  name="code"
                  type="code"
                  autoComplete="code"
                  required
                  className="appearance-none rounded my-1 bg-gray-100 relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-rose-600 focus:border-rose-600 focus:z-10 sm:text-sm"
                  placeholder={basics.nameOfUser}
                  defaultValue={firstName}
                />
                <input
                  id="code"
                  ref={familyNameInputRef}
                  name="code"
                  type="code"
                  autoComplete="code"
                  required
                  className="appearance-none  rounded my-1 bg-gray-100 relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-rose-600 focus:border-rose-600 focus:z-10 sm:text-sm"
                  placeholder={basics.familyNameOfUser}
                  defaultValue={lastName}
                />
              </div>

              <div className="flex justify-center items-center my-2 mx-auto">
                <button
                  type="submit"
                  className=" relative  flex justify-center items-center py-2 px-4 border border-transparent text-sm font-medium rounded text-white bg-rose-700 hover:bg-rose-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-600"
                >
                  {basics.addUserUpdate}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </MainModal>
  );
};

export default AuthPopUpMenu;
