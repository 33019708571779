import { configureStore } from "@reduxjs/toolkit";
// Redux Persist:
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

import cartReducer from "./cart-slice";
import uiReducer from "./Ui-slice";
import addressReducer from "./address-slice";
import hasItemReducer from "./hasItem";
import authReducer from "./auth-slice";
import categoryReducer from "./category-slice";
import userInfoReducer from "./userInfo-slice";
import userOrdersReducer from "./userOrders-slice";
import shopInfoReducer from "./shopInfo-slice";
import searchReducer from "./search-slice";
import commentsReducer from "./comments-slice";
import ticketReducer from "./ticket-slice";
import targetDateReducer from "./targetDate-slice";
// import basicsSetupsSlice from './basicsSetups-slice'
//Redux Persist:
const persistCategoryConfig = {
  key: "category-root",
  storage,
};
const persistAuthConfig = {
  key: "auth-root",
  storage,
};
const persistCartConfig = {
  key: "cart-root",
  storage,
  // whitelist: ["carts"],
};
const persistAddressConfig = {
  key: "cart-root",
  storage,
 
};
const persistedCategoryReducer = persistReducer(persistCategoryConfig, categoryReducer);
const persistedCartReducer = persistReducer(persistCartConfig, cartReducer);
const persistedAuthReducer = persistReducer(persistAuthConfig, authReducer);
const persistedAddressReducer = persistReducer(persistAddressConfig, addressReducer);

const store = configureStore({
  reducer: {
    cart: persistedCartReducer,
    // cart: cartReducer,
    ui: uiReducer,
    address: persistedAddressReducer,
    hasItem: hasItemReducer,
    auth: persistedAuthReducer,
    category: persistedCategoryReducer,
    userInfo: userInfoReducer,
    userOrders: userOrdersReducer,
    shopInfo: shopInfoReducer,
    search: searchReducer,
    comments: commentsReducer,
    ticket: ticketReducer,
    targetDate: targetDateReducer,
    // basics:basicsSetupsSlice,
  },
  // Redux Persist:
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
  // Redux Persist.
});
export default store;
// Redux Persist:
export const persistor = persistStore(store);
