import { createSlice, current } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    cartIsVisible: false,
    notification: null,
    notificationIsShowing: true,
    authModalPopUp: false,
    authUpdateModalPopUp: false,
    downloadAppModalPopUp: true,
    commentToStoreModalPopUp: false,
    hamburgerModalPopUp: false,
    infoModalPopUp: false,
    commentPopUp: false,
    addressModalPopUp: false,
    paymentModalPopUp: false,
    sendingDataToServerWasSuccessfull: false,
    FavoriteDataIssuccessfullyReceived: false,
  },
  reducers: {
    toggle(state) {
      state.cartIsVisible = !state.cartIsVisible;
    },
    showNotification(state: any, action) {
      state.notification = {
        status: action.payload.status,
        title: action.payload.title,
        message: action.payload.message,
      };
    },
    showNotificationInTimer(state: any, action) {
      state.notificationIsShowing = action.payload
    },
    toggleModal(state) {
      state.authModalPopUp = !state.authModalPopUp;
    },
    toggleUpdateUserModal(state) {
      state.authUpdateModalPopUp = !state.authUpdateModalPopUp;
    },
    toggleDownloadAppModal(state) {
      state.downloadAppModalPopUp = !state.downloadAppModalPopUp;
    },
    toggleCommentToStoreModal(state) {
      state.commentToStoreModalPopUp = !state.commentToStoreModalPopUp;
    },
    toggleHambargerModal(state) {
      state.hamburgerModalPopUp = !state.hamburgerModalPopUp;
    },
    infoModalPopUp(state) {
      state.infoModalPopUp = !state.infoModalPopUp;
    },
    addCommentPopUp(state) {
      state.commentPopUp = !state.commentPopUp;
    },
    addressModalPopUp(state) {
      state.addressModalPopUp = !state.addressModalPopUp;
    },
    paymentModalPopUpToggle(state,action) {
      state.paymentModalPopUp = action.payload;
    },
    sendUserToHome(state,action) {
      state.sendingDataToServerWasSuccessfull = action.payload
    },
    fetchingFavoriteDataIssuccessfull(state,action) {
      state.FavoriteDataIssuccessfullyReceived = action.payload
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice.reducer;
