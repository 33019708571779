import { ImLocation2 } from "react-icons/im";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { Link } from "react-router-dom";
import { uiActions } from "../../../store/Ui-slice";
import { useDispatch, useSelector } from "react-redux";
import AddressInfoButton from "./AddressInfoButton";
import { useState } from "react";
import { TiTick } from "react-icons/ti";

const AddressesInLastStepOfPurchase = (props: any) => {
  const [isAddressInfoButtonClicked, setIsAddressInfoButtonClicked] =
    useState(false);
  const dispatch = useDispatch();

  const addressSelectedData = useSelector(
    (state: any) => state.address.addressSelectedData
  );
  const addressModalPopUpHandler = () => {
    dispatch(uiActions.addressModalPopUp());
    setIsAddressInfoButtonClicked(() => !isAddressInfoButtonClicked);
  };
  const addressModalPopUpHidHandler = () => {
    const data = props.address.id;
    props.onAddressClick(data);
    setIsAddressInfoButtonClicked(false);
  };
  //console.log("isAddressInfoButtonClicked", isAddressInfoButtonClicked);

  return (
    <li className="container p-1 mx-auto">
      <div className="flex justify-between items-center rounded-md  bg-gray-100 ">
        <div
          onClick={addressModalPopUpHidHandler}
          className="flex items-center justify-start w-full cursor-pointer"
        >
          <div className="flex items-center justify-center w-14 h-14 ">
            <ImLocation2 className="w-6 h-6" />
            {addressSelectedData === props.address.id && (
              <TiTick className="text-red-600 absolute w-8 h-8" />
            )}
          </div>
          <div>
            <div className="text-orange-600 text-xl">{props.address.title}</div>
            <div className="text-base">{props.address.description}</div>
          </div>
        </div>
        <div onClick={addressModalPopUpHandler}>
          <BiDotsVerticalRounded className="w-6 h-6 " />
        </div>
      </div>
      {isAddressInfoButtonClicked && (
        <AddressInfoButton address={props.address} />
      )}
    </li>
  );
};

export default AddressesInLastStepOfPurchase;
