import { createSlice } from "@reduxjs/toolkit";

const initialCategoryState = {
  dataOfProducts: [],
  loading: true,
  hasMore: true,
  categoryId: 0,
  itemsPerPage: 6,
  page: 1,
  totalItems: 0,
  productsLength: 0,
  typeOfProductsCategories: "",
};

const categorySlice = createSlice({
  name: "category",
  initialState: initialCategoryState,
  reducers: {
    newCategoryIsSelected(state) {
      // state.categoryId = action.payload;
      state.page = 1;
      state.hasMore = true;
      state.dataOfProducts = [];
    },
    pageChanger(state, action) {
      state.page = action.payload;
    },
    typeOfProductsCategoriesChanger(state, action) {
      state.typeOfProductsCategories = action.payload;
    },
    nextPage(state) {
      state.page++;
      // if (state.itemsPerPage * state.page < state.totalItems) state.page++;
    },

    setHasMore(state, action) {
      state.hasMore = action.payload;
    },
    idOfSelectedCategory(state, action) {
      state.categoryId = action.payload;
    },
    totalItemsOfCategory(state, action) {
      state.totalItems = action.payload;
    },
    totalItemsOfProducts(state, action) {
      state.productsLength = action.payload;
    },
    setDataOfProducts(state, action) {
      state.dataOfProducts = state.dataOfProducts
        ? [...state.dataOfProducts, ...action.payload]
        : action.payload;
    },
    toggleLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const categoryActions = categorySlice.actions;

export default categorySlice.reducer;
