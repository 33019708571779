// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Notification_notification__rhc9v {\n  width: 100%;\n  /* height: 3rem; */\n  background-color: #1a8ed1;\n  display: flex;\n  justify-content: space-between;\n  padding: 1.5rem 10%;\n  align-items: center;\n  color: white;\n}\n\n.Notification_notification__rhc9v h2 {\n  font-size: 1.2rem;\n  margin: 0;\n}\n\n.Notification_error__H9D18 {\n  background-color: #690000;\n}\n\n.Notification_success__YotBa {\n  background-color: #1ad1b9;\n}", "",{"version":3,"sources":["webpack://./src/components/Ui/Notification.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,SAAS;AACX;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".notification {\n  width: 100%;\n  /* height: 3rem; */\n  background-color: #1a8ed1;\n  display: flex;\n  justify-content: space-between;\n  padding: 1.5rem 10%;\n  align-items: center;\n  color: white;\n}\n\n.notification h2 {\n  font-size: 1.2rem;\n  margin: 0;\n}\n\n.error {\n  background-color: #690000;\n}\n\n.success {\n  background-color: #1ad1b9;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notification": "Notification_notification__rhc9v",
	"error": "Notification_error__H9D18",
	"success": "Notification_success__YotBa"
};
export default ___CSS_LOADER_EXPORT___;
