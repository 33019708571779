import { ImLocation2 } from "react-icons/im";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import AddressInfoButton from "./AddressInfoButton";
import { useEffect, useState } from "react";
import location from "../../../resources/images/app/location_on.png";
import home from "../../../resources/images/app/home_g.png";
import restaurant from "../../../resources/images/app/restaurant_menu.png";
import shop from "../../../resources/images/app/shopping_cart.png";
import gym from "../../../resources/images/app/sports_soccer.png";
import { addressActions } from "../../../store/address-slice";

const Address = (props: any) => {
  const [selectedChild, setSelectedChild] = useState(null);
  const dispatch = useDispatch();

  const addressSelectedData = useSelector(
    (state: any) => state.address.addressSelectedData
  );
  // console.log('addressSelectedData',addressSelectedData,props);
  const isAddressInfoButtonClicked = useSelector(
    (state: any) => state.address.isAddressInfoButtonClicked
  );
  useEffect(() => {
    if (!isAddressInfoButtonClicked) {
      setSelectedChild(null);
    }
  }, [isAddressInfoButtonClicked]);
  const showPopUpHandler = (id: any) => {
    setSelectedChild(id);
    dispatch(addressActions.toggleAddressInfoButton(true));
  };

  const hidePopUpHandler = () => {
    if (selectedChild) {
      setSelectedChild(null);
      dispatch(addressActions.toggleAddressInfoButton(false));
    }
  };

  // const addressModalPopUpHandler = (id: any) => {
  //   //console.log("id:any", id);
  //   // dispatch(uiActions.addressModalPopUp());
  //   setIsCurrentAddressInfoButtonClicked(true);
  //   // setIsAddressInfoButtonClicked(() => !isAddressInfoButtonClicked);

  // };
  // const addressModalPopUpHidHandler = () => {
  //   // const data = props.address.id;
  //   // props.onAddressClick(data);
  //   // setIsAddressInfoButtonClicked(false);
  // };
  // //console.log("isAddressInfoButtonClicked", isAddressInfoButtonClicked);
  // const HideAddressInfoButtonPupOpHandler = () => {
  //   if (isCurrentAddressInfoButtonClicked) {
  //     dispatch(addressActions.toggleAddressInfoButton(false));
  //     // setIsAddressInfoButtonClicked(false)
  //     // //console.log("undoHandleee");
  //   }
  // };
  // console.log("ptrops", props);
  const addressImageIcon = () => {
    switch (props.address.icon) {
      case "location":
        return location;
      case "home":
        return home;
      case "restaurant":
        return restaurant;
      case "shop":
        return shop;
      case "gym":
        return gym;
      default:
        return location;
    }
  };
  return (
    <li onClick={hidePopUpHandler} className="container p-1 mx-auto">
      <div className="flex justify-between items-center rounded-md  bg-gray-200 ">
        <div
          // onClick={addressModalPopUpHidHandler}
          className="flex items-center justify-start w-full"
        >
          <div className="flex items-center justify-center w-14 h-14 ">
            {/* <ImLocation2 /> */}
            <img
              src={addressImageIcon()}
              className="w-6 h-6"
              alt="location icon"
            />
            {/* {addressSelectedData === props.address.id && (
              <TiTick className="text-red-600 absolute w-8 h-8" />
            )} */}
          </div>
          <div>
            <div className="text-orange-600 text-xl">{props.address.title}</div>
            <div className="text-base">{props.address.description}</div>
          </div>
        </div>
        <div onClick={() => showPopUpHandler(props.address.id)}>
          <BiDotsVerticalRounded className="w-6 h-6 cursor-pointer" />
        </div>
      </div>
      {selectedChild && <AddressInfoButton address={props.address} />}
    </li>
  );
};

export default Address;
