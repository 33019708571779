import axios from "axios";
import { baseURL } from "../basics";
import { categoryActions } from "./category-slice";
import { uiActions } from "./Ui-slice";

export const fetchShowAllProductsHandler: any = (
  currentPage: any,
  itemsPerPage: any
) => {
  return async (dispatch: any) => {
    dispatch(categoryActions.toggleLoading(true));
    axios({
      method: "get",
      url: `${baseURL}api/Product/GetProducts?PageNumber=${currentPage}&RowCount=${itemsPerPage}`,
      responseType: "stream",
    })
      .then(function (response) {
        const data = response.data.data;

        dispatch(
          categoryActions.totalItemsOfProducts(response.data.totalItems)
        );
        dispatch(categoryActions.setDataOfProducts(data));
        // dispatch(categoryActions.toggleLoading(false));
        if (data.length === 0) {
          dispatch(categoryActions.setHasMore(false));
        }

        //     setProductsLength(response.data.totalItems);
        //     setDataProduct(data);
        //     setResponse(true);
      })
      .catch(function (error: any) {
        dispatch(categoryActions.toggleLoading(false));

        dispatch(
          uiActions.showNotification({
            status: "error",
            title: "خطا!",
            message: "دریافت لیست محصولات از سرور موفق نبود ",
          })
        );
        //     setResponse(false);
        //    console.log("showAllsHandler errorLIST", error);
      });
  };
};
export const fetchShowSpecialsHandler: any = (
  currentPage: any,
  itemsPerPage: any
) => {
  return async (dispatch: any) => {
    //
    //
    //
    //
    dispatch(categoryActions.toggleLoading(true));
    //
    //
    //
    //
    axios({
      method: "get",
      url: `${baseURL}api/Product/GetSpecialProducts?PageNumber=${currentPage}&RowCount=${itemsPerPage}`,
      responseType: "stream",
    })
      .then(function (response) {
        const data = response.data.data;
        dispatch(
          categoryActions.totalItemsOfProducts(response.data.totalItems)
        );
        dispatch(categoryActions.setDataOfProducts(data));
        //  dispatch(categoryActions.setDataOfProducts((prevItems: any[]) => [...prevItems, ...data]));
        
        dispatch(categoryActions.toggleLoading(false));
        if (data.length === 0) {
          dispatch(categoryActions.setHasMore(false));
        }
      })
      .catch(function (error: any) {
        dispatch(categoryActions.toggleLoading(false));
        dispatch(
          uiActions.showNotification({
            status: "error",
            title: "خطا!",
            message: "دریافت لیست محصولات از سرور موفق نبود ",
          })
        );
      });
  };
};
export const fetchShowProductsHandler: any = (
  currentPage: any,
  itemsPerPage: any,
  categoryId: any
) => {
  return async (dispatch: any) => {
    dispatch(categoryActions.toggleLoading(true));
    axios({
      method: "get",
      url: `${baseURL}api/Product/GetByCategory?CategoryId=${categoryId}&PageNumber=${currentPage}&RowCount=${itemsPerPage}`,
      responseType: "stream",
    })
      .then(function (response) {
        const data = response.data.data;
        dispatch(
          categoryActions.totalItemsOfProducts(response.data.totalItems)
        );
        dispatch(categoryActions.setDataOfProducts(data));
        dispatch(categoryActions.toggleLoading(false));
        if (data.length === 0) {
          dispatch(categoryActions.setHasMore(false));
        }
      })
      .catch(function (error: any) {
        dispatch(categoryActions.toggleLoading(false));
        dispatch(
          uiActions.showNotification({
            status: "error",
            title: "خطا!",
            message: "دریافت لیست محصولات از سرور موفق نبود ",
          })
        );
      });
  };
};
