import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Cart from "./components/Main/Cart/Cart";
import ListOfTypes from "./components/Main/ListOfCategories/ListOfTypes";
import ListOfProducts from "./components/Main/ListOfProducts/ListOfProducts";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCartData,
  fetchFavoriteCartData,
  sendCartData,
} from "./store/cart-actions";

import { fetchAddressesItemsData } from "./store/address-actions";
import { cartActions } from "./store/cart-slice";
import SinglePage from "./components/Main/SinglePage/SinglePage";
import About from "./components/Main/Information/About";
import Addresses from "./components/Main/Addresses/Addresses";
import CartInProfile from "./components/Main/Cart/CartInProfile";
import ListOfProductsByCategory from "./components/Main/ListOfProducts/ByCategory/ListOfProductsByCategory";
import axios from "axios";
import { baseURL, basics } from "./basics";
import { userInfoActions } from "./store/userInfo-slice";
import MainPopUpMenu from "./components/Modals/Main/MainPopUpMenu";
import FavoriteProducts from "./components/Main/Favorite/FavoriteProducts";
import Search from "./components/Ui/Search";
import TopNavbar from "./components/Navbar/TopNavbar";
import InfoBar from "./components/Navbar/InfoBar";
import BottomNavbar from "./components/Navbar/BottomNavbar";
import { addressActions } from "./store/address-slice";
import SelectAddressAndAcceptPrices from "./components/Main/Addresses/SelectAddressAndAcceptPrices";
import { fetchShopInfo } from "./store/shopInfo-actions";
import ListOfOrders from "./components/Main/Order/ListOfOrders";
import UserOrderDetails from "./components/Main/Order/UserOrderDetails";
// import HScroll from "./components/Ui/HScroll";
// import Notification from "./components/Ui/Notifications";
import Notification from "./components/Ui/Notifications";
import { uiActions } from "./store/Ui-slice";
import AuthPopUpMenu from "./components/Modals/Auth/AuthPopUpMenu";
import GetStoreComments from "./components/Main/StoreComments/GetStoreComments";
import TicketToSupport from "./components/Main/Ticket/TicketToSupport";
import TicketMainPage from "./components/Main/Ticket/TicketMainPage";
import AddNewTicket from "./components/Main/Ticket/AddNewTicket";
import { searchActions } from "./store/search-slice";
import PaymentPopUpMenu from "./components/Modals/Payment/PaymentPopUpMenu";
import Pricing from "./components/Main/Information/Pricing";
import Quality from "./components/Main/Information/Quality";
import Warranty from "./components/Main/Information/Warranty";
import Rules from "./components/Main/Information/Rules";
import CallToUs from "./components/Main/Information/CallToUs";
import ListOfSpecialProducts from "./components/Main/ListOfProducts/ListOfSpecialProducts";
import UpdateUser from "./components/Modals/UpdateProfile/UpdateUser";
import MainFooter from "./components/Footer/MainFooter";
import Privacy from "./components/Main/Information/Privacy";
import SendAndHowCalculatePayments from "./components/Navbar/SendAndHowCalculatePayments";
import GoBack from "./components/Ui/GoBackButton";
import PeriodsPopUpMenu from "./components/Modals/Periods/PeriodsPopUpMenu";
import EditAddress from "./components/Main/Addresses/EditAddress";
import SearchingProducts from "./components/Main/Search/SearchingProducts";
import { categoryActions } from "./store/category-slice";
// import useCartItemsAreEqual from "./components/Hooks/useCartItemsAreEqual";
import AddCommentToStore from "./components/Modals/AddComment/AddCommentToStore";
import DownloadAppPopUpMenu from "./components/Modals/DownloadApp/DownloadAppPopUpMenu";
// import LocationSelector from "./components/Main/Map/MapBox/LocationSelector";
// import Share from "./components/Main/Information/Share";
// import Warranty from "./components/Main/Information/warranty";
let isInitial = true;
function App() {
  //   const number = 1234567.89;
  // const arabicNumber = number.toLocaleString("fa-EG");
  // //console.log("arabicNumber",arabicNumber); // Output: ١٬٢٣٤٬٥٦٧٫٨٩
  // const getLocalCheck = useCartItemsAreEqual();
  // const { cartItemsAreEqual } = useCartItemsAreEqual();

  const dispatch = useDispatch();
  const history = useHistory();
  const LoggedIn = useSelector((state: any) => state.auth.isLoggedIn);

  const [categoryOfProductData, setCategoryOfProductData] = useState([""]);
  const [categoryOfProductDataResponse, setCategoryOfProductDataResponse] =
    useState(false);
  const notification = useSelector((state: any) => state.ui.notification);
  const notificationIsShowing = useSelector(
    (state: any) => state.ui.notificationIsShowing
  );
  // search:
  const sendUserToSearchPageHandler = () => {
    if (LoggedIn) {
      dispatch(searchActions.userClickedOnSearch(true));
      history.push("/search");
    } else {
      dispatch(uiActions.toggleModal());
      history.push("/");
    }
  };
  useEffect(() => {
    if (notification) {
      // setShowDiv(true);
      setTimeout(
        () => dispatch(uiActions.showNotificationInTimer(false)),
        5000
      );
      dispatch(uiActions.showNotificationInTimer(true));
      // setTimeout(() => setNotiOnTimer(false), 5000);
      // setNotiOnTimer();
    }
  }, [dispatch, notification]);

  const cartIsShown = useSelector((state: any) => state.ui.cartIsVisible);
  const cart = useSelector((state: any) => state.cart);
  // //console.log("useSelector", cart);
  const Token = useSelector((state: any) => state.auth.token);
  // const notification = useSelector((state: any) => state.ui.notification);
  const clickedOnLoginButton = useSelector(
    (state: any) => state.ui.authModalPopUp
  );
  const clickedOnUpdateUserButton = useSelector(
    (state: any) => state.ui.authUpdateModalPopUp
  );
  const clickedOnCommentToStoreButton = useSelector(
    (state: any) => state.ui.commentToStoreModalPopUp
  );
  const clickedOnFinalSubmitButton = useSelector(
    (state: any) => state.ui.paymentModalPopUp
  );
  const clickedOnPeriodsListButton = useSelector(
    (state: any) => state.targetDate.showPeriodsList
  );

  const isHamClicked = useSelector(
    (state: any) => state.ui.hamburgerModalPopUp
  );
  // const isMounted = useRef<boolean>();
  // useEffect(() => {
  //   // -------------check Local cart Items with server----------------
  //   if (getLocalCheck) {
  //     //console.log("getLocalCheck", getLocalCheck);
  //   }
  // }, [getLocalCheck]);
  useEffect(() => {
    // if (isMounted.current) return;
    // --------get Categories firstItem & Categories Data---------
    axios({
      method: "get",
      url: `${baseURL}api/Category/GetHomePageCategories`,
      responseType: "stream",
    })
      .then(function (response) {
        const categoryData: any = response.data;

        // const idFirstData = response.data[0].id;
        // const dataLength = response.data.length;
        setCategoryOfProductData(categoryData);
        setCategoryOfProductDataResponse(true);
        // setDataFirstCategoryes(idFirstData);
        // dispatch(categoryActions.idOfCategory(idFirstData));
        // setDataCategoryesLength(dataLength);
        // //console.log("AppppcategoryUrl:", categoryData);
      })
      .catch(function () {
        //console.log("errorLIST", error);
      });
    // ------------getUserInfo-----------------
    if (Token) {
      axios({
        method: "get",
        url: `${baseURL}api/User/GetUserInfo?access_token=${Token}`,
      })
        .then((response: any) => {
          dispatch(userInfoActions.avatarChanger(response.data.avatar));
          dispatch(userInfoActions.firsNameChanger(response.data.firstName));
          dispatch(userInfoActions.lastNameChanger(response.data.lastName));
          // console.log("GetUserInfo", response, response.data.firstName);
        })
        .catch((error: any) => {
          console.log("------errorLIST", error);
        });

      // -------------getFavoriteProducts----------------
      dispatch(fetchFavoriteCartData(Token));
      dispatch(fetchCartData(Token));
      dispatch(fetchAddressesItemsData(Token));
    }
    // dispatch(fetchShopInfo())
    // isMounted.current = true;
  }, [Token, dispatch]);

  // useEffect(() => {

  //     if(serverAndLocalItemsCheck){
  //       console.log('cartItemsAreEqual',cartItemsAreEqual);
  //     }
  //     // const timer = setTimeout(() => {
  //     //   if (Object.keys(getLocalCheck).length === 0) {
  //     //     console.log("getLocalCheck", getLocalCheck);
  //     //   } else {
  //     //     console.log("getLocalCheck is empty", getLocalCheck);
  //     //   }
  //     //   // dispatch(sendAddressItemsData(address,Token))
  //     //   //console.log("dispatch(fetchAddressesItemsData(Token));");
  //     //   dispatch(cartActions.compareServerAndLocalItems(false));
  //     // }, 1000);
  //     // return () => {
  //     //   clearTimeout(timer);
  //     // };

  // }, [checkServerAndLocalItems, dispatch, serverAndLocalItemsCheck]);

  useEffect(() => {
    if (isInitial) {
      isInitial = false;
      return;
    }
    //console.log("cart.changed", cart.changed);
    if (cart.changed) {
      const timer = setTimeout(() => {
        //console.log(
        // "----------------setTimer for sendCartData-------------------"
        // );
        // console.log("cart", cart);
        dispatch(sendCartData(cart, Token));
        //console.log("dispatch(cartActions.toggleChangeToFalse());");
        dispatch(cartActions.toggleChangeToFalse());
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
    // //console.log("cart", cart);
  }, [cart, dispatch, Token]);
  useEffect(() => {
    // fetch(`${baseURL}api/General/GetInfo`)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     dispatch(shopInfoActions.addShopInfo(data));
    //     //console.log("OverallInfo", data);
    //   });
    dispatch(fetchShopInfo());
  }, [dispatch]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const hideLoginHandler = () => {
    setIsLoggedIn(false);
  };
  const isAddressInfoButtonClicked = useSelector(
    (state: any) => state.address.isAddressInfoButtonClicked
  );
  const toggleInfoButton = () => {
    if (isAddressInfoButtonClicked) {
      dispatch(addressActions.toggleAddressInfoButton(false));
    } else {
      // this is for search list received:
      dispatch(searchActions.toggleSearchInfoButton(false));
      dispatch(searchActions.addNewListOfSearchData([]));
    }
  };
  const addressSelectedForEditData = useSelector(
    (state: any) => state.address.addressSelectedForEditData
  );
  // console.log("addressSelectedForEditData", addressSelectedForEditData);
  const newAddressSelected = {
    description: "",
    icon: "location",
    id: "",
    latitude: null,
    longitude: null,
    title: "",
  };
  useEffect(() => {
    if ("geolocation" in navigator) {
      // console.log(" getting location:");
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          // setLat(latitude);
          // setLng(longitude);
          dispatch(
            addressActions.initiateMarkerAndViewState([latitude, longitude])
          );
          // setMarker({ latitude: latitude, longitude: longitude });
          // setInitialViewState({ latitude: latitude, longitude: longitude,zoom:12 });
          // console.log("setMarker:",latitude, longitude);
        },
        (error) => {
          console.error("Error getting location:", error.message);
          // dispatch(addressActions.initiateMarkerAndViewState([35.31136423226539, 46.9961286574362] ))
        }
      );
    } else {
      console.log("Geolocation is not available in this browser.");
    }
  }, [dispatch]);
  // This code sets the isMobile variable to true if the user agent string contains "iPhone", "iPad", "iPod", or "Android", which are typical user agent strings for mobile devices.
  // const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const isMobile = /Android/i.test(navigator.userAgent);
  // assuming 768 pixels is the threshold for mobile devices
  // TODO: make a hook that give you an out put that you will understand user use the phone or not and you will understand how u change your ui
  const isMobileWidth = window.innerWidth < 768;
  console.log("isMobile", isMobile, isMobileWidth);
  const downloadAppModalPopUp = useSelector(
    (state: any) => state.ui.downloadAppModalPopUp
  );
  return (
    <div
      onClick={toggleInfoButton}
      className="overflow-hidden max-w-7xl mx-auto mb-20"
    >
      {downloadAppModalPopUp && isMobile && <DownloadAppPopUpMenu />}
      {notification && notificationIsShowing && (
        <Notification
          status={notification.status}
          title={notification.title}
          message={notification.message}
        />
      )}
      <div className="hidden sm:flex">
        <TopNavbar />
      </div>
      <div className="fixed top-0 w-full z-20 sm:hidden">
        <div className="my-2 mr-4 ">
          <GoBack />
        </div>
      </div>
      <BottomNavbar />

      {clickedOnFinalSubmitButton && (
        <PaymentPopUpMenu onHideLogin={hideLoginHandler} />
      )}
      {clickedOnLoginButton && <AuthPopUpMenu onHideLogin={hideLoginHandler} />}
      {clickedOnUpdateUserButton && <UpdateUser />}
      {clickedOnCommentToStoreButton && <AddCommentToStore />}

      {cartIsShown && <Cart />}
      {isHamClicked && <MainPopUpMenu />}

      <Switch>
        <Route path="/" exact>
          <Redirect to="/home" />
        </Route>
        <Route path="/home" exact>
          <TopNavbar />
          <div className="bg-sky-100">
            <InfoBar />
            <div
              onClick={sendUserToSearchPageHandler}
              className="cursor-pointer"
            >
              <Search search={basics.productsSearch} />
            </div>
            <ListOfTypes />
            <ListOfSpecialProducts />
            {categoryOfProductDataResponse &&
              categoryOfProductData.map((category: any) => (
                <ListOfProductsByCategory
                  key={category.id}
                  id={category.id}
                  imageUrl={category.imageUrl}
                  name={category.name}
                />
              ))}
          </div>
          <MainFooter />
        </Route>
        <Route path="/search" exact>
          <SearchingProducts />
        </Route>
        <Route path="/favoriteProducts" exact>
          <FavoriteProducts />
        </Route>
        <Route path="/products" exact>
          <ListOfProducts />
        </Route>
        <Route path="/products/productItem/:productId" exact>
          <SinglePage />
        </Route>
        <Route path="/cartOfUser" exact>
          <CartInProfile />
        </Route>
        <Route path="/listOfOrders" exact>
          <ListOfOrders />
        </Route>
        <Route path="/listOfOrders/userOrderDetails/:orderId" exact>
          <UserOrderDetails />
        </Route>
        <Route path="/selectAddressAndAcceptPrices" exact>
          {clickedOnPeriodsListButton && <PeriodsPopUpMenu />}
          <SelectAddressAndAcceptPrices />
        </Route>
        <Route path="/addresses" exact>
          <Addresses />
        </Route>
        <Route path="/addresses/addNewAddress" exact>
          <EditAddress addressSelectedForEditData={newAddressSelected} />
        </Route>
        <Route path="/addresses/editAddress" exact>
          <EditAddress
            addressSelectedForEditData={addressSelectedForEditData}
          />
        </Route>
        <Route path="/getStoreComments" exact>
          <GetStoreComments />
        </Route>
        <Route path="/sendAndHowCalculatePayments" exact>
          <SendAndHowCalculatePayments />
        </Route>
        <Route path="/about" exact>
          <About />
        </Route>
        <Route path="/privacy" exact>
          <Privacy />
        </Route>
        <Route path="/pricing" exact>
          <Pricing />
        </Route>
        <Route path="/rules" exact>
          <Rules />
        </Route>
        <Route path="/warranty" exact>
          <Warranty />
        </Route>
        <Route path="/quality" exact>
          <Quality />
        </Route>
        <Route path="/contactToSupport" exact>
          <CallToUs />
        </Route>
        <Route path="/ticketToSupport" exact>
          <TicketToSupport />
        </Route>
        <Route path="/ticketToSupport/addNewTicket" exact>
          <AddNewTicket />
        </Route>
        <Route path="/ticketToSupport/:ticketId" exact>
          <TicketMainPage />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
