import { Link, NavLink, Route, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { basics } from "../../../basics";
import { cartActions } from "../../../store/cart-slice";
import { addressActions } from "../../../store/address-slice";
import { deleteAddresseItemsData } from "../../../store/address-actions";

const AddressInfoButton = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const Token = useSelector((state: any) => state.auth.token);
  //console.log("props.address", props.address);
  const addressItem = useSelector(
    (state: any) => state.address.addressItems.items
  );
  const existingCartItemPlusOneItem = addressItem?.find(
    (item: any) => item.id === props.address.id
  )
    ? true
    : false;
  //console.log("existingCartItemPlusOneItem", existingCartItemPlusOneItem);
  const editItemHandler = () => {
    dispatch(addressActions.addAddressSelectedForEditData(props.address));
    history.push("/addresses/editAddress");
    //console.log("editItemHandler");
    // dispatch(addressActions.toggleLoading(true));c
    // <Route path="/addNewAddress" exact>
    // <AddNewAddress />
    // </Route>;
    // //console.log("props.address.id", props.address.id, Token);
    // dispatch(deleteAddresseItemsData(props.address.id, Token));
    // dispatch(addressActions.toggleChange());
    // dispatch(addressActions.removeItemFromAddresses(props.address.id));
  };
  const removeItemHandler = () => {
    // dispatch(addressActions.toggleLoading(true));c
    //console.log("props.address.id", props.address.id, Token);
    dispatch(deleteAddresseItemsData(props.address.id, Token));
    dispatch(addressActions.toggleChange());
    dispatch(addressActions.removeItemFromAddresses(props.address.id));
  };
  return (
    <div className="flex justify-end items-center z-20 relative">
      <ul className="flex flex-col w-24 bg-red-100 rounded-lg absolute left-0 -top-5">
        <li
          onClick={editItemHandler}
          className="cursor-pointer block py-2 pr-4 pl-3 border-b border-gray-100 hover:rounded-t-lg hover:bg-red-400 "
        >
          {basics.edit}
        </li>

        <li
          onClick={removeItemHandler}
          //   activeClassName="bg-red-500 text-white rounded-lg"
          className="cursor-pointer block py-2 pr-4 pl-3 border-b border-gray-100 hover:rounded-b-lg hover:bg-red-400 "
        >
          {basics.delete}
        </li>
      </ul>
    </div>
  );
};

export default AddressInfoButton;
