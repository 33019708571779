import { basics } from "../../../basics";
import HowUsersCallToSupport from "./HowUsersCallToSupport";

const CallToUs = () => {
  return (
    <div className="mb-24 mt-4 sm:my-24 flex flex-col justify-center items-center m-2">
      <p className="font-bold">{basics.callUs}</p>
      <div  className="bg-gray-100 rounded-lg p-2 mx-2 my-12 flex flex-col items-center max-w-2xl text-gray-900">
      <HowUsersCallToSupport />

      </div>
    </div>
  );
};

export default CallToUs;

// function CallButton() {
