import React from "react";
import { MdMyLocation } from "react-icons/md";

const CustomButton = ({ selectButton }: any) => {
  return (
    <button className="" onClick={selectButton}>
      <MdMyLocation className="absolute bottom-8 text-zinc-50 bg-rose-500 w-10 h-10 mb-1 mr-1 p-2 rounded-full cursor-pointer z-10" />
    </button>
  );
};

export default CustomButton;
