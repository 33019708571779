import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

// import SinglePage from "../SinglePage/SinglePage";
import { baseURL, basics } from "../../../basics";

import LikeAndUnLikeButton from "../../Ui/LikeAndUnLikeButton";

const FavoriteItem = (props: any) => {
  const { id, title, price, imageUrl, description } = props.items;
  console.log("description", description);
  const [descriptions, setDescriptions] = useState("");
  const [firstLineDesc, setFirstLineDesc] = useState("");
  // const [showSinglePage, setShowSinglePage] = useState(false);
  const history = useHistory();

  const showProductPageHandler = () => {
    //console.log("showProductPageHandler", id);
    // setShowSinglePage(true);
  };

  useEffect(() => {
    async function fetchDescription() {
      try {
        const rangeStart = 0;
        const rangeEnd = 150;
        const text = await description;
        const hasNewline: any = text.includes("\n");
        if (hasNewline) {
          const paragraphs = description.split("\n");

          const lastWordBoundaryForFirstPara = paragraphs[0].lastIndexOf(
            ". ",
            rangeEnd
          );
          const longFirstPara = lastWordBoundaryForFirstPara >= rangeStart;
          const endofFirstPara = longFirstPara
            ? lastWordBoundaryForFirstPara
            : rangeEnd;
          const threeDotFirstPara =
            lastWordBoundaryForFirstPara >= rangeStart ? "..." : "";
          const truncatedTextFirstPara = `${paragraphs[0].substring(
            rangeStart,
            endofFirstPara
          )} ${threeDotFirstPara}`;

          const lastWordBoundary = paragraphs[1].lastIndexOf(" ", rangeEnd);
          // console.log("lastWordBoundary", lastWordBoundaryForFirstPara);
          // If the last word boundary is within the range, use it as the end of the substring
          const end =
            lastWordBoundary >= rangeStart ? lastWordBoundary : rangeEnd;
          const threeDot = lastWordBoundary >= rangeStart ? "..." : "";

          // Extract the substring within the specified range
          const truncatedText = `${paragraphs[1].substring(
            rangeStart,
            end
          )} ${threeDot}`;
          if (longFirstPara) {
            setFirstLineDesc(truncatedTextFirstPara);
          } else {
            setFirstLineDesc(truncatedTextFirstPara);
            setDescriptions(truncatedText);
          }
        } else {
          const paragraphs = description;

          // Find the last full word boundary within the range
          const lastWordBoundary = paragraphs.lastIndexOf(" ", rangeEnd);

          // If the last word boundary is within the range, use it as the end of the substring
          const end =
            lastWordBoundary >= rangeStart ? lastWordBoundary : rangeEnd;
          const threeDot = lastWordBoundary >= rangeStart ? "..." : "";
          // Extract the substring within the specified range
          const truncatedText = `${paragraphs.substring(
            rangeStart,
            end
          )} ${threeDot}`;
          // setFirstLineDesc(paragraphs);
          setDescriptions(paragraphs);
          // console.log("truncatedText", truncatedText);
        }
      } catch (error) {
        //console.log("fetchDescription", error);
      }
    }
    if (description) {
      fetchDescription();
    }
  }, [description]);
  const toggleCartHandler = () => {
    history.push(`/products/productItem/${id}`);
  };

  return (
    <div>
      {/* {!showSinglePage && ( */}
      <form className=" max-w-sm  bg-gray-100 rounded-lg shadow-md  my-2 mx-1">
        <div className="relative">
          {/* is it true??? */}
          <div onClick={toggleCartHandler}>
            <div className="flex justify-between items-center ">
              <div className=" px-2 flex flex-col justify-start items-start">
                <div onClick={showProductPageHandler}>
                  <p className="text-lg h-12 font-bold text-gray-900 flex justify-start items-center">
                    {title}
                  </p>
                </div>
                <div onClick={showProductPageHandler}>
                  <p className="text-xs text-justify py-3 text-gray-400 flex flex-col justify-center items-start">
                    <p>{firstLineDesc}</p>
                    <p>{descriptions}</p>
                  </p>
                </div>
                <p className="m-1">{`${price.toLocaleString()}${
                  basics.priceName
                }`}</p>
              </div>

              <img
                src={`${baseURL}${imageUrl}`}
                alt={title}
                className="w-24 h-24 my-6 rounded-full border-4 border-gray-600"
              />
            </div>
          </div>

          <LikeAndUnLikeButton item={props.items} />
        </div>
      </form>
      {/* )} */}
      {/* {showSinglePage && <SinglePage id={id} />} */}
    </div>
  );
};
export default FavoriteItem;
