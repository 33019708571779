import { useHistory } from "react-router-dom";
import { baseURL } from "../../../basics";
import { useDispatch, useSelector } from "react-redux";
import { categoryActions } from "../../../store/category-slice";
import { fetchShowProductsHandler } from "../../../store/category-actions";

const ListOfCategoriesData = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const itemsPerPage = useSelector((state: any) => state.category.itemsPerPage);
  const currentPage = useSelector((state: any) => state.category.page);
  const clickOnCategoryItemHandler = () => {
    // console.log("props.id", props.id);
    // console.log('clickOnCategoryItemHandler',props.id);
    dispatch(categoryActions.newCategoryIsSelected());
    dispatch(categoryActions.typeOfProductsCategoriesChanger(""));
    // dispatch(fetchShowProductsHandler(currentPage, itemsPerPage, props.id));
    dispatch(categoryActions.idOfSelectedCategory(props.id));
    history.push("/products");
  };
  return (
    // <Link to={`/products/${props.id}`}>
    <div
      onClick={clickOnCategoryItemHandler}
      className="flex justify-center items-center bg-white rounded-lg min-w-max  hover:opacity-75 cursor-pointer p-2 mx-1"
    >
      <img
        src={`${baseURL}${props.imageUrl}`}
        alt={props.name}
        className="h-6 w-6 rounderd-full mx-2 my-1"
      />
      <p className="text-base font-semibold text-gray-900 flex justify-center items-center">
        {props.name}
      </p>
    </div>
    // </Link>
  );
};
export default ListOfCategoriesData;
