import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "../../../basics";
import GradeDescription from "../../Ui/GradeDescription";
import UnitTypeBox from "../../Ui/UnitTypeBox";
import ShowPriceInCardItem from "../ListOfProducts/ShowPriceInCardItem";
import CheckStockAndChangeButton from "../../Ui/CheckStockAndChangeButton";
import EraseItemFromCartButton from "../../Ui/EraseItemFromCartButton";
import { cartActions } from "../../../store/cart-slice";

const ListOfCartItemsInCartInProfile = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state: any) => state.cart.items.items);
  const eraseItemHandler = (id: any) => {
    // console.log("id", id);
    dispatch(cartActions.eraseItemFromCart(id));
  };
  return (
    <div>
      {cartItems.map((item: any) => (
        <div
          // onClick={checkId}
          key={item.product.id}
          className="flex justify-start items-start hover:bg-gray-200 hover:rounded-lg px-1 py-2 my-1 relative h-36 rounded-lg bg-gray-100"
        >
          <div className="flex ">
            <div className="">
              <img
                src={`${baseURL}${item.product.imageUrl}`}
                alt={item.product.title}
                // onClick={showProductPageHandler}
                className="w-16 h-16 rounded-lg border border-2 border-slate-600"
              />
            </div>
            <div className="flex flex-col items-start justify-between mx-2">
              <div className="font-bold text-sm py-1">{item.product.title}</div>
              <div className="flex flex-col justify-start items-start ">
                <div className="ml-2">
                  <GradeDescription
                    grade={item.product.grade}
                    color={"black"}
                  />
                </div>
                <div className="flex justify-start items-center ">
                  <UnitTypeBox
                    unit={item.product.unit}
                    unitType={item.product.unitType}
                  />
                  <ShowPriceInCardItem
                    price={item.product.price}
                    discountedPrice={item.product.discountedPrice}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={() => eraseItemHandler(item.product.id)}
            className="absolute left-0 top-0 my-2 ml-2"
          >
            <EraseItemFromCartButton />
          </div>
          <div className="absolute left-0 bottom-0 my-2 ml-2">
            <CheckStockAndChangeButton
              items={item.product}
              parentStyles={""}
              childStyles={"flex-row-reverse bg-blue-400 hover:bg-blue-500"}
            />
            {/* <AddAndRemoveItemButton
              style={{ name: "flex-row bg-blue-400 hover:bg-blue-500" }}
              data={{
                id: item.product.id,
                imageUrl: item.product.imageUrl,
                title: item.product.title,
                price: item.product.price,
                // totalPrice: item.product.totalPrice,
                grade: item.product.grade,
                quantity: item.quantity,
              }}
            /> */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ListOfCartItemsInCartInProfile;
