import { basics } from "../../../basics";
import cartIsEmpty from '../../../resources/images/app/cartIsEmpty.png'
const YourCartIsEmpety = () => {
  return (
    <div className="my-32 flex flex-col justify-center items-center text-lg text-rose-600 font-bold">
      <div>
        <img src={cartIsEmpty} className="w-32" alt="" />
      </div>
      <div>{basics.YourCartIsEmpety}</div>
    </div>
  );
};

export default YourCartIsEmpety;
