import { basics } from "../../../../basics";
import ListOfCategoriesData from "../../ListOfCategories/ListOfCategoriesData";
import getAll from "../../../../resources/images/app/banner_background.png";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchShowAllProductsHandler,
  fetchShowSpecialsHandler,
} from "../../../../store/category-actions";
import { categoryActions } from "../../../../store/category-slice";
import { useHistory } from "react-router-dom";

const DataCategoresItem = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const itemsPerPage = useSelector((state: any) => state.category.itemsPerPage);
  const currentPage = useSelector((state: any) => state.category.page);
  const showSpecialsHandler = () => {
    dispatch(categoryActions.newCategoryIsSelected());
    dispatch(categoryActions.typeOfProductsCategoriesChanger("special"));
    // dispatch(fetchShowSpecialsHandler(currentPage, itemsPerPage));
    history.push("/products");
  };
  const showAllsHandler = () => {
    dispatch(categoryActions.newCategoryIsSelected());
    dispatch(categoryActions.typeOfProductsCategoriesChanger("allProducts"));
    // dispatch(fetchShowAllProductsHandler(currentPage, itemsPerPage));
    // console.log('r(currentPage',currentPage);
    history.push("/products");
  };
  return (
    <div className="flex justify-start items-center overflow-x-scroll overflow-y-hidden ml-2">
      {props.dataCategory.map((item: any) => (
        <ListOfCategoriesData
          key={item.id}
          id={item.id}
          name={item.name}
          imageUrl={item.imageUrl}
        />
      ))}
      <div
        onClick={showSpecialsHandler}
        className="flex justify-center items-center bg-white rounded-lg min-w-max  hover:opacity-75 cursor-pointer p-2 mx-1"
      >
        <img
          src={getAll}
          alt={basics.specialOrderTitle}
          className="h-6 w-6 rounderd-full mx-2 my-1"
        />
        <p className="text-base font-semibold text-gray-900 flex justify-center items-center">
          {basics.specialOrderTitle}
        </p>
      </div>
      <div
        onClick={showAllsHandler}
        className="flex justify-center items-center bg-white rounded-lg min-w-max  hover:opacity-75 cursor-pointer p-2 mx-1"
      >
        <img
          src={getAll}
          alt={basics.getAll}
          className="h-6 w-6 rounderd-full mx-2 my-1"
        />
        <p className="text-base font-semibold text-gray-900 flex justify-center items-center">
          {basics.getAll}
        </p>
      </div>
    </div>
  );
};

export default DataCategoresItem;
