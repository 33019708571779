import { BsX } from "react-icons/bs";
const EraseItemFromCartButton = () => {
  return (
    <div>
      <BsX className="w-6 h-6 cursor-pointer"/>
    </div>
  );
};

export default EraseItemFromCartButton;
