import { Link } from "react-router-dom";
import { basics } from "../../../basics";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../../store/cart-slice";
import { fetchCartData, sendCartData } from "../../../store/cart-actions";
import { useEffect, useState } from "react";

const PurchaseInvoicePrices = () => {
  //   const dispatch = useDispatch();
  // const getLocalCheck = useCartItemsAreEqual();
  //   const cartItems = useSelector((state: any) => state.cart.items.items);
  const totalPrice = useSelector((state: any) => state.cart.totalPrice);
  const totalDiscountedPrice = useSelector(
    (state: any) => state.cart.totalDiscountedPrice
  );

  //  console.log('totalPrice,totalDiscountedPrice',totalPrice,totalDiscountedPrice);
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  // const deliveryAmount = useSelector(
  //   (state: any) => state.cart.items.deliveryAmount
  // );
  const deliveryAmount = useSelector(
    (state: any) => state.shopInfo.shopInfoData.deliveryAmount
  );
  const deliveryAmountDiscountedFrom = useSelector(
    (state: any) => state.shopInfo.shopInfoData.deliveryAmountDiscountedFrom
  );
  useEffect(() => {
    if (deliveryAmountDiscountedFrom > totalPrice) {
      setDeliveryPrice(deliveryAmount);
    } else {
      setDeliveryPrice(0);
    }
  }, [deliveryAmount, deliveryAmountDiscountedFrom, totalPrice]);
  //   const AllProductsQuantity = useSelector(
  //     (state: any) => state.cart.totalQuantity
  //   );
  //   const cart = useSelector((state: any) => state.cart);
  //   const Token = useSelector((state: any) => state.auth.token);
  //   const checkCartHandler = () => {
  //     dispatch(cartActions.toggleChanges());
  //     dispatch(fetchCartData(Token));
  //     dispatch(sendCartData(cart, Token));
  //     //console.log("checkCartHandler");
  //   };

  return (
    <div className="mx-3 font-medium text-sm">
      {totalPrice === totalDiscountedPrice ? (
        <div className="border border-1">
          <div className="flex justify-between m-1 ">
            <p>{basics.totalCost}</p>
            <p>{`${totalPrice.toLocaleString()} ${basics.priceName}`}</p>
          </div>
        </div>
      ) : (
        <div className="border border-1 border-rose-600 text-rose-900 mb-1 rounded-lg mb-1">
          <div className="flex font-medium justify-between text-sm m-1">
            <p>{basics.totalCostWithDiscount}</p>
            <p className="line-through">{`${totalPrice.toLocaleString()} ${basics.priceName}`}</p>
            <p>{`${totalDiscountedPrice.toLocaleString()} ${basics.priceName}`}</p>
          </div>
        </div>
      )}
      <div className="border border-1">
        <div className="flex justify-between inline-block  m-1">
          <p>{basics.shippingPrice}</p>
          {deliveryPrice !== 0 ? (
            <p>{`${deliveryPrice.toLocaleString()} ${basics.priceName}`}</p>
          ) : (
            <p className="bg-orange-300 rounded px-2 text-rose-600">{basics.freeShippingPrice}</p>
          )}
        </div>
      </div>
      {/* <div className="border rounded-lg border-1 border-rose-600 mb-1"> */}
      <div className="border border-1 mb-1">
        <div className="flex font-medium justify-between text-sm m-1">
          <p>{basics.totalPrice}</p>
          {totalPrice === totalDiscountedPrice ? (
            <p>{`${(totalPrice + deliveryPrice).toLocaleString()} ${basics.priceName}`}</p>
          ) : (
            <p>{`${(totalDiscountedPrice + deliveryPrice).toLocaleString()} ${
              basics.priceName
            }`}</p>
          )}
        </div>
      </div>
    </div>
    // </div>
  );
};

export default PurchaseInvoicePrices;
