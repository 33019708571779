import { useDispatch, useSelector } from "react-redux";
import FavoriteItem from "./FavoriteItem";
import Search from "../../Ui/Search";
import { basics } from "../../../basics";
import { fetchFavoriteCartData } from "../../../store/cart-actions";
import { useEffect, useState } from "react";
import Loading from "../../Ui/Loading";
import { uiActions } from "../../../store/Ui-slice";
import { useHistory } from "react-router-dom";
import YourFavoriteListIsEmpty from "./YourFavoriteListIsEmpty";
const FavoriteProducts = () => {
  const Token = useSelector((state: any) => state.auth.token);
  // const [response, setResponse] = useState(false);
  const dispatch = useDispatch();
  const FavoriteDataIssuccessfullyReceived = useSelector(
    (state: any) => state.ui.FavoriteDataIssuccessfullyReceived
  );

  const favoriteItems = useSelector(
    (state: any) => state.cart.favoriteItems.items
  );
  useEffect(() => {
    dispatch(fetchFavoriteCartData(Token));
    // if(){}
    dispatch(uiActions.fetchingFavoriteDataIssuccessfull(false));
    // setResponse(true);
  }, [Token, dispatch]);

  const LoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const history = useHistory();
  if (!LoggedIn) {
    dispatch(uiActions.toggleModal());
    history.push("/");
  }

  if (!FavoriteDataIssuccessfullyReceived) {
    return (
      <div className="my-24 mx-auto">
        <Loading />
      </div>
    );
  }
  // console.log('favoriteItems',favoriteItems);
  if (favoriteItems.length===0) {
    return <YourFavoriteListIsEmpty />;
  }
  return (
    <div className="my-24 max-w-4xl mx-auto">
      {/* <Search search={basics.productsSearch} /> */}
      <ul className="md:grid md:grid-cols-2">
        {favoriteItems.map((item: any) => (
          <FavoriteItem key={item.id} items={item} />
          // <div key={item.id}>{item.title}</div>
        ))}
      </ul>
    </div>
  );
};

export default FavoriteProducts;
