import { useSelector } from "react-redux";
import { basics } from "../../../basics";
import { IoMdSend } from "react-icons/io";
import Loading from "../../Ui/Loading";
const ReplyToTicket = ({ sendTicketHandler, messageInputRef }: any) => {
  const loadingSendNewReplyForTicket = useSelector(
    (state: any) => state.ticket.loadingSendNewReplyForTicket
  );
  return (
    <form
      action="#"
      className="flex justify-center w-full bg-gray-900 rounded-t-lg p-1"
    >
      <button
        className=" text-zinc-50 cursor-pointer flex justify-center items-center "
        onClick={sendTicketHandler}
      >
        {loadingSendNewReplyForTicket ? (
          <div className="w-8 p-1 ">
            <Loading />
          </div>
        ) : (
          <IoMdSend className="w-8 h-8 p-1" />
        )}
      </button>
      <input
        ref={messageInputRef}
        required
        className="p-1 h-12 w-full max-w-20 bg-gray-200 border-0 border-gray-900 rounded-r"
        type="text"
        placeholder="پیام"
      />

      {/* {typedComment > 3 ? ( */}
    </form>
  );
};

export default ReplyToTicket;
