import { basicUnitType, basics } from "../../basics";

const UnitTypeBox = (props: any) => {

  return (
    <div className="flex justify-center text-center bg-orange-200 text-orange-800 rounded text-xs my-0 mx-1 p-1 max-w-max">
      <p>{props.unit}</p>
      <p>{basicUnitType[props.unitType]}</p>
    </div>
  );
};

export default UnitTypeBox;
