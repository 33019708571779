import ProductItemInMobile from "../Main/ListOfProducts/ProductItemInMobile";

const ListOfReceivedDataOfSearch = (props: any) => {
  //console.log('props.title',props.item.title);
  return (
    <li className=" mx-1 block p-1  focus:ring-rose-500 focus:border-rose-500 ">
      <ProductItemInMobile items={props.item} />
    </li>
  );
};

export default ListOfReceivedDataOfSearch;
