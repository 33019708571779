import { useSelector } from "react-redux";
import { FaWhatsapp } from "react-icons/fa";
import { IoIosCall, IoMdMail } from "react-icons/io";
import { TbWorld } from "react-icons/tb";
import { SiTelegram } from "react-icons/si";
const HowUsersCallToSupport = () => {
  const SupportPhoneNumber = useSelector(
    (state: any) => state.shopInfo.SupportPhoneNumber
  );
  const SupportEmail = useSelector((state: any) => state.shopInfo.SupportEmail);
  const SupportTelegram = useSelector(
    (state: any) => state.shopInfo.SupportTelegram
  );
  const webSiteLink = useSelector((state: any) => state.shopInfo.webSiteLink);
  return (
    <div>
      <a href={`tel:${SupportPhoneNumber}`}>
        <div className="flex justify-between items-center rounded py-2 px-1 font-bold">
          <IoIosCall className="text-rose-400 w-8 h-8" />
          <p className="px-2">{SupportPhoneNumber}</p>
        </div>
      </a>
      <hr className="m-1 h-px rounded  text-gray-800 bg-gray-500 w-full" />
      <a href={`tel:${SupportTelegram}`}>
        <div className="flex justify-between items-center rounded py-2 px-1 font-bold">
          <SiTelegram className="text-rose-400 w-8 h-8" />
          <p className="px-2">{`${SupportTelegram}@`}</p>
        </div>
      </a>
      <hr className="m-1 h-px rounded text-gray-800 bg-gray-500 w-full" />
      <a href={`tel:${SupportEmail}`}>
        <div className="flex justify-between items-center rounded py-2 px-1 font-bold">
          <IoMdMail className="text-rose-400 w-8 h-8" />
          <p className="px-2">{SupportEmail}</p>
        </div>
      </a>
      <hr className="m-1 h-px rounded  text-gray-800 bg-gray-500 w-full" />
      <a href={`${webSiteLink}`}>
        <div className="flex justify-between items-center rounded py-2 px-1 font-bold">
          <TbWorld className="text-rose-400 w-8 h-8" />
          <p className="px-2">https://mivekani.com</p>
        </div>
      </a>
      {/* <a href={`https://api.whatsapp.com/send?phone=${SupportPhoneNumber}`}>
        <div className="my-4 ">
          <FaWhatsapp className="w-12 h-12 text-green-600 m-1" />
        </div>
      </a> */}
    </div>
  );
};

export default HowUsersCallToSupport;
