import { CgProfile } from "react-icons/cg";
import { baseURL } from "../../../basics";
import AddReply from "./AddReply";

const Reply = (props: any) => {
  const avatarIcon = props.reply.avatar ? (
    <img
      className="h-12 w-12 rounded-full"
      src={`${baseURL}${props.reply.avatar}`}
      alt="avatar"
    />
  ) : (
    <CgProfile className="h-12 w-12 rounded-full" />
  );
  return (
    <div className="flex justify-start items-start relative">
      <div className="">{avatarIcon}</div>
      <div className="w-full">
        <div className=" m-1 bg-gray-200 rounded-b-lg rounded-l-lg m-1 p-1">
          <div className="font-bold">{props.reply.userName}</div>
          <div>{props.reply.createdDate}</div>
          <div>{props.reply.description}</div>
        </div>
        <div className="">
          {/* <div className="absolute z-10 top-0 left-0  mt-2 ml-2"> */}

          <AddReply commentId={props.parentId} />
        </div>
      </div>
      {/* <AddReply commentId={item.id} /> */}
    </div>
  );
};

export default Reply;
