import { useEffect, useState } from "react";
import { basics, headerstatusColor, orderStatusType } from "../../../basics";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../Ui/Loading";
import { Link, useHistory } from "react-router-dom";
import { userOrdersActions } from "../../../store/userOrders-slice";
import useDateConverter from "../../Hooks/useDateConverter";
import moment from "jalali-moment";
const UserOrderItem = (props: any) => {
  const [data, setData] = useState("");
  const [convertedDate, setConvertedDate] = useState("");
  const shopInfoData = useSelector((state: any) => state.shopInfo.shopInfoData);
  const { Year, dayOfWeek, DayOfMonth, TimeOfDay, Month } =
    useDateConverter(convertedDate);

  useEffect(() => {
    if (props.data) {
      Object.keys(shopInfoData).length && setData(shopInfoData.stores[0].title);
      setConvertedDate(props.data.lastModified);
    }
  }, [props.data, shopInfoData]);
  const history = useHistory();
  const dispatch = useDispatch();
  const userOrdesHandler = (id: any) => {
    dispatch(userOrdersActions.pageChanger());
    history.push(`/listOfOrders/userOrderDetails/${id}`);
  };
  const classes = `bg-${
    headerstatusColor[props.data.status]
  }-600 rounded-b-lg text-zinc-50 text-xs flex justify-center items-center p-1`;
  return (
    <div className="bg-gray-100  my-3 max-w-2xl mx-auto rounded-b ">
      <div className={classes}>
        <p>{`${basics.orderStatus}: ${orderStatusType[props.data.status]}`}</p>
      </div>
      <div className=" text-xs  max-w-2xl">
        <div className="flex  my-2">
          <p className="pl-1 font-bold">{`${basics.exactTimeOfOrder}:`}</p>
          <p>{`${dayOfWeek} ${DayOfMonth} ${Month} ${Year} ${basics.time} ${TimeOfDay}`}</p>
        </div>
        <div className="flex my-2">
          <div className="flex w-1/2">
            <p className="pl-1 font-bold">{`${basics.shop}:`}</p>
            <p>{data}</p>
          </div>
          <div className="flex w-1/2">
            <p className="pl-1 font-bold">{`${basics.numberOfOrderId}:`}</p>
            <p>{props.data.id}</p>
          </div>
        </div>
        <div className="flex my-2">
          <div className="flex w-1/2">
            <p className="pl-1 font-bold">{`${basics.numberOfItemsInOrder}:`}</p>
            <p>{props.data.productsCount}</p>
          </div>
          <div className="flex w-1/2">
            <p className="pl-1 font-bold">{`${basics.totalCost}:`}</p>
            <p className="text-rose-600">{props.data.total.toLocaleString()}</p>
            <p className="text-rose-600">{`${basics.priceName}`}</p>
          </div>
        </div>
        {props.data.discountedTotal !== props.data.total && (
          <div className="flex justify-center items-center mt-3 text-green-400">
            <p className="pl-1 font-bold">{`${basics.totalCostWithDiscount}:`}</p>
            <p className=" ">{props.data.discountedTotal.toLocaleString()}</p>
          </div>
        )}
        <div>
          <div className="flex justify-end p-2 ">
            <p
              className="font-bold text-red-600 text-xs cursor-pointer"
              onClick={() => userOrdesHandler(props.data.id)}
            >
              {basics.seeMoreInfoOfOrder}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserOrderItem;
