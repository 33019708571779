//export const baseURL = `https://core.api.test.mivekani.com/`;
export const baseURL = `https://core.api.alpha.mivekani.com/`;

export const basics = {
  shopName: "میوه کانی",
  shop: "فروشگاه",
  exit: "خروج",
  numberOfOrderId: "شماره فاکتور",
  numberOfItemsInOrder: "اقلام خرید",
  priceOfOrder: "مبلغ کل فاکتور",
  seeMoreInfoOfOrder: "مشاهده جزئیات",
  detailsOfOrder: "جزئیات سفارش",
  orderSummary: "خلاصه خرید",
  ListOfOrdersInCart: "سبد خرید",
  shippingPrice: "هزینه ارسال",
  freeShippingPrice: "رایگان",
  // shippingPriceAmount: "17000تومان",
  enterYourCode: "کد تخفیف را وارد کنید",
  totalCost: "جمع محصولات",
  totalPrice: "جمع فاکتور ",
  totalCostWithDiscount: "جمع محصولات (با کد تخفیف)",
  apply: "اعمال کد تخفیف",
  continueProcessOfPurchase: "ادامه فرآیند خرید",
  pay: "پرداخت و ارسال محصول",
  addToCart: "افزودن به سبد",
  noStocks: "تمام شد",
  seeAllProducts: "همه موارد",
  ListOfTypes: "دسته بندی ها",
  orderStatus: "وضعیت سفارش",
  listOfAddresses: "مکان های منتخب",
  time: "ساعت",
  exactTimeOfOrder: "تاریخ ثبت سفارش",
  like: "علاقه",
  qualityName: "کیفیت:",
  qoodQuality: "خوش قیمت",
  perfectQuality: "درجه یک",
  stocksAmount: "موجودی",
  priceName: "تومان",
  totalQuantity: "تعداد کل :",
  addressTitle: "عنوان آدرس",
  addressDescription: "آدرس",
  addressSubmit: "ثبت آدرس",
  search: "جستجوی ",
  moreInformation: "اطلاعات تکمیلی",
  addressSearch: "جستجوی آدرس",
  productsSearch: "جستجوی محصول",
  noDataFound: "هیچ محصولی یافت نشد",
  loading: "درحال بارگذاری",
  status: "وضعیت",
  seeCustomersComment: "مشاهده نظرات",
  activitiesPeriod: "ساعت فعالیت",
  sendAndHowCalculatePayments: "ارسال و نحوه محاسبه هزینه",
  until: "الی",
  home: "خانه",
  products: "محصولات",
  product: "محصول",
  myProfile: "پروفایل من",
  aboutUs: "درباره ما",
  callUs: "تماس با پشتیبانی",
  likes: "برگزیده ها",
  managingAddresses: "مدیریت آدرس ها",
  listOfOrders: "لیست سفارشات",
  listOfProducts: "لیست محصولات",
  share: "معرفی به دوستان",
  quality: "کیفیت",
  warranty: "گارانتی",
  rules: "قوانین",
  pricing: "قیمت",
  privacy: "حریم خصوصی",
  ticketToSupport: "پیام به پشتیبانی",
  contactSupport: "تماس به پشتیبانی",
  guest: "مهمان",
  completProfileInfo: "تکمیل کردن پروفایل، کلیک کنید",
  edit: "ویرایش",
  delete: "حذف",
  couponCode: "کد تخفیف",
  couponSubmit: "اعمال تخفیف",
  clearCouponCode: "لغو کد تخفیف",
  finalSubmitAndPay: "تایید نهایی و پرداخت",
  accept: "تایید",
  goBack: "برگشت",
  nameOfUser: "نام",
  familyNameOfUser: "نام خانوادگی",
  phoneNumber: "شماره موبایل",
  phoneNumberSample: "*********9",
  signUp: "عضویت",
  signIn: "ورود",
  signUpDescription: "حساب کاربری ندارید؟",
  signInToProfileDescription: " در حال حاضر یک حساب کاربری دارید؟ ",
  signInToProfile: "ورود",
  addReceivedCode: "کد را وارد کنید",
  getCode: "دریافت کد تایید",
  yourCodeIsNotCorrect: "کد وارد شده صحیح نمی باشد",
  yourNumberIsNotCorrect: "شماره تلفن وارد شده صحیح نمی باشد ",
  addComment: "ثبت نظر",
  ticketTitle: "موضوع",
  ticketDescription: "پیام",
  ticketSubmit: "ثبت",
  selectYourPaymentType: "روش پرداخت",
  selectBankPaymentType: "درگاه پرداخت بانک ملت",
  selectBankPaymentTypeDescription: "پرداخت آنلاین با تمامی کارت های بانکی",
  selectHomeDoorPaymentType: "پرداخت درب منزل",
  selectHomeDoorPaymentTypeDescription: "وجه نقد و یا کارتخوان در محل ",
  yourAddressListIsEmpty: "لیست آدرس شما خالی است",
  addNewAddressInPurchasePageButton: "اضافه کردن آدرس جدید",
  YourCartIsEmpety: "سبد خرید خالی است.",
  YourFavoriteIsEmpety: "لیست برگزیده ها خالی است.",
  YourOrdersIsEmpety: "لیست سفارشات خالی است.",
  showcase: "ویترین",
  lastCart: "سبد نهایی",
  myOrders: "سفارشات من",
  myAddress: "آدرس",
  Categories: "دسته بندی ها",
  specialOrderTitle: "پیشنهاد ویژه",
  clickForAdd: "برای ثبت نام اینجا لمس کنید",
  storeComments: "نظرات و پیشنهادات",
  codeWillSendAfterWhile: "ارسال مجدد کد بعد از",
  sendAgainCode: "ارسال مجدد کد",
  addReply: "پاسخ دادن",
  submitReply: "ثبت",
  updateUserProfile: "ویرایش پروفایل",
  editUserAddress: "ویرایش آدرس",
  addUserUpdate: "ثبت",
  getAll: "همه محصولات",
  AddPeriods: "تعیین زمان ارسال سفارش ",
  // footer:
  getApplicationTitle: "تجربه کاربری بهتر در ",
  getApplicationDesc: "اپلیکیشن میوه کانی",
  callInformations: "اطلاعات تماس",
  aboutShop: "درباره میوه کانی",
  userCommentTitle: "نظر شما",
};
export const basicUnitType = [
  " عدد",
  " کیلوگرم",
  " گرم",
  " بسته",
  " جعبه",
  " بطری",
  " قوطی",
  "سطل ",
  "بدون دسته بندی ",
];
export const orderStatusType = [
  "",
  "در انتظار پرداخت",
  "در انتظار بررسی",
  "درحال آماده سازی سفارش",
  "ارسال شده",
  "تحویل شده",
  "لغو شده",
  "مرجوع شده",
];
export const headerstatusColor = [
  "",
  "gray",
  "blue",
  "purple",
  "teal",
  "green",
  "red",
  "yellow",
];
// toDo:
//  1- we can't see Cart popup in shopping page(ListOfProducts)/
export const basicsDescription = {
  about:
    "با سلام خدمت هم میهنان و همشهریان عزیز.\n تیم ``میوه  کانی ``فعالیت خودرا از سال ۱۳۹۲ بصورت عمده و خرده در سطح شهر سنندج  بصورت سیار و مغازه در میدان کاوه اغاز کرده و به لطف خدا و تلاش بی وقفه در راستای روزی حلال و رضایت مشتریان عزیز توانسته ایم سهم قابل توجهی از بازار میوه این شهر (مشتریان ثابت از تمام مناطق شهر سنندج) در دست بگیریم.\n ``میوه کانی`` به عنوان اولین هایپر میوه اینترنتی در استان کردستان و با هدف راه اندازی  اپلیکیشن (نرم افزار) ایجاد شغل برای افراد واجد شرایط و  پیشرفت نوع کسب کار با استاندارهای روز و جهانی بوده و از همه مهم تر رعایت و  رضایت حال مشتریان عزیزی که به دلیل مشغله های کاری و زمانی و اعتماد و... قادر به خرید حضوری  میوه نمی باشند این نرم افزار  را به کمک همشهریان بسیار توانمند در حوزه برنامه نویسی و نرم افزار طراحی کرده  و امیدواریم با توکل به خدا و لطف شما  همشهریان عزیز  سهم کوچکی از خرید امن و با اطمینان شما باشیم.\n ارادتمند شما:\n سرکوت مریوانی\n مدیر عامل ``میوه کانی``\n کارشناس ارشد مدیریت بازاریابی\n   ✨مطمئن باشید فقط برای یک بار نیست✨",
  aboutInFooter:
    " ``میوه کانی`` به عنوان اولین هایپر میوه اینترنتی در استان کردستان و با هدف راه اندازی  اپلیکیشن (نرم افزار) ایجاد شغل برای افراد واجد شرایط و  پیشرفت نوع کسب کار با استاندارهای روز و جهانی بوده و از همه مهم تر رعایت و  رضایت حال مشتریان عزیزی که به دلیل مشغله های کاری و زمانی و اعتماد و... قادر به خرید حضوری  میوه نمی باشند این نرم افزار  را به کمک همشهریان بسیار توانمند در حوزه برنامه نویسی و نرم افزار طراحی کرده  و امیدواریم با توکل به خدا و لطف شما  همشهریان عزیز  سهم کوچکی از خرید امن و با اطمینان شما باشیم.",
  quality:
    "1_درجه 1 (تشریفاتی):\nاین محصولات مناسب مهمانی ها و مراسم با درجه مرغوبیت بالا و یک دست بودن هستند و نسبت به بازار قیمت نسبتأ بالاتری دارند.\n2_ متوسط و درهم  (بصرفه ،اقتصادی،قابل قبول).\nاین محصولات یک دست و یک سایز نمی باشند اما کاملا سالم و برای مصرف خانگی و بدون دور ریز می‌باشند  و کاملا قابل مصرف و دارای  قیمت پایین و قابل قبول نسبت به کیفیت و مرغوبیت انها بسیار بصرفه برای مشتریان عزیز می باشد.\n3_آبگیری و درجه 2\nاین محصولات شامل (پرتقال _ انار _ طالبی _موز _لیمو _شلیل _ هلو _آلو و...) می باشد که قابل استفاده برای مهمانی و مصارف شخصی نمی باشد و امکان دارد با مقداری دور ریز همراه باشد و فقط برای آبگیری یا خشک کردن و لواشک یا ... قابل استفاده هستند.\n   ✨مطمئن باشید فقط برای یک بار نیست✨",
  warranty:
    "✓✓گارانتی مرجوعی✓✓\nبرای تمام محصولات خریداری شده\nبا خرید از ``میوه کانی`` حتی 1 ریال هم هزینه محصول خراب و بی کیفیت پرداخت نمی کنید.\nتمام سفارشات شما اگر به اشتباه ارسال شود و یا مورد تایید مشتریان عزیز نباشد شامل گارانتی بی قید و شرط هستند.\n✓✓نحوه استفاده از گارانتی√√\nدر همان روز از طریق تماس تلفنی با پشتیبانی ``میوه کانی`` اقدام نمایید.\nشماره تماس :  09109205060\n✓✓مطمئن و آسوده خاطر باشید✓✓ تمام محصولات و خرید شما شامل گارانتی بی قید و شرط هستند.\n   ✨مطمئن باشید فقط برای یک بار نیست✨",
  rules:
    "در راستای  اعتماد و لطف شما مشتریان عزیز\n سعی تیم  ``میوه کانی``  رضایت شما و اعتماد و لذت خرید امن با آرامش و آسودگی خاطر  شما می باشد.\nتنها قانون ``میوه کانی`` رضایت شما مشتریان عزیز از محصولات است پس با اطمینان و اسودگی کامل می توانید خرید کنید...\nتمام خرید شما تحت گارانتی مرجوعی بی قید و شرط ما می باشد.\n  ✨مطمئن باشید فقط برای یک بار نیست✨",
  pricing:
    "نوع قیمت گذاری ما ارسال میوه،سبزی و صیفی جات با کمترین هزینه است.\nتا جایی که بتوانیم هزینه محصولات میوه،سبزی و صیفی را بصرفه و کاهش دهیم.\nقیمت گذاری  در ``میوه کانی`` طبق قیمت روز میوه و تره بار در ایران است.\nهمانطور که میدانید قیمت میوه وصیفیجات هر روز درحال نوسان است و عوامل مختلفی باعث افزایش(بارندگی ،اعیاد،تعطیلات نوروز....) یا کاهش (تنوع زیاد از یک محصول، افزایش محصول در بازار و ......) قیمت ها می شود.\nدر نتیجه تیم تامین و تهیه محصولات ``میوه کانی`` نهایت تلاش خود را می کنند تا با ارئه قیمت مناسب و بصرفه (قیمت روز) محصولات را به دست مشتریان عزیز برسانند.\n         ✨مطمئن باشید برای یک بار نیست✨",
  privacy:
    "میوه کانی با تاکید بر احترامی که برای حریم شخصی کاربران قائل است، برای خرید و ثبت سفارش اطلاعاتی را از کاربران درخواست می کند. برای ارسال سفارش اطلاعاتی نظیر شماره تلفن و آدرس مورد نیاز است.",
  sendAndHowCalculatePayments:
    "میوه کانی با تاکید بر احترامی که برای حریم شخصی کاربران قائل است، برای خرید و ثبت سفارش اطلاعاتی را از کاربران درخواست می کند. برای ارسال سفارش اطلاعاتی نظیر شماره تلفن و آدرس مورد نیاز است.",
};
