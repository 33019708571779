import { useState, useRef, useEffect } from "react";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import axios from "axios";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "./CustomButton";
import { uiActions } from "../../../../store/Ui-slice";

const customIcon = new Icon({
  iconUrl: require("../../../../resources/images/app/marker.png"),
  iconSize: [38, 38], // size of the icon
});

function MapLeafLet({
  longitude,
  latitude,
  onPositionChange,
  descriptionOfUserFromMapHandler,
}) {
  const mapRef = useRef(null);
  const map = mapRef.current;
  const initiatelatLng = useSelector((state) => state.address.initiatelatLng);
  const dispatch = useDispatch();
  // [latitude, longitude] ||  initiatelatLng || [35.31136423226539, 46.9961286574362]
  const [position, setPosition] = useState(
    (latitude && longitude && [latitude, longitude]) ||
      (!initiatelatLng.length
        ? [35.31136423226539, 46.9961286574362]
        : initiatelatLng)
  );
  // console.log("initiatelatLng", initiatelatLng, position);
  const [markerLocationChange, setMarkerLocationChange] = useState(true);
  // const [autoFillDescription, setAutoFillDescription] = useState("");
  function LocationMarker() {
    // const [draggable, setDraggable] = useState(true);
    // console.log("userLocation:", initiatelatLng);
    const markerRef = useRef(null);
    // const eventHandlers = useMemo(
    //   () => ({
    //     dragend() {
    //       const marker = markerRef.current;
    //       if (marker != null) {
    //         console.log('marker dragged:',marker.getLatLng());
    //         setPosition(marker.getLatLng());
    //       }
    //     },
    //   }),
    //   []
    // );
    // const toggleDraggable = useCallback(() => {
    //   // setDraggable((d) => !d);
    // }, []);
    const autoFillDescHandler = () => {
      const newCoordinates = [position[0], position[1]];
      reverseGeocode(newCoordinates);
    };
    const map = useMapEvents({
      dragend() {
        map.locate();
        const m = map.getCenter();
        // console.log("m dargend", m);
        // console.log("position ,m:", position);
        setPosition([m.lat, m.lng]);
        autoFillDescHandler();
      },
      zoomstart() {
        setMarkerLocationChange(true);
      },
      zoomend() {
        if (markerLocationChange) {
          map.locate();
          const m = map.getCenter();
          // console.log("m zooooooooooooooooom");
          // console.log("position ,m:", position);
          setPosition([m.lat, m.lng]);
          autoFillDescHandler();
          setMarkerLocationChange(false);
          // map.off('zoomend');
        }
      },
      locationfound(e) {
        // setPosition(e.latlng);
        // console.log("e.latlng", e);
        if (markerLocationChange) {
          if (initiatelatLng.length !== 0) {
            // console.log("locationFound");
            // map.flyTo(initiatelatLng, map.getZoom());
            // autoFillDescHandler();
            // map.off('locationfound');
            setMarkerLocationChange(false);
          }
        }
      },
    });
    // console.log("Positin", position);
    return position === null ? null : (
      <Marker
        icon={customIcon}
        // draggable={draggable}
        // eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}
      >
        {/* <Popup minWidth={90}>
          <span onClick={toggleDraggable}>
            {draggable
              ? "Marker is draggable"
              : "Click here to make marker draggable"}
          </span>
        </Popup> */}
      </Marker>
    );
  }
  const flyToUserLocation = (event) => {
    event.preventDefault();
    // const map = mapRef.current;
    // console.log("map", map);
    if (map) {
      if (initiatelatLng.length !== 0) {
        setPosition(initiatelatLng);
        map.flyTo(initiatelatLng, map.getZoom(), {
          duration: 1, // Animation duration in seconds
        });
        setMarkerLocationChange(true);
      } else {
        dispatch(
          uiActions.showNotification({
            status: "error",
            title: "",
            message:
              "دریافت اطلاعات نقطه ی مکانی شما موفقیت آمیز نبود، لطفا تنظیمات نقطه مکانی(GPS) خود را فعال کنید ",
          })
        );
      }
    }
  };

  useEffect(() => {
    // console.log("latitude&& longitude", latitude, longitude);
    if (latitude && longitude) return;
    // const map = mapRef.current;
    // console.log("map useEffect1", map);
    // console.log("initiatelatLng", initiatelatLng);
    if (map) {
      // console.log("map useEffect2", map);
      if (initiatelatLng.length !== 0) {
        setPosition(initiatelatLng);
        map.flyTo(initiatelatLng, map.getZoom(), {
          duration: 1, // Animation duration in seconds
        });
        setMarkerLocationChange(true);
      }
    }
  }, [initiatelatLng, latitude, longitude, map]);

  // const handleFlyTo = () => {
  //   const map = mapRef.current;
  //   if (map) {
  //     const newCoordinates = [position[0], position[1]];

  //     reverseGeocode(newCoordinates);
  //   }
  // };

  const reverseGeocode = async (coordinates) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${coordinates[0]}&lon=${coordinates[1]}&accept-language=fa`
      );

      if (response.data.display_name) {
        const locationName = response.data.display_name;

        // setAutoFillDescription();
        descriptionOfUserFromMapHandler(
          locationName.split(",").reverse().slice(5).join("-")
        );
        // You can do something with the locationName, like displaying it on the UI.
      }
    } catch (error) {
      console.error("Reverse geocoding error:", error);
    }
  };
  onPositionChange(position);
  return (
    <div className="relative">
      <MapContainer
        center={position}
        zoom={15}
        setView={true}
        className="z-0 h-56 w-80"
        ref={mapRef}
        zoomControl={false}
        attributionControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <LocationMarker />
      </MapContainer>
      <CustomButton selectButton={flyToUserLocation} />
    </div>
  );
}
export default MapLeafLet;
