import { basics } from "../../../basics";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { addressActions } from "../../../store/address-slice";
import {
  deleteAddressesItemsData,
  sendAddressItemsData,
} from "../../../store/address-actions";

import {
  MdCheckCircle,
  MdLocationOn,
  MdLocationPin,
  MdHome,
  MdRestaurantMenu,
  MdShoppingCart,
  MdSportsSoccer,
} from "react-icons/md";

import { uiActions } from "../../../store/Ui-slice";
// import MapLatLng from "../Map/MapLatLng";

import MapLeafLet from "../Map/MapBox/MapLeafLet";


const EditAddress = (props: any) => {
  // const addressSubmitHandler = () => {
  //   //console.log("addressSubmitHandler");
  // };
  // console.log("editAddress props", props);
  const dispatch = useDispatch();
  const history = useHistory();
  const sendingDataToServerWasSuccessfull = useSelector(
    (state: any) => state.ui.sendingDataToServerWasSuccessfull
  );

  const [clickedOnSubmitButton, setClickedOnSubmitButton] = useState(false);
  const Token = useSelector((state: any) => state.auth.token);
  // const props.addressSelectedForEditData = useSelector(
  //   (state: any) => state.address.addressSelectedForEditData
  // );
  // console.log("addressSelectedForEditData", addressSelectedForEditData);
  const loading = useSelector((state: any) => state.address.loading);

  // const Latitude = useSelector((state: any) => state.address.lat);
  // const Longitude = useSelector((state: any) => state.address.lng);
  const [Latitude, setLatitude] = useState();
  const [Longitude, setLongitude] = useState();
  // const [autoFillDescription, setAutoFillDescription] = useState('');

  //   const titleInputRef = useRef<HTMLInputElement>(null);
  //   const descriptionInputRef = useRef<HTMLInputElement>(null);
  // const [latitude, setLatitude] = useState(Latitude);
  // const [longitude, setLongitude] = useState(Longitude);
  const [triggerMap, setTriggerMap] = useState(false);
  const [title, setTitle] = useState(props.addressSelectedForEditData.title);
  const [description, setDescription] = useState(
    props.addressSelectedForEditData.description
  );
  useEffect(() => {
    if (
      props.addressSelectedForEditData.latitude &&
      props.addressSelectedForEditData.longitude
    ) {
      // setLatitude(props.addressSelectedForEditData.latitude);
      // setLongitude(props.addressSelectedForEditData.longitude);
      dispatch(
        addressActions.addLat(props.addressSelectedForEditData.latitude)
      );
      dispatch(
        addressActions.addLng(props.addressSelectedForEditData.longitude)
      );
    }
    setTriggerMap(true);
  }, [
    props.addressSelectedForEditData.latitude,
    props.addressSelectedForEditData.longitude,
    dispatch,
  ]);
  const [addressIcon, setAddressIcon] = useState(
    props.addressSelectedForEditData.icon
  );

  const handleTitleInputChange = (e: any) => {
    setTitle(e.target.value);
  };
  const handleDescriptionInputChange = (e: any) => {
    setDescription(e.target.value);
  };

  const addressSubmitHandler = (event: any) => {
    event.preventDefault();
    //     const enteredTitle: string = titleInputRef.current!.value;
    //     const enteredDescription: string = descriptionInputRef.current!.value;
    // dispatch(addressActions.addLat(lat));
    // dispatch(addressActions.addLng(lng));

    const address = {
      addressIcon,
      title,
      description,
      Latitude,
      Longitude,
    };
    // console.log("address", address);
    if (title.length >= 50) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "عنوان آدرس باید کوتاه تر باشد",
          message: "",
        })
      );
    } else if (addressIcon && title && description) {
      dispatch(addressActions.addItemToAddressesList(address));
      dispatch(addressActions.toggleLoading(true));
      setClickedOnSubmitButton(true);
      if (props.addressSelectedForEditData.id) {
        deleteAddressesItemsData(props.addressSelectedForEditData.id, Token);
      }
      dispatch(sendAddressItemsData(address, Token));
    } else if (!addressIcon && title && description) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "یک آیکن را انتخاب کنید",
          message: "",
        })
      );
    } else if (addressIcon && !title && description) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "عنوان آدرس خالی است",
          message: "",
        })
      );
    } else {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: " آدرس خالی است",
          message: "",
        })
      );
    }
  };
  const addIconHandler = (text: string) => {
    //console.log("addIconHandler", text);
    dispatch(addressActions.toggleAddressIcon(text));
    setAddressIcon(text);
  };
  useEffect(() => {
    if (
      !loading &&
      clickedOnSubmitButton &&
      sendingDataToServerWasSuccessfull
    ) {
      history.goBack();
    }
  }, [
    clickedOnSubmitButton,
    history,
    loading,
    sendingDataToServerWasSuccessfull,
  ]);
  const positionOfUserFromMapHandler = (position: any) => {
    // console.log("positionOfUserFromMapHandler", position[0], position[1]);
    setLatitude(position[0]);
    setLongitude(position[1]);
  };
  const descriptionOfUserFromMapHandler = (autoFillDescription: any) => {
    setDescription(autoFillDescription);
  };
  return (
    <div className="flex flex-col justify-center items-center mt-12 mb-24 sm:mb:56 sm:mt-24 mx-auto max-w-2xl">
      <div className="flex justify-center fixed top-0 left-0 right-0 z-10 bg-white sm:relative  text-xl font-bold">
        <div className="my-3">{basics.editUserAddress}</div>
      </div>

      <div className="flex justify-center items-center bg-gray-100 my-2 p-2 rounded-lg sm:w-full mx-auto">
        <div className="flex justify-center items-center m-1 p-2 rounded-lg">
          <div className="relative">
            {addressIcon === "location" && (
              <MdCheckCircle className="text-red-600 absolute -bottom-1 -right-1 w-6 h-6" />
            )}

            <MdLocationPin
              onClick={() => addIconHandler("location")}
              className="text-zinc-50 w-12 h-12 p-1 m-1 rounded-lg cursor-pointer bg-gray-300"
            />
          </div>
          <div className="relative">
            {addressIcon === "home" && (
              <MdCheckCircle className="text-red-600 absolute -bottom-1 -right-1 w-6 h-6" />
            )}

            <MdHome
              onClick={() => addIconHandler("home")}
              className="text-zinc-50 w-12 h-12 p-1 m-1 rounded-lg cursor-pointer bg-gray-300"
            />
          </div>
          <div className="relative">
            {addressIcon === "restaurant" && (
              <MdCheckCircle className="text-red-600 absolute -bottom-1 -right-1 w-6 h-6" />
            )}

            <MdRestaurantMenu
              onClick={() => addIconHandler("restaurant")}
              className="text-zinc-50 w-12 h-12 p-1 m-1 rounded-lg cursor-pointer bg-gray-300"
            />
          </div>
          <div className="relative">
            {addressIcon === "shop" && (
              <MdCheckCircle className="text-red-600 absolute -bottom-1 -right-1 w-6 h-6" />
            )}

            <MdShoppingCart
              onClick={() => addIconHandler("shop")}
              className="text-zinc-50 w-12 h-12 p-1 m-1 rounded-lg cursor-pointer bg-gray-300"
            />
          </div>
          <div className="relative">
            {addressIcon === "gym" && (
              <MdCheckCircle className="text-red-600 absolute -bottom-1 -right-1 w-6 h-6" />
            )}

            <MdSportsSoccer
              onClick={() => addIconHandler("gym")}
              className="text-zinc-50 w-12 h-12 p-1 m-1 rounded-lg cursor-pointer bg-gray-300"
            />
          </div>
        </div>
      </div>
      <form className="flex flex-col justify-center items-center my-2 sm:w-full mx-auto">
        {/* <div className="w-96"><MapLatLng /></div> */}
        <div className="block w-80 sm:w-full mx-2 my-2">
          <div className="relative top-4 -right-1 flex items-center pr-3 pointer-events-none">
            <MdLocationOn className="absolute  w-6 h-6" />
          </div>
          <input
            type="text"
            id="address_title"
            //     ref={titleInputRef}
            value={title}
            onChange={handleTitleInputChange}
            className="bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-rose-500 focus:border-rose-500  block w-80 sm:w-full pr-8"
            placeholder={basics.addressTitle}
            required
          />
        </div>
        <textarea
          // type="description"
          id="description"
          //     ref={descriptionInputRef}
          value={description}
          onChange={handleDescriptionInputChange}
          className="resize-none bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-rose-500 focus:border-rose-500 block w-80 sm:w-full mb-2 p-2.5 h-24"
          placeholder={basics.addressDescription}
          required
        />
        {triggerMap && (
          <div className="block flex justify-center items-center mb-56">
            <div className="h-56 w-80 ">
              <MapLeafLet
                latitude={
                  props.addressSelectedForEditData.latitude
                }
                longitude={
                  props.addressSelectedForEditData.longitude
                }
                onPositionChange={positionOfUserFromMapHandler}
                descriptionOfUserFromMapHandler={
                  descriptionOfUserFromMapHandler
                }
              />
            </div>
          </div>
        )}
        <button
          type="submit"
          onClick={addressSubmitHandler}
          className="fixed bottom-20 left-0 right-0 z-10 flex justify-center items-center text-white bg-rose-700 hover:bg-rose-800 focus:ring-4 focus:outline-none focus:ring-rose-300 font-medium rounded-lg text-sm w-56 px-5 py-2.5 mx-auto text-center "
        >
          {loading ? (
            <div role="status">
              <svg
                aria-hidden="true"
                className="w-5 h-5 ml-2 text-gray-200 animate-spin  fill-rose-700"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <p className="px-12">{basics.addressSubmit}</p>
          )}
        </button>
      </form>
    </div>
  );
};

export default EditAddress;
