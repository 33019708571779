import { basics } from "../../basics";

const GradeDescription= (props:any) => {
     return (  <div className={`text-center text-${props.color}-500 text-xs`}>
     <span className="px-1">{basics.qualityName}</span>
     <span>
       {props.grade === 0
         ? `${basics.perfectQuality}`
         : `${basics.qoodQuality}`}
     </span>
   </div> );
}
 
export default GradeDescription;