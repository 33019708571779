import { useState } from "react";
import classes from "./Notification.module.css";

const Notification = (props: any) => {
  let specialClasses = "";

  if (props.status === "error") {
    specialClasses = classes.error;
  }
  if (props.status === "success") {
    specialClasses = classes.success;
  }

  const cssClasses = `fixed  max-w-7xl rounded-b-lg z-30 ${classes.notification} ${specialClasses} flex flex-col `;

  return (
    <section className={cssClasses}>
      <h2>{props.title}</h2>
      <p>{props.message}</p>
    </section>
  );
};

export default Notification;
