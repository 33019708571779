import { basics, basicsDescription } from "../../basics";

const AboutShopInFooter = () => {
  return (
    <div className="text-justify m-1 ">
      <h2 className="font-bold text-lg text-rose-400 m-1">{`${basics.shop}: ${basics.shopName}`}</h2>
      <div>{basicsDescription.aboutInFooter}</div>
    </div>
  );
};

export default AboutShopInFooter;
