import { createSlice } from "@reduxjs/toolkit";

const initialInfoState = {
  firstName: "",
  lastName: "",
  avatar: null,
};

const userInfoSlice = createSlice({
  name: "userInfo",
  initialState: initialInfoState,
  reducers: {
    replaceUserInfo(state) {
      state.firstName = "";
      state.lastName = "";
      state.avatar = null;
    },
    avatarChanger(state, action) {
      state.avatar = action.payload;
    },
    firsNameChanger(state, action) {
      state.firstName = action.payload;
    },
    lastNameChanger(state, action) {
      state.lastName = action.payload;
    },
    updateUserInfo(state,action){
      state.firstName = action.payload.enteredName;
      state.lastName = action.payload.enteredFamilyName;
      state.avatar = action.payload.enteredAvatar;
    }
  },
});

export const userInfoActions = userInfoSlice.actions;
export default userInfoSlice.reducer;
