import { useHistory } from "react-router-dom";
import { baseURL } from "../../../basics";
import ShowPriceInCardItem from "./ShowPriceInCardItem";
import LikeAndUnLikeButton from "../../Ui/LikeAndUnLikeButton";
import UnitTypeBox from "../../Ui/UnitTypeBox";
import GradeDescription from "../../Ui/GradeDescription";
import CheckStockAndChangeButton from "../../Ui/CheckStockAndChangeButton";
import { useEffect, useState } from "react";

const SpecialItem = (props: any) => {
  const {
    id,
    title,
    price,
    imageUrl,
    stock,
    discountedPrice,
    isSpecial,
    unit,
    unitType,
    grade,
  } = props.items;

  const showProductPageHandler = () => {
    //console.log("showProductPageHandler", id);
  };
  const history = useHistory();
  const sendUserToProductItemPage = () => {
    history.push(`/products/productItem/${id}`);
  };
  const [truncatedText, settRuncatedText] = useState("");
  useEffect(() => {
    if (title) {
      const rangeStart = 0;
      const rangeEnd = 20;
      // //console.log('title',title);
      const lastWordBoundary = title.lastIndexOf(" ", rangeEnd);
      const truncatedText =
        lastWordBoundary >= rangeEnd
          ? `${title.substring(rangeStart, lastWordBoundary)} ...`
          : `${title.substring(rangeStart, rangeEnd)}`;
      settRuncatedText(truncatedText);
    }
  }, [title]);
  return (
    <div className="relative ">
      <form className="bg-white rounded-lg shadow-md  m-1 relative h-32 w-80">
        <LikeAndUnLikeButton item={props.items} />
        <CheckStockAndChangeButton
          parentStyles={"absolute left-0 bottom-0 m-2"}
          childStyles={"flex-row-reverse bg-green-400 hover:bg-green-500"}
          items={props.items}
        />
        <div
          onClick={sendUserToProductItemPage}
          className=" flex justify-start items-start"
        >
          <div className="flex justify-start items-start">
            <img
              src={`${baseURL}${imageUrl}`}
              alt={title}
              className=" w-full h-24 w-24 object-scale-down object-cover rounded-full m-2"
            />
          </div>

          <div className=" ">
            <div
              onClick={showProductPageHandler}
              className="flex flex-col items-start justify-start h-full"
            >
              <p className=" py-1 text-gray-900 flex flex-col justify-start items-start">
                {truncatedText}
              </p>

              <GradeDescription grade={grade} color={"black"} />
              <div className="py-1 flex flex-col justify-start items-start">
                <UnitTypeBox unit={unit} unitType={unitType} />
                <ShowPriceInCardItem
                  price={price}
                  discountedPrice={discountedPrice}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default SpecialItem;
