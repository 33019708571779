// in all components that we use react-lick we have problem
//  with right to left scroll button
import axios from "axios";
import ProductItem from "../ProductItem";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { categoryActions } from "../../../../store/category-slice";
import { baseURL, basics } from "../../../../basics";
import Loading from "../../../Ui/Loading";
import { fetchShowProductsHandler } from "../../../../store/category-actions";

const ListOfProductsByCategory = (props: any) => {
  //console.log("refresh ListOfProductsByCategory");
  // /////////////////////////////
  // const settings = {
  //   dots: false,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 5,
  //   slidesToScroll: 5,
  //   initialSlide: 0,
  //   nextArrow: <NextArrow />,
  //   prevArrow: <PrevArrow />,
  //   rtl: true,
  //   swipeToSlide: true,
  //   responsive: [
  //     {
  //       breakpoint: 1280,
  //       settings: {
  //         slidesToShow: 4,
  //         slidesToScroll: 4,
  //         infinite: true,
  //         // dots: true,
  //       },
  //     },
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 3,
  //         infinite: true,
  //         // dots: true,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //         initialSlide: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };
  // /////////////////////////////
  // const cartItems = useSelector((state: any) => state.cart.items.items);
  // const getItemsStockRemained = useSelector(
  //   (state: any) => state.cart.items.items
  // );
  // const stockItems = getItemsStockRemained.map((item: any) => item.product);
  // //console.log("props.cartItems.quantity", stockItems, getItemsStockRemained);
  // //console.log("getItemsStockRemained", getItemsStockRemained);
  // //console.log("cartItems", cartItems);
  const [dataProduct, setDataProduct] = useState([""]);
  const [response, setResponse] = useState(false);
  //console.log("dataProduct", dataProduct);
  //console.log("props.id", props.id);
  // const isMounted = useRef<boolean>();
  useEffect(() => {
    // if (isMounted.current) return;
    axios({
      method: "get",
      url: `${baseURL}api/Product/GetByCategory?CategoryId=${props.id}`,
      responseType: "stream",
    })
      .then(function (response) {
        const data = response.data.data;
        setDataProduct(data);
        setResponse(true);
        //console.log("categoryUrl:response.data", response.data);
        //console.log("categoryUrl:data", data);
      })
      .catch(function (error: any) {
        //console.log("errorLIST", error);
      });
    // isMounted.current = true;
  }, [props.id]);

  // const openProductsOfThatCategory = () => {};
  const itemsPerPage = useSelector((state: any) => state.category.itemsPerPage);
  const currentPage = useSelector((state: any) => state.category.page);
  const dispatch = useDispatch();
  const history = useHistory();
  const catagorySelectedId = (id: any) => {
    // //console.log("catagorySelectedId0", id);
    dispatch(categoryActions.newCategoryIsSelected());
    dispatch(categoryActions.typeOfProductsCategoriesChanger(""));
    // dispatch(fetchShowProductsHandler(currentPage, itemsPerPage, props.id));
    dispatch(categoryActions.idOfSelectedCategory(id));
    history.push(`/products`);
  };

  // const firstId = useSelector((state: any) => state.category.categoryId);
  // const arrayOfColors = ["green", "yellow", "blue", "orange", "red"];
  // const backGroundStyle: any = arrayOfColors[props.id - firstId];
  if (!response) {
    return (
      <div className="my-24 mx-auto">
        <Loading />
      </div>
    );
  }
  return (
    <div className="max-w-7xl mx-auto   ">
      <div className={` mb-2 mx-auto  rounded text-center pt-8  `}>
        <div className="flex justify-between items-center mr-2">
          <div className="flex justify-start items-center">
            <img
              src={`${baseURL}${props.imageUrl}`}
              alt={props.title}
              // onClick={showProductPageHandler}
              className="h-8 w-8 object-contain rounded-full bg-white mx-2"
            />
            <h2 className="text-xl font-extra bold text-gray-900">
              {props.name}
            </h2>
          </div>
          {/* <Link to="/products"> */}
          <button
            onClick={() => catagorySelectedId(props.id)}
            // className={`bg-transparent hover:bg-${backGroundStyle}-800 text-${backGroundStyle}-700 font-semibold hover:text-white py-2 px-4 border border-${backGroundStyle}-100 hover:border-black rounded`}
            className={`bg-transparent hover:bg-blue-300 text-blue-400  hover:text-white py-2 px-4 mx-2 rounded-lg`}
          >
            {basics.seeAllProducts}
          </button>
          {/* </Link> */}
        </div>
        {/* <ul className=""> */}
        {/* ///////////////////////////////////////////////// */}

        <div className="col-span-5 flex overflow-x-scroll ml-2 scrollbar-hidden">
          {/* <Slider {...settings}> */}
          {dataProduct.map((product: any) => (
            <ProductItem
              key={product.id}
              items={product}
              // cartItems={cartItems}
              // quantity={quantity}
              // id={product.id}
              // title={product.title}
              // price={product.price}
              // discountedPrice={product.discountedPrice}
              // imageUrl={product.imageUrl}
              // isSpecial={product.isSpecial}
              // stock={product.stock}
              // unit={product.unit}
              // unitType={product.unitType}
              // grade={product.grade}
              // baseUrl={baseURL}
              // product={cartCtx.items}
            />
          ))}
          {/* </Slider> */}
        </div>

        {/* ///////////////////////////////////////////////// */}
      </div>
    </div>
  );
};
export default ListOfProductsByCategory;
