import { createSlice, current } from "@reduxjs/toolkit";

const initialInfoState = {
  dataOfOrders: [],
  itemsPerPage: 5,
  currentPage: 1,
  status: 2,
};

const userOrdersSlice = createSlice({
  name: "userOrders",
  initialState: initialInfoState,
  reducers: {
    pageChanger(state) {
      state.currentPage = 1;
    },
    nextPage(state) {
      state.currentPage++;
    },
    prevPage(state) {
      state.currentPage--;
    },
    // addDataOfOrders(state, action) {
    //    state.dataOfOrders?.push(action.payload)
    //   // action.payload; //  const [items, setItems] = useState<any[]>([]);
    // }, ///setItems((prevItems:any[]) => [...prevItems, ...data]);

    //     firsNameChanger(state, action) {
    //       state.firstName = action.payload;
    //     },
    //     lastNameChanger(state, action) {
    //       state.lastName = action.payload;
    //     },
    // addAddresses(state, action: any) {
    //   state.addresses = action.payload;
    //   // //console.log(".................current(state)", current(state.addresses));
    // },
    // addPhoneNumber(state, action: any) {
    //   state.phoneNumber = action.payload;

    // },
  },
});

export const userOrdersActions = userOrdersSlice.actions;
export default userOrdersSlice.reducer;
