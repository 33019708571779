import { Link } from "react-router-dom";
import { basics } from "../../basics";
import shopLogo from "../../resources/images/app/ic_icon.png";
const NavbarLogo = () => {
  return (
    <Link to="/" className="flex items-center mx-auto ">
      <div className="flex justify-center items-center self-center text-xl font-semibold whitespace-nowrap ">
        <h1 className="font-black text-2xl antialiased tracking-tight">میوه کانی</h1>
        <img src={shopLogo} alt="" className="h-10" />
      </div>
    </Link>
  );
};

export default NavbarLogo;
